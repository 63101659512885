import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "../App.css";
import notification from "../assests/images/Push-notifications-rafiki.svg";
import API_PATH from "../Constants/api-path";
import MobileMenu from "../MobileMenu";
import Sidebar from "../Sidebar";
import { Button } from "antd"
import { BsChevronLeft, Calendar, Timer } from "../Utils/Icons";


function NotificationPage() {
  document.title = "Client | Notification";
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(false);
  const [Loading1, setLoading1] = useState(false);
  const [datas, setDatas] = useState([]);
  const [filter, setFilter] = useState(true);
  const [read, setRead] = useState(false);
  const history = useNavigate();
  function randomColor() {
    let hex = Math.floor(Math.random() * 0xffffff);
    let color = "#" + hex.toString(16);

    return color;
  }

  const navigate = useNavigate();

  const userData = JSON.parse(localStorage.getItem("userData"));
  const headers = {
    "x-access-token": userData.access_token,
  };
  const [response, setResponse] = useState("");

  useEffect(() => {
    getData();
    getNotifications();
    // const interval = setInterval(() => {
    //   getData();
    // }, 3000);
    // return () => clearInterval(interval);
  }, []);

  //console.log(response);
  const getData = async () => {
    try {
      const res = await fetch(
        `${API_PATH.GET_NOTIFICATION_BY_SOCKET}/${userData.user._id}`,
        {
          method: "GET",
          headers: headers,
        }
      );

      const result = await res.json();
      if (result) {
        // console.log(result);
        setNotifications([...result]);
        setLoading(true);
        setRead(true);
      } else {
        return toast.error("something went wrong server error", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    } catch (error) {
      console.log(error);
      // return toast.error("something went wrong server error", {
      //   position: toast.POSITION.TOP_RIGHT,
      //   autoClose: 3000,
      // });
    }
  };

  // const refreshPage = () => {
  //   window.location.reload();
  // };

  const getnotDetails = (type, prospectiveId) => {
    if (type === "NOTE") {
      history({
        pathname: `/share/note/${prospectiveId}`,
      });
    } else if (type === "CONTENT") {
      history({
        pathname: `/share/content/${prospectiveId}`,
      });
    } else if (type === "SLEEVE") {
      history({
        pathname: `/share/sleeve/${prospectiveId}`,
      });
    } else if (type === "COVER") {
      history({
        pathname: `/share/folder/${prospectiveId}`,
      });
    } else {
      history({
        pathname: "/share/transcript",
      });
    }
    setRead(true);
  };

  const readNotifications = async (e) => {
    e.preventDefault();
    try {
      const res = await fetch(`${API_PATH.UPDATE_NOTIFICATION_READ_STATUS}`, {
        method: "GET",
        headers: headers,
      });

      const result = await res.json();
      if (result) {
        getData();
        getNotifications();
        setNotifications([...result]);
        setLoading(true);
        setRead(false);
        // window.location.reload(true);
        setTimeout(() => { }, 1000);
        return toast.success("All messages are seen", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });

        // console.log(result);
        // setNotifications([...result]);
        // setLoading(true);
      } else {
        return toast.error("something went wrong server error", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    } catch (error) {
      console.log(error);
      // return toast.error("something went wrong server error", {
      //   position: toast.POSITION.TOP_RIGHT,
      //   autoClose: 3000,
      // });
    }
  };

  if (loading) {
    var count = notifications.filter((item) => item.isRead === false).length;
    var notificationdata = [];

    for (let i = notifications.length - 1; i >= 0; i--) {
      //console.log(i);
      //console.log(chat[i]);
      notificationdata.push(notifications[i]);
    }

    //setAllChat([...chatdata]);
    //console.log(chatdata);
  }

  const getNotifications = () => {
    if (notifications instanceof Array) {
      if (filter) {
        if (notifications.filter((item) => item.isRead === false).length > 0) {
          let notificationData = notifications.filter(
            (item) => item.isRead === false
          );
          return (
            <>
              {notificationData.map((e, index) => {
                return (
                  <div
                    className="notificationDiv d-flex justify-content-between align-items-center"
                    key={index}
                  >
                    <div className="d-flex">
                      <div className="userIcon me-3">
                        <>
                          <div className="avatarImg">
                            <figure className="m-0">
                              <img src="images/folder-icon.png" alt="folder" />
                            </figure>
                          </div>
                        </>
                      </div>

                      <div
                        className="notificationFrom"
                        style={{ cursor: "pointer" }}
                      >
                        <div
                          onClick={() => getnotDetails(e.type, e.prospectiveId)}
                        >
                          <h5>
                            <span
                              style={{
                                color: "#848594",
                                fontWeight: "600",
                                fontSize: "16px",
                                textTransform: "none",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {e.title}
                            </span>{" "}
                          </h5>
                          <p
                            style={{
                              fontSize: "14px",
                              color: "#848594",
                              lineHeight: "1.7",
                            }}
                          >
                            Hi {e.curentUserId.firstNmae}, {e.title},
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="notificationtime">
                      {/* {console.log(e.createdAt)} */}
                      <p
                        style={{
                          fontSize: "14px",
                          color: "#848594",
                          lineHeight: "1.7",
                        }}
                      >
                        {Calculate_days_hours(
                          new Date(e.createdAt),
                          new Date(Date.now())
                        )}
                      </p>
                    </div>
                  </div>
                );
              })}
            </>
          );
        } else {
          return (
            <>
              <div className="text-center noResultFound">
                <div className="container">
                  <div className="row d-flex justify-content-center align-items-center">
                    <div className="col-md-6">
                      <img src={notification} alt="notify"></img>
                      <h5 className="ms-4 mt-4">No notifications available</h5>
                    </div>
                  </div>
                </div>
              </div>
            </>
          );
        }
      } else {
        if (notifications.length > 0) {
          return (
            <>
              {notifications.map((e, index) => {
                return (
                  <div
                    className="notificationDiv d-flex justify-content-between align-items-center cursor-pointer"
                    key={index}
                  >
                    <div className="d-flex">
                      <div className="userIcon me-3">
                        <>
                          <div className="avatarImg">
                            <figure className="m-0">
                              <img src="images/folder-icon.png" alt="folder" />
                            </figure>
                          </div>
                        </>
                      </div>

                      <div
                        className="notificationFrom"
                        style={{ cursor: "pointer" }}
                      >
                        <div
                          onClick={() => getnotDetails(e.type, e.prospectiveId)}
                        >
                          <h5>
                            <span
                              style={{
                                color: "#3b384b",
                                fontWeight: "600",
                                fontSize: "16px",
                                textTransform: "none",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {e.title}
                            </span>{" "}
                          </h5>
                          <p
                            style={{
                              fontSize: "14px",
                              color: "#848594",
                              lineHeight: "1.7",
                            }}
                          >
                            Hi {e.curentUserId.firstNmae}, {e.title},
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="notificationtime">
                      {/* {console.log(e.createdAt)} */}
                      <p
                        style={{
                          fontSize: "14px",
                          color: "#848594",
                          lineHeight: "1.7",
                        }}
                      >
                        {Calculate_days_hours(
                          new Date(e.createdAt),
                          new Date(Date.now())
                        )}
                      </p>
                    </div>
                  </div>
                );
              })}
            </>
          );
        } else {
          return (
            <>
              <div className="text-center noResultFound">
                <div className="container">
                  <div className="row d-flex justify-content-center align-items-center">
                    <div className="col-md-6">
                      <img src={notification} alt="notify"></img>
                      <h5 className="ms-4 mt-4">No notifications available</h5>
                    </div>
                  </div>
                </div>
              </div>
            </>
          );
        }
      }
    }
  };

  function Calculate_days_hours(date1, date2) {
    var diff = date2.getTime() - date1.getTime();
    var days = Math.floor(diff / (1000 * 60 * 60 * 24));
    var hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    var minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((diff % (1000 * 60)) / 1000);
    ///return { days, hours, minutes, seconds };
    if (days !== 0) {
      return days + " " + "days ago ";
    } else if (hours !== 0) {
      return hours + " " + "hours ago";
    } else if (minutes !== 0) {
      return minutes + " " + "min. ago";
    } else if (seconds < 60) {
      return seconds + " " + "sec. ago";
    }
  }

  const handleIconClick = () => {
    navigate(-1);
  };

  return (
    <>
      <MobileMenu />
      <div className="page-wrapper chiller-theme toggled">
        <div className="main-sec d-lg-flex">
          <div className="sidebar-menu" id="navAccordion">
            <Sidebar />
          </div>
        </div>
        <main className="body-total content-wrapper float-start w-100">
          <div className="container-fluid">
            <div className="d-block d-lg-block ">
              {/* <div className="top-bar-menu float-start">
                <TopMenu />
              </div> */}

              <div className="dasb-div bk-div float-start w-100">
                <div className="body-expart-div request-page projects-scope">
                  <div className="request-body-sec collaborate-page">
                    { }
                    <div className="notificationContainer">
                      <div className="notificationHeader d-flex justify-content-between align-items-center">
                        <h2
                          className="d-flex align-items-center"
                          style={{ fontSize: "24px", cursor: "pointer" }}
                          onClick={handleIconClick}
                        >
                          <BsChevronLeft className="search-btn-back me-3" />
                          Notifications
                        </h2>
                        <div
                          className="d-flex justify-content-between align-items-center"
                          style={{
                            fontSize: "16px",
                            fontWeight: "400",
                          }}
                        >
                          <NavLink
                            to={""}
                            type="button"
                            className="me-3 cursor-pointer"
                            onClick={() => setFilter(!filter)}
                          >
                            {filter === true ? (
                              <button className="btn  btnsm" style={{ color: "#2a55e2" }}>View all</button>
                            ) : (
                              <button className="btn  btnsm" style={{ color: "#2a55e2" }}>
                                Filter by unread
                              </button>
                            )}
                          </NavLink>
                          <NavLink
                            to={""}
                            onClick={() => {
                              readNotifications();
                              // refreshPage();
                            }}
                          >
                            {" "}
                            <button className="btn  btnsm" style={{ color: "#2a55e2" }}>
                              Mark all as read
                            </button>
                          </NavLink>
                          {/* ):(
                            <NavLink></NavLink>
                          )} */}
                        </div>
                      </div>

                      <div
                        className="notifications mt-3"
                        style={{
                          background: "#fff",
                          borderRadius: "6px",
                          padding: "12px 12px 0px 12px",
                        }}
                      >
                        {getNotifications()}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}

export default NotificationPage;
