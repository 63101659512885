import React, { useState } from "react";
import Sidebar from "../Sidebar";
import TopMenu from "../TopMenu";
import Spinner from "react-bootstrap/Spinner";
import CollaborateSearchDetails from "./CollaborateSearchDetails";
import Button from "@mui/material/Button";

const CollaborateSearch = () => {
  return (
    <div>
      <div className="page-wrapper chiller-theme toggled">
        <div className="main-sec d-lg-flex">
          <Sidebar />
        </div>
        <main className="body-total content-wrapper float-start w-100">
          <div className="container-fluid">
            <div className="d-block d-lg-block">
              <div className="top-bar-menu float-start" id="header">
                <TopMenu />
              </div>
            </div>

            <div className="dasb-div bk-div float-start w-100">
              <div className="body-expart-div projects-pages support-page">
                <div className="faq-section">
                  <CollaborateSearchDetails />
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default CollaborateSearch;
