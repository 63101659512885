import React, { useEffect, useState } from "react";

const CopyIcon = () => (
  <span style={{ cursor: "pointer", fontSize: "14px", color: "#5746EC" }}>
    Copy Link
  </span>
);

const Checkmark = () => (
  <span style={{ cursor: "pointer", fontSize: "14px", color: "#5746EC" }}>
    Copied
  </span>
);

export const CopyToClipboard = ({ copyText, className }) => {
  console.log(copyText);
  const [clicked, setClicked] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setClicked(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, [clicked]);

  const onClick = () => {
    navigator.clipboard.writeText(copyText);
    console.log(navigator.clipboard.writeText(copyText));
    setClicked(true);
  };

  return (
    // <div className={className} onClick={onClick} title="Click to copy pubkey">
    <span onClick={onClick}>{clicked ? <Checkmark /> : <CopyIcon />}</span>
    // </div>
  );
};
