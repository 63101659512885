import CloseIcon from "@mui/icons-material/Close";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import success from "../assests/images/success.svg";
function SuccessModal({ isSuccessModal }) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    setShow(isSuccessModal);
  }, [isSuccessModal]);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      size="sm"
      id="successModal"
    >
      <Modal.Body>
        <div className="text-end">
          <CloseIcon
            onClick={handleClose}
            className="cursor-pointer"
            style={{ cursor: "pointer" }}
          />
        </div>
        <div className="py-4 px-3 text-center">
          <img
            src={success}
            style={{ width: "150px" }}
            className="m-auto"
            alt="success"
          />
          <h4>Success</h4>
          <h6>Your expert request has been sent successfully</h6>
          <p className="mt-5">
            Our team will be in touch with you shortly with a confirmation &
            invite
          </p>
          <Link
            to={"/find-expert"}
            onClick={handleClose}
            className="successModal"
          >
            Countinue
          </Link>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default SuccessModal;
