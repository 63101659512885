import React, { useState, useRef, useMemo, Jodit } from "react";
import JoditEditor from "jodit-react";
const MessageEditor = ({ setValue, config, value }) => {
  const editor = useRef("#message_insert", {
    autofocus: false,
    toolbarAdaptive: false,
    buttons: ["bold", "italic", "paragraph"],
    extraButtons: [
      {
        name: "OnlyParagraph",
        icon: "",
        exec: (editor) => {
          var selection = editor.selection;
          var text = editor.selection.getHTML();
          // console.log(text);
          var html = "<p>" + text + "</p>";
          // console.log(html);
          editor.selection.remove();
          editor.selection.insertHTML(html);
        },
      },
    ],
  });

  return (
    <JoditEditor
      value={value}
      id="message_insert"
      ref={editor}
      onChange={(content) => setValue(content)}
      config={config}
      tabIndex={1}
      style={{ border: "1px solid black" }}
    />
  );
};

// Type your message here. To reference an expert, type @
export default MessageEditor;
