import Button from "@mui/material/Button";
import { Input } from "antd";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Spinner } from "reactstrap";
import "../App.css";
import API_PATH from "../Constants/api-path";
import MessageEditor from "../messageEditor";
import "./CreateNotesForm.css";

const { TextArea } = Input;

const config = {
  readonly: false,
  width: "100%",
  enableDragAndDropFileToEditor: true,
  buttons: [
    "source",
    "|",
    "bold",
    "italic",
    "underline",
    "|",
    "ul",
    "ol",
    "|",
    "font",
    "fontsize",
    "brush",
    "paragraph",
    "|",
    "image",
    "table",
    "link",
    "|",
    "left",
    "center",
    "right",
    "justify",
    "|",
    "undo",
    "redo",
    "|",
    "hr",
    "eraser",
    "fullsize",
  ],
  uploader: { insertImageAsBase64URI: false },
  removeButtons: [
    "brush",
    "file",
    "hr",
    "font",
    "fontsize",
    "brush",
    "image",
    "undo",
    "redo",
    "link",
    "fullsize",
    "table",
    "undo",
    "redo",
    "back",
    "paint",
    "eraser",
    "paragraph",
    "source",
    "|",
    "justify",
  ],
  showXPathInStatusbar: false,
  showCharsCounter: false,
  showWordsCounter: true,
  toolbarAdaptive: false,
  toolbarSticky: false,
  toolbarButtonSize: "middle",
};

const CreateShareNotesForm = ({ data, permission }) => {
  //console.log(data.description);
  const [name, setName] = useState("");
  const userData = JSON.parse(localStorage.getItem("userData"));
  const [spinner, setSpinner] = useState(false);
  useEffect(() => {
    setName(data.name);
    setValue(data.description);
  }, [data]);

  const [value, setValue] = useState("");

  // console.log(value);
  // console.log(name);

  const editContent = async (e) => {
    e.preventDefault();
    setSpinner(true);
    document.getElementById("editContentdisabled2").disabled = true;
    try {
      let payload = {
        ...data,
      };
      payload.name = name;
      payload.description = value;
      payload.companyId = userData.user.company_id;
      payload.userId = userData.user._id;
      payload.roleId = userData.user.role_id._id;
      //console.log(payload);
      const res = await fetch(
        `${API_PATH.CONTENT_UPDATE}?ContentID=${data._id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            "x-access-token": userData.access_token,
          },
          body: JSON.stringify(payload),
        }
      );

      const result = await res.json();
      if (result) {
        setSpinner(false);
        document.getElementById("editContentdisabled2").disabled = false;
        toast.success("Note updated successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      } else {
        return toast.error("something went wrong server error", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    } catch (error) {
      return toast.error("something went wrong server error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
  };

  return (
    <>
      <div className="container rounded bg-white">
        <div className="row">
          <div className="col-md-12 border-right">
            <div className="p-2">
              <div className="row">
                <form method="get" action="">
                  <div className="row">
                    <div className="form-group">
                      <TextArea
                        style={{
                          color: "#5c5f62",
                          fontWeight: "500",
                          margin: "0px 0",
                          fontSize: "30px",
                        }}
                        className="title-inp"
                        name="name"
                        placeholder="Title fdsfs"
                        onChange={(e) => setName(e.target.value)}
                        value={name}
                        bordered={false}
                        autoSize={{
                          minRows: 2,
                          maxRows: 6,
                        }}
                      />

                      {/* <input
                        className="title-inp"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      /> */}
                    </div>
                  </div>
                  <div
                    style={{
                      backgroundColor: "#fff",
                      position: "relative",
                    }}
                  >
                    <div className="form-group">
                      <div className="createNotesEditor">
                        <MessageEditor
                          setValue={setValue}
                          value={value}
                          config={config}
                        />
                      </div>
                    </div>
                  </div>{" "}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12 mt-2">
          {permission === "view" ? (
            <></>
          ) : (
            <Button
              className="btn col-lg-5 mx-auto btnsm"
              style={{ textTransform: "capitalize" }}
              onClick={editContent}
              id="editContentdisabled2"
            >
              {spinner ? (
                <>
                  <Spinner
                    size="sm"
                    style={{
                      width: "1rem",
                      height: "1rem",
                      marginRight: "5px",
                    }}
                  />
                  Update Content
                </>
              ) : (
                " Update Content"
              )}
            </Button>
          )}
        </div>
      </div>
    </>
  );
};

export default CreateShareNotesForm;
