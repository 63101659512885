import { Button } from "@mui/material";
import { Progress } from "antd";
import axios from "axios";
import $ from "jquery";
import React, { useCallback, useEffect, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Spinner } from "reactstrap";
import "../App.css";
import API_PATH from "../Constants/api-path";
import MailEditor from "../MailEditor";
import MobileMenu from "../MobileMenu";
import Sidebar from "../Sidebar";
import NotesSetions from "./NotesSetions";
import Transcript from "./Transcript";

const config = {
  readonly: false,
  height: "200px",
  width: "100%",
  enableDragAndDropFileToEditor: true,
  buttons: [
    "source",
    "|",
    "bold",
    "italic",
    "underline",
    "|",
    "ul",
    "ol",
    "|",
    "font",
    "fontsize",
    "brush",
    "paragraph",
    "|",
    "image",
    "table",
    "link",
    "|",
    "left",
    "center",
    "right",
    "justify",
    "|",
    "undo",
    "redo",
    "|",
    "hr",
    "eraser",
    "fullsize",
  ],
  uploader: { insertImageAsBase64URI: false },
  removeButtons: [
    "brush",
    "file",
    "hr",
    "font",
    "fontsize",
    "brush",
    "image",
    "undo",
    "redo",
    "link",
    "fullsize",
    "table",
    "undo",
    "redo",
    "back",
    "paint",
    "eraser",
    "paragraph",
    "source",
    "|",
    "justify",
  ],
  showXPathInStatusbar: false,
  showCharsCounter: false,
  showWordsCounter: true,
  toolbarAdaptive: false,
  toolbarSticky: false,
  toolbarButtonSize: "middle",
};
const fileTypes = ["JPG", "PNG", "GIF"];

function Collaborate() {
  const userData = JSON.parse(localStorage.getItem("userData"));
  const [noteListData, setNoteListData] = useState("");
  const [recentData, setRecentData] = useState("");
  const [value, setValue] = useState("");
  const [folderData, setFolderData] = useState("");
  const [file, setFile] = useState(null);
  const [fileprogress, setFileprogress] = useState(0);
  const [spinner, setSpinner] = useState(true);

  useEffect(() => {
    document.body.style.overflow = "";
    return () => {
      document.body.style.overflow = "";
    };
  }, []);

  const headers = React.useMemo(
    () => ({
      "x-access-token": userData.access_token,
    }),
    [userData.access_token]
  );

  const handleFileChange = (file) => {
    setFile(file);
  };
  //console.log(file);

  // console.log(file);
  const getFolders = React.useCallback(async () => {
    try {
      const res = await fetch(
        `${API_PATH.COVER_LIST}/${userData.user._id}/false`,
        {
          method: "GET",
          headers: headers,
        }
      );

      const result = await res.json();
      if (result) {
        // console.log(result);
        setFolderData(result);
      } else {
        return toast.error(
          "Something went wrong! Our team is looking into it.",
          {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          }
        );
      }
    } catch (error) {
      return toast.error("Something went wrong! Our team is looking into it.", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
  }, [headers, userData.user._id]);

  // const getRecordings = React.useCallback(async () => {
  //   setSpinner(true);
  //   try {
  //     let domain = userData.user.email.split("@")[1];
  //     const res = await fetch(
  //       `${API_PATH.MEETING_LIST}/${domain}/${userData.user._id}/false`,
  //       {
  //         method: "GET",
  //         headers: headers,
  //       }
  //     );

  //     const result = await res.json();
  //     if (result) {
  //       setRecordingData(result);
  //       setSpinner(false);
  //     } else {
  //       setSpinner(false);
  //       return toast.error("something went wrong server error", {
  //         position: toast.POSITION.TOP_RIGHT,
  //         autoClose: 3000,
  //       });
  //     }
  //   } catch (error) {
  //     setSpinner(false);
  //     return toast.error("something went wrong server error", {
  //       position: toast.POSITION.TOP_RIGHT,
  //       autoClose: 3000,
  //     });
  //   }
  // }, [headers, userData.user._id, userData.user.email]);

  const getNotes = React.useCallback(async () => {
    setSpinner(true);

    try {
      const res = await fetch(
        `${API_PATH.NOTE_LIST}/${userData.user._id}/false`,
        {
          method: "GET",
          headers: headers,
        }
      );

      const result = await res.json();
      if (result) {
        setSpinner(false);
        setNoteListData(result);
      } else {
        setSpinner(false);
        return toast.error(
          "Something went wrong! Our team is looking into it.",
          {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          }
        );
      }
    } catch (error) {
      setSpinner(false);
      return toast.error("Something went wrong! Our team is looking into it.", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
  }, [headers, userData.user._id]);

  // console.log(noteListData);

  const getRecentlyViewd = React.useCallback(async () => {
    try {
      const res = await fetch(
        `${API_PATH.RECENTLY_VIEWD}/${userData.user._id}/false`,
        {
          method: "GET",
          headers: headers,
        }
      );

      const result = await res.json();
      if (result) {
        setRecentData(result.data);
      } else {
        return toast.error(
          "Something went wrong! Our team is looking into it.",
          {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          }
        );
      }
    } catch (error) {
      return toast.error("Something went wrong! Our team is looking into it.", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
  }, [headers, userData.user._id]);

  //console.log(recentData);
  const getCoverList = () => {
    if (folderData instanceof Array) {
      return folderData.map(function (object, i) {
        const { _id, firstNmae } = object;
        return (
          <>
            <option value={_id}>{firstNmae}</option>
          </>
        );
      });
    }
  };

  const callOnce = React.useRef(false);

  const onInitFunctions = useCallback(() => {
    getFolders();
    getNotes();
    getRecentlyViewd();
    // getRecordings();
  }, [getFolders, getNotes, getRecentlyViewd]);

  useEffect(() => {
    if (!callOnce.current) {
      callOnce.current = true;
      onInitFunctions();
    }
  }, [onInitFunctions]);

  const [noteData, setNoteData] = useState({
    name: "",
    description: "",
    userId: "",
    companyId: "",
    roleId: "",
  });

  const [contentData, setcontentData] = useState({
    name: "",
    description: "",
    userId: "",
    companyId: "",
    roleId: "",
    coverId: "",
  });

  const [coverData, setcoverData] = useState({
    name: "",
    description: "",
    userId: "",
    companyId: "",
    roleId: "",
  });
  const [sleeveData, setSleeveData] = useState({
    sleeveName: "",
    sleeveType: "",
    summary: "",
    publicUrl: "",
    transcript: "",
    userId: "",
    companyId: "",
    roleId: "",
    coverId: "",
    isAllowAll: true,
    permittedUsers: [],
    fileType: "",
    fileSize: 0,
  });
  const handleNotesInputs = (e) => {
    const key = e.target.name;
    const value = e.target.value;

    setNoteData({ ...noteData, [key]: value });
  };

  const handleCoversInputs = (e) => {
    const key = e.target.name;
    const value = e.target.value;

    setcoverData({ ...coverData, [key]: value });
  };

  const handleContentInputs = (e) => {
    const key = e.target.name;
    const value = e.target.value;

    setcontentData({ ...contentData, [key]: value });
  };

  const handleSleevesInputs = (e) => {
    const key = e.target.name;
    const value = e.target.value;

    setSleeveData({ ...sleeveData, [key]: value });
  };

  // const calculatePercentage = (e) => {
  //   const percent = Math.floor(e.loaded / e.total * 100);
  //   setFileprogress(percent);
  // }
  const createNote = async (e) => {
    e.preventDefault();

    try {
      let payload = {
        ...noteData,
      };
      payload.description = value;
      payload.companyId = userData.user.company_id;
      payload.userId = userData.user._id;
      payload.roleId = userData.user.role_id;
      // console.log(payload);
      payload.roleId = userData.user.role_id._id;
      // console.log(payload);
      const res = await fetch(`${API_PATH.NOTE_CREATE}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": userData.access_token,
        },
        body: JSON.stringify(payload),
      });

      const result = await res.json();
      if (result) {
        document.getElementById("closeNoteModal").click();
        onInitFunctions();
      } else {
        return toast.error(
          "Something went wrong! Our team is looking into it.",
          {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          }
        );
      }
    } catch (error) {
      return toast.error("Something went wrong! Our team is looking into it.", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
  };

  const createContent = async (e) => {
    e.preventDefault();
    setSpinner(true);
    document.getElementById("createContentdisabled").disabled = true;
    try {
      let payload = {
        ...contentData,
      };
      payload.description = value;
      payload.companyId = userData.user.company_id;
      payload.userId = userData.user._id;
      payload.roleId = userData.user.role_id._id;
      // console.log(payload);
      const res = await fetch(`${API_PATH.CONTENT_CREATE}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": userData.access_token,
        },
        body: JSON.stringify(payload),
      });

      const result = await res.json();
      if (result) {
        setSpinner(false);
        document.getElementById("contentModal").click();
        document.getElementById("createContentdisabled").disabled = false;
        onInitFunctions();
        return toast.success("Content created successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      } else {
        return toast.error(
          "Something went wrong! Our team is looking into it.",
          {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          }
        );
      }
    } catch (error) {
      setSpinner(false);
      return toast.error("Something went wrong! Our team is looking into it.", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
  };

  const createCover = async (e) => {
    e.preventDefault();
    setSpinner(true);
    document.getElementById("createCoverdisabled").disabled = true;
    try {
      let payload = {
        ...coverData,
      };
      payload.description = value;
      payload.companyId = userData.user.company_id;
      payload.userId = userData.user._id;
      payload.roleId = userData.user.role_id._id;
      // console.log(payload);
      const res = await fetch(`${API_PATH.COVER_CREATE}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": userData.access_token,
        },
        body: JSON.stringify(payload),
      });

      const result = await res.json();
      if (result) {
        setSpinner(false);
        document.getElementById("coverModal").click();
        onInitFunctions();
        return toast.success("Cover created Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      } else {
        return toast.error(
          "Something went wrong! Our team is looking into it.",
          {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          }
        );
      }
    } catch (error) {
      return toast.error("Something went wrong! Our team is looking into it.", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
  };

  const getExtentionFromFileNmae = (fileName) => {
    return (
      fileName
        .substring(fileName.lastIndexOf(".") + 1, fileName.length)
        .toLowerCase() || fileName.toLowerCase()
    );
  };

  const checkFileType = (fileName) => {
    const videoType = ["mp4", "mov", "mkv", "webm", "avi"];
    const audioType = ["mp3", "aav"];
    const imageType = [
      "jpeg",
      "png",
      "gif",
      "tiff",
      "psd",
      "eps",
      "ai",
      "indd",
      "raw",
    ];
    const pdfType = ["pdf"];
    const docType = ["doc", "docx", "odt", "ppt", "pptx"];
    const excelType = ["xls", "xlsx", "ods"];
    const textType = ["txt", "js", "html", "css", "py", "java", "ts"];
    const ext = getExtentionFromFileNmae(fileName);
    if (videoType.includes(ext)) return "video";
    else if (audioType.includes(ext)) return "audio";
    else if (imageType.includes(ext)) return "image";
    else if (pdfType.includes(ext)) return "pdf";
    else if (docType.includes(ext)) return "doc";
    else if (excelType.includes(ext)) return "excel";
    else if (textType.includes(ext)) return "text";
    else return "system";
  };

  const createSleeve = async (e) => {
    e.preventDefault();
    setSpinner(true);
    document.getElementById("createSleevedisabled").disabled = true;
    try {
      if (file) {
        var data = new FormData();
        let fileName = `${Date.now()}-${file.name.split(" ").join("+")}`;
        data.append("file", file, fileName);
        axios
          .request({
            method: "post",
            url: `${API_PATH.UPLOAD_MEDIA}/${userData.user.company_id.shortName}`,
            data: data,
            headers: headers,
            onUploadProgress: (p) => {
              // console.log(p);
              setFileprogress(Math.floor((p.loaded / p.total) * 100));
            },
          })
          .then(async (s3Result) => {
            // console.log(s3Result);

            if (s3Result) {
              let payload = {
                ...sleeveData,
              };
              payload.sleeveType = checkFileType(file.name);
              payload.fileType = file.type;
              payload.fileSize = s3Result.data.data.size;
              payload.fileExt = getExtentionFromFileNmae(file.name);
              payload.sleeveKey = fileName;
              payload.publicUrl = s3Result.data.data.location;
              payload.summary = value;
              payload.companyId = userData.user.company_id;
              payload.userId = userData.user._id;
              payload.roleId = userData.user.role_id._id;
              // console.log(payload);
              const res = await fetch(`${API_PATH.SLEEVE_CREATE}`, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  "x-access-token": userData.access_token,
                },
                body: JSON.stringify(payload),
              });

              const result = await res.json();
              if (result) {
                setSpinner(false);
                document.getElementById("FolderModal").click();
                userData.user.company_id.bucketSize =
                  userData.user.company_id.bucketSize - payload.fileSize;
                localStorage.setItem("userData", JSON.stringify(userData));
                setFileprogress(0);
                onInitFunctions();
                document.getElementById(
                  "createSleevedisabled"
                ).disabled = false;
                return toast.success("Sleeve created successfully", {
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 3000,
                });
              } else {
                return toast.error(
                  "Something went wrong! Our team is looking into it.",
                  {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000,
                  }
                );
              }
            } else {
              return toast.error("Please upload a file", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
              });
            }
          })
          .catch((error) => {
            console.log(error);
          });
        // const s3Res = await fetch(
        //   `${API_PATH.UPLOAD_MEDIA}/${userData.user.company_id.shortName}`,
        //   {
        //     method: "POST",
        //     headers: headers,
        //     body: data,
        //   }
        // );
        // const s3Result = await s3Res.json();
      }
    } catch (error) {
      return toast.error("Something went wrong! Our team is looking into it.", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
  };

  $(window).scroll(function () {
    var scroll = $(window).scrollTop();
    if (scroll > 0) {
      $("#header").addClass("active");
    } else {
      $("#header").removeClass("active");
    }
  });

  document.title = "Client | Call Recordings";
  return (
    <>
      <MobileMenu />
      <div className="page-wrapper chiller-theme toggled">
        <div className="main-sec d-lg-flex">
          <Sidebar />
        </div>
        <main className="body-total content-wrapper float-start w-100">
          <div>
            {/* <div className="d-block d-lg-block">
              <div className="top-bar-menu float-start" id="header">
                <TopMenu />
              </div>
            </div> */}

            <div className="bk-div float-start w-100">
              {/* <div className="dasb-div2 bk-div float-start w-100"> */}
              <div className="body-expart-div request-page projects-scope">
                <div className="request-body-sec collaborate-page">
                  {}
                  <div className="folder-sections mt-3">
                    <Transcript />
                  </div>

                  {/* <div className="notes-sections mt-5">
                    <NotesSetions
                      noteList={noteListData}
                      spinner={spinner}
                    />
                  </div> */}

                  {/* <div className="folder-sections mt-5">
                    <FolderSections folderList={folderData} />
                  </div> */}
                  {/* {recentData && recentData.length > 0 ? (
                    <div className="recente-vew-divs mt-5">
                      <h2 className="search-btn-back">
                        {" "}
                        Recently Viewed ({recentData
                          ? recentData.length
                          : 0}){" "}
                      </h2>

                      <div className="">
                        <RecenetViewTable />
                      </div>
                    </div>
                  ) : (
                    <></>
                  )} */}
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
      <ToastContainer />
      <div
        className="modal fade addmodal cm-modal-all"
        id="NotesModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Modal title
              </h5>
              <button
                id="closeNoteModal"
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                style={{ marginTop: "-20px" }}
              ></button>
            </div>
            <div className="modal-body">
              <div className="inside-comon-modal">
                <h2 className="text-center"> Add Your Note </h2>
                {/* <p className="text-center">
                  {" "}
                  Lorem ipsum dolor sit amet, consectetur{" "}
                </p> */}
                <form method="get" action="">
                  <div className="form-group">
                    <label> Note Title * </label>
                    <input
                      type="text"
                      name="name"
                      onChange={handleNotesInputs}
                      className="form-control"
                      placeholder="Note TItle"
                    />
                  </div>

                  <div className="form-group">
                    <label> Description * </label>
                    <div className="message-modal">
                      <textarea
                        placeholder="Write...."
                        name="description"
                        onChange={handleNotesInputs}
                        className="form-control d-none"
                      ></textarea>
                      <figure className="mx-auto d-none">
                        <img src="images/textarea-img.png" alt="texta" />
                      </figure>
                      <MailEditor setValue={setValue} config={config} />
                    </div>
                  </div>
                  <div className="">
                    <input
                      type="submit"
                      className="btn submit-bn"
                      value="Add Note"
                      onClick={createNote}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade addmodal cm-modal-all"
        id="contentModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Create Your Content
              </h5>
              <button
                id="closeContentModal"
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                style={{ marginTop: "-20px" }}
              ></button>
            </div>
            <div className="modal-body">
              <div className="inside-comon-modal">
                <h2 className="text-center">Create Your Content</h2>
                {/* <p className="text-center">
                  {" "}
                  Lorem ipsum dolor sit amet, consectetur{" "}
                </p> */}
                <form method="get" action="">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label> Content Title* </label>
                        <input
                          type="text"
                          name="name"
                          onChange={handleContentInputs}
                          className="form-control"
                          // placeholder="Content Title*"
                        />
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="form-group">
                        <label> Pick Folder </label>

                        <select
                          className="form-select"
                          aria-label="Default select example"
                          name="coverId"
                          onChange={handleContentInputs}
                        >
                          <option>Please select a folder</option>
                          {getCoverList()}
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="form-group">
                    <label> Content Area * </label>
                    <div className="message-modal">
                      <textarea
                        placeholder="Write...."
                        className="form-control d-none"
                        name="description"
                        onChange={handleContentInputs}
                      ></textarea>
                      <figure className="mx-auto d-none">
                        <img src="images/textarea-img.png" alt="texta" />
                      </figure>
                      <MailEditor setValue={setValue} config={config} />
                    </div>
                  </div>

                  <div className="">
                    <Button
                      className="btn submit-bn"
                      style={{ textTransform: "capitalize" }}
                      onClick={createContent}
                      id="createContentdisabled"
                    >
                      {spinner ? (
                        <>
                          <Spinner
                            size="sm"
                            style={{
                              width: "1rem",
                              height: "1rem",
                              marginRight: "5px",
                            }}
                          />
                          Create Content
                        </>
                      ) : (
                        "Create Content"
                      )}
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade addmodal cm-modal-all"
        id="coverModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Create New Folder
              </h5>
              <button
                id="closeCoverModal"
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                style={{ marginTop: "-20px" }}
              ></button>
            </div>
            <div className="modal-body">
              <div className="inside-comon-modal">
                <h2 className="text-center"> Create New Folder </h2>
                {/* <p className="text-center">
                  {" "}
                  Lorem ipsum dolor sit amet, consectetur{" "}
                </p> */}
                <form method="get" action="">
                  <div className="form-group">
                    <label> Folder Name * </label>
                    <input
                      type="text"
                      name="name"
                      onChange={handleCoversInputs}
                      className="form-control"
                      // placeholder="Note TItle"
                    />
                  </div>

                  <div className="form-group">
                    <label> Description * </label>
                    <div className="message-modal">
                      <textarea
                        name="description"
                        onChange={handleCoversInputs}
                        placeholder="Write...."
                        className="form-control d-none"
                      ></textarea>
                      <figure className="mx-auto d-none">
                        <img src="images/textarea-img.png" alt="texta" />
                      </figure>
                      <MailEditor setValue={setValue} config={config} />
                    </div>
                  </div>
                  <div className="">
                    <Button
                      className="btn submit-bn"
                      style={{ textTransform: "capitalize" }}
                      onClick={createCover}
                      id="createCoverdisabled"
                    >
                      {spinner ? (
                        <>
                          <Spinner
                            size="sm"
                            style={{
                              width: "1rem",
                              height: "1rem",
                              marginRight: "5px",
                            }}
                          />
                          Create Folder
                        </>
                      ) : (
                        "Create Folder"
                      )}
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade addmodal cm-modal-all"
        id="FolderModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Add Your Files
              </h5>
              <button
                id="closeSleeveModal"
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                style={{ marginTop: "-20px" }}
              ></button>
            </div>
            <div className="modal-body">
              <div className="inside-comon-modal">
                <h2 className="text-center">Add Your Files</h2>
                {/* <p className="text-center">
                  {" "}
                  Lorem ipsum dolor sit amet, consectetur{" "}
                </p> */}
                <form method="get" action="">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label> File Name Title* </label>
                        <input
                          type="text"
                          name="sleeveName"
                          onChange={handleSleevesInputs}
                          className="form-control"
                          // placeholder="Content Title*"
                        />
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="form-group">
                        <label> Pick Folder </label>

                        <select
                          className="form-select"
                          aria-label="Default select example"
                          name="coverId"
                          onChange={handleSleevesInputs}
                        >
                          <option>Please select a folder</option>
                          {getCoverList()}
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="form-group">
                    <label style={{ width: "100%" }}> Content Area * </label>

                    {/* <div className="file-upload">
                      <label className="file-upload__label">
                        <span>
                          <img src="images/file-img2.png" allt="icn" />
                        </span>
                        Drag and drop your Files
                      </label>
                      
                      <input className="file-upload__input" type="file" />
                    </div> */}
                    <FileUploader handleChange={handleFileChange} name="file" />

                    {fileprogress === 0 ? (
                      <></>
                    ) : (
                      <Progress
                        strokeColor={{
                          "0%": "#108ee9",
                          "100%": "#87d068",
                        }}
                        percent={fileprogress}
                      />
                    )}
                    {/* <Dragger {...props}>
                      <p className="ant-upload-drag-icon">
                        <UploadFileIcon />
                      </p>
                      <p className="ant-upload-text">
                        Click or drag file to this area to upload
                      </p>
                    </Dragger> */}
                  </div>

                  <div className="">
                    <Button
                      className="btn submit-bn"
                      style={{ textTransform: "capitalize" }}
                      onClick={createSleeve}
                      id="createSleevedisabled"
                    >
                      {spinner ? (
                        <>
                          <Spinner
                            size="sm"
                            style={{
                              width: "1rem",
                              height: "1rem",
                              marginRight: "5px",
                            }}
                          />
                          Create File
                        </>
                      ) : (
                        "Create File"
                      )}
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Collaborate;
