import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Spinner } from "reactstrap";
import "../App.css";
import "../assests/css/custom.css";
import API_PATH from "../Constants/api-path";
import AllDataManagement from "./ManagementTableApi";
// import no_result from "../assests/images/No-data-rafiki.svg";
import axios from "axios";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Col, Modal, Row } from "react-bootstrap";
import { Form, Dropdown, Menu, Table } from "antd";
import { MoreOutlined } from "@ant-design/icons";
import {
  MdDelete,
  MdModeEditOutline,
  MdOutlineCheck,
  MdOutlineClose,
} from "react-icons/md";
import AvatarGroup from "react-avatar-group";

function UserMangementTable({ data, deleteUser, load, getUsers }) {
  const [workData, setworkData] = useState(AllDataManagement);
  // console.log(workData);
  const history = useNavigate();
  const userData = JSON.parse(localStorage.getItem("userData"));
  const headers = {
    "x-access-token": userData.access_token,
  };
  const [spinner, setSpinner] = useState(false);
  const [visible, setVisible] = useState(false);
  const InputType = visible ? "text" : "password";
  const [isName, setIsName] = useState("");
  const [isEmail, setIsEmail] = useState("");
  const [isCode, setIsCode] = useState("");
  const [isNumber, setIsNumber] = useState("");
  const [isContactCode, setIsContactCode] = useState("");
  const [isCountry, setIsCountry] = useState("");
  const [isCompany, setIsCompany] = useState("");
  const [isPassword, setIsPassword] = useState("");
  const [showCreateModal, setCreateModal] = useState(false);
  const [isRoleId, setIsRoleId] = useState("");
  const [showDeleteModal, setDeleteModal] = useState(false);

  const [updateUserId, setUpdateUserId] = useState("");

  const [isUserId, setIsUserId] = useState("");

  const [userList, setUserList] = useState([]);
  const [message, setMessage] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [rolesData, setRolesData] = useState([""]);

  const [form] = Form.useForm();

  let UserData = JSON.parse(localStorage.getItem("userData"));

  const updateUserModal = async () => {
    if (
      isName !== "" &&
      isEmail !== "" &&
      // isCode !== "" &&
      isNumber !== "" &&
      // isCountry !== "" &&
      isRoleId !== ""
    ) {
      try {
        const regEx = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!regEx.test(isEmail.toLowerCase())) {
          //setChekemail(true)
          //console.log("Email is invalid");
          return setMessage("Email is invalid");
        } else {
          //console.log("empty")
          setMessage("");
          let email = isEmail.split("@");
          if (
            email[1] === "gmail.com" ||
            email[1] === "outlook.com" ||
            email[1] === "yahoo.com" ||
            email[1] === "inbox.com" ||
            email[1] === "icloud.com" ||
            email[1] === "mail.com"
          ) {
            //console.log("here");
            return setMessage("Please Provide Valid Email");
          } else {
            setMessage("");
          }
          //return
        }

        setSpinner(true);

        const res = await fetch(
          `${API_PATH.USER_MANAGEMENT_UPDATE}/${updateUserId}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            // console.log("rolesData", res.data);

            body: JSON.stringify({
              firstNmae: isName,
              email: isEmail,
              contact_number: isNumber,
              contact_code: isContactCode,
              country: isCountry,
              role_id: isRoleId,
              name: isName,
            }),
          }
        );

        if (res.status === 200) {
          setSpinner(false);
          // window.location.reload();
          getUsers();
          return toast.success("User updated successfully!", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
        } else {
          return toast.error("something went wrong server error", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
        }
      } catch (error) {
        return toast.error("something went wrong server error", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    } else {
      return toast.warning("All fields are mandatory!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
  };

  // const updateUserModal = async (e) => {
  //   e.preventDefault();
  //   console.log(updateUserModal);
  //   try {
  //     const res = await fetch(
  //       `${API_PATH.ROLE_MANAGEMENT_UPDATE}/${isRoleId}`,

  //       {
  //         username: isName,
  //         email: isEmail,
  //         contactCode: isCode,
  //         contactNumber: isNumber,
  //         country: isCountry,
  //         company: isCompany,
  //         password: isPassword,
  //         role_id: isRoleId,
  //       }
  //     );

  //     if (res.data.status === 200) {
  //       console.log(res.status);
  //       history.push("/find-expert");
  //       return toast.success("UserData Updated Successful", {
  //         position: toast.POSITION.TOP_RIGHT,
  //         autoClose: 3000,
  //       });
  //     }
  //   } catch (error) {
  //     return toast.error(error.response.data.message, {
  //       position: toast.POSITION.TOP_RIGHT,
  //       autoClose: 3000,
  //     });
  //   }
  // };

  const getUserList = async () => {
    try {
      const res = await axios.get(
        `${API_PATH.ROLE_MANAGEMENT}?company_id=${userData?.user?.company_id?._id}`,
        {
          method: "GET",
          headers: headers,
        }
      );
      const response = res.data;
      // console.log(response);
      setRolesData(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  // const getUserList = async () => {
  //   try {
  //     const res = await axios.get(
  //       `${API_PATH.USER_MANAGEMENT}/${UserData.user.company_id._id}`,
  //       {
  //         method: "GET",
  //         headers: headers,
  //       }
  //     );
  //     const response = res.data;
  //     console.log(response, "response");
  //     setUserList(response.data);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  useEffect(() => {
    getUserList();
  }, []);

  const setUserModal = (curElem) => {
    setIsName(curElem?.name);
    setIsEmail(curElem?.email);
    setIsNumber(curElem?.contact_number);
    setIsContactCode(curElem?.contact_code);
    setIsCountry(curElem?.country);
    setIsRoleId(curElem?.role_id ? curElem?.role_id?._id : "");
    setUpdateUserId(curElem?._id);
  };

  // console.log(setUpdateUserId, "user_id");
  // const deleteUserModal = (curElem) => {};    //console.log(id)

  const getUserId = (id) => {
    setIsUserId(id);
    // console.log(deleteId);
  };

  const deleteUserModal = () => {
    // console.log(isUserId, "deleteUserModal");
    deleteUser(isUserId);
  };

  const menu = (curElem) => (
    <Menu>
      <Menu.Item
        key="edit"
        onClick={() => {
          setUserModal(curElem);
          setCreateModal(true);
        }}
      >
        <span style={{ fontSize: "14px" }}>
          <MdModeEditOutline style={{ marginRight: "8px" }} />
          Edit
        </span>
      </Menu.Item>
      <Menu.Item
        key="delete"
        onClick={() => {
          getUserId(curElem?._id);
          setDeleteModal(true);
        }}
      >
        <span>
          <MdDelete style={{ marginRight: "8px" }} />
          Delete
        </span>
      </Menu.Item>
    </Menu>
  );

  const convertDate = (date) => {
    //console.log(date)
    if (date !== null && date !== "") {
      var d = new Date(date);
      var day = d.getDate();
      var x = d.toDateString().substr(4, 3);
      var year = d.getFullYear();
      let p = `${x} ${day}, ${year}`;
      //console.log(p)
      return p;
    } else {
      return "---------";
    }
  };

  const columns = [
    {
      title: "USER NAME",
      dataIndex: "name",
      key: "name",
      render: (text, record) => {
        const addedBy = record?.name;
        if (!addedBy || !addedBy[0]) {
          // Handle the case when 'addedBy' or 'addedBy[0]' is not defined
          return null; // or any appropriate fallback
        }

        return (
          <div className="d-flex align-items-center text-center">
            <div className="me-2">
              <AvatarGroup
                avatars={[addedBy]}
                uppercase={true}
                className="team-info"
                initialCharacters={2}
                fontColor="#f33636"
                max={2}
                // bold={true}
                size={30}
                fontSize=".38"
                backgroundColor="#EFEFEF"
              />
            </div>

            <span>{addedBy}</span>
          </div>
        );
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Role",
      dataIndex: "role_id",
      key: "role_id",
      render: (role_id) => `${role_id ? role_id?.name : "-"}`,
    },
    {
      title: "Contact Number",
      dataIndex: "contact_number",
      key: "contact_number",
      render: (text, record) => {
        const countryCode = record?.contact_code;

        const formattedCountryCode =
          countryCode && !countryCode.includes("+")
            ? `+${countryCode === null ? "" : countryCode}`
            : countryCode === null
            ? ""
            : countryCode;

        const combinedNumber = `${formattedCountryCode} ${text}`;

        return <span>{combinedNumber}</span>;
      },
    },
    {
      title: "Added on",
      dataIndex: "created_at",
      render: (created_at) => `${convertDate(created_at)}`,
    },
    {
      title: "Action",

      render: (record) => (
        <Dropdown
          overlay={menu(record)}
          trigger={["click"]}
          placement="bottomRight"
        >
          <MoreOutlined style={{ fontSize: "20px", cursor: "pointer" }} />
        </Dropdown>
      ),
    },
  ];

  const countryOptions = [
    { code: "+1", name: "United States" },
    { code: "+44", name: "United Kingdom" },
    { code: "+61", name: "Australia" },
    { code: "+33", name: "France" },
    { code: "+49", name: "Germany" },
    { code: "+81", name: "Japan" },
    { code: "+91", name: "India" },
    { code: "+86", name: "China" },
    { code: "+55", name: "Brazil" },
    { code: "+52", name: "Mexico" },
    { code: "+39", name: "Italy" },
    { code: "+7", name: "Russia" },
    { code: "+82", name: "South Korea" },
    { code: "+351", name: "Portugal" },
    { code: "+34", name: "Spain" },
    { code: "+54", name: "Argentina" },
    { code: "+63", name: "Philippines" },
    { code: "+234", name: "Nigeria" },
    { code: "+62", name: "Indonesia" },
    { code: "+55", name: "Anguilla" },
    { code: "+1264", name: "Antigua and Barbuda" },
    { code: "+54", name: "Argentina" },
    { code: "+374", name: "Armenia" },
    { code: "+297", name: "Aruba" },
    { code: "+61", name: "Australia" },
    { code: "+43", name: "Austria" },
    { code: "+994", name: "Azerbaijan" },
    { code: "+1242", name: "Bahamas" },
    { code: "+973", name: "Bahrain" },
    { code: "+880", name: "Bangladesh" },
    { code: "+1246", name: "Barbados" },
    { code: "+375", name: "Belarus" },
    { code: "+32", name: "Belgium" },
    { code: "+501", name: "Belize" },
    { code: "+229", name: "Benin" },
    { code: "+1441", name: "Bermuda" },
    { code: "+975", name: "Bhutan" },
    { code: "+591", name: "Bolivia" },
    { code: "+387", name: "Bosnia and Herzegovina" },
    { code: "+267", name: "Botswana" },
    { code: "+55", name: "Brazil" },
    { code: "+246", name: "British Indian Ocean Territory" },
    { code: "+1284", name: "British Virgin Islands" },
    { code: "+673", name: "Brunei" },
    { code: "+359", name: "Bulgaria" },
    { code: "+226", name: "Burkina Faso" },
    { code: "+257", name: "Burundi" },
    { code: "+855", name: "Cambodia" },
    { code: "+237", name: "Cameroon" },
    { code: "+1", name: "Canada" },
    { code: "+238", name: "Cape Verde" },
    { code: "+599", name: "Caribbean Netherlands" },
    { code: "+1345", name: "Cayman Islands" },
    { code: "+236", name: "Central African Republic" },
    { code: "+235", name: "Chad" },
    { code: "+56", name: "Chile" },
    { code: "+86", name: "China" },
    { code: "+57", name: "Colombia" },
    { code: "+269", name: "Comoros" },
    { code: "+242", name: "Congo - Brazzaville" },
    { code: "+243", name: "Congo - Kinshasa" },
    { code: "+682", name: "Cook Islands" },
    { code: "+506", name: "Costa Rica" },
    { code: "+225", name: "Côte d’Ivoire" },
    { code: "+385", name: "Croatia" },
    { code: "+53", name: "Cuba" },
    { code: "+599", name: "Curaçao" },
    { code: "+357", name: "Cyprus" },
    { code: "+420", name: "Czech Republic" },
    { code: "+45", name: "Denmark" },
    { code: "+253", name: "Djibouti" },
    { code: "+1767", name: "Dominica" },
    { code: "+1809", name: "Dominican Republic" },
    { code: "+670", name: "East Timor" },
    { code: "+593", name: "Ecuador" },
    { code: "+20", name: "Egypt" },
    { code: "+503", name: "El Salvador" },
    { code: "+240", name: "Equatorial Guinea" },
    { code: "+291", name: "Eritrea" },
    { code: "+372", name: "Estonia" },
    { code: "+251", name: "Ethiopia" },
    { code: "+500", name: "Falkland Islands" },
    { code: "+298", name: "Faroe Islands" },
    { code: "+679", name: "Fiji" },
    { code: "+358", name: "Finland" },
    { code: "+33", name: "France" },
    { code: "+594", name: "French Guiana" },
    { code: "+689", name: "French Polynesia" },
    { code: "+241", name: "Gabon" },
    { code: "+220", name: "Gambia" },
    { code: "+995", name: "Georgia" },
    { code: "+49", name: "Germany" },
    { code: "+233", name: "Ghana" },
    { code: "+350", name: "Gibraltar" },
    { code: "+30", name: "Greece" },
    { code: "+299", name: "Greenland" },
    { code: "+1473", name: "Grenada" },
    { code: "+590", name: "Guadeloupe" },
    { code: "+1671", name: "Guam" },
    { code: "+502", name: "Guatemala" },
    { code: "+44", name: "Guernsey" },
    { code: "+224", name: "Guinea" },
    { code: "+245", name: "Guinea-Bissau" },
    { code: "+592", name: "Guyana" },
    { code: "+509", name: "Haiti" },
    { code: "+504", name: "Honduras" },
    { code: "+852", name: "Hong Kong SAR China" },
    { code: "+36", name: "Hungary" },
    { code: "+354", name: "Iceland" },
    { code: "+91", name: "India" },
    { code: "+62", name: "Indonesia" },
    { code: "+98", name: "Iran" },
    { code: "+964", name: "Iraq" },
    { code: "+353", name: "Ireland" },
    { code: "+44", name: "Isle of Man" },
    { code: "+972", name: "Israel" },
    { code: "+39", name: "Italy" },
    { code: "+1876", name: "Jamaica" },
    { code: "+81", name: "Japan" },
    { code: "+44", name: "Jersey" },
    { code: "+962", name: "Jordan" },
    { code: "+7", name: "Kazakhstan" },
    { code: "+254", name: "Kenya" },
    { code: "+686", name: "Kiribati" },
    { code: "+383", name: "Kosovo" },
    { code: "+965", name: "Kuwait" },
    { code: "+996", name: "Kyrgyzstan" },
    { code: "+856", name: "Laos" },
    { code: "+371", name: "Latvia" },
    { code: "+961", name: "Lebanon" },
    { code: "+266", name: "Lesotho" },
    { code: "+231", name: "Liberia" },
    { code: "+218", name: "Libya" },
    { code: "+423", name: "Liechtenstein" },
    { code: "+370", name: "Lithuania" },
    { code: "+352", name: "Luxembourg" },
    { code: "+853", name: "Macau SAR China" },
    { code: "+389", name: "Macedonia" },
    { code: "+261", name: "Madagascar" },
    { code: "+265", name: "Malawi" },
    { code: "+60", name: "Malaysia" },
    { code: "+960", name: "Maldives" },
    { code: "+223", name: "Mali" },
    { code: "+356", name: "Malta" },
    { code: "+692", name: "Marshall Islands" },
    { code: "+596", name: "Martinique" },
    { code: "+222", name: "Mauritania" },
    { code: "+230", name: "Mauritius" },
    { code: "+262", name: "Mayotte" },
    { code: "+52", name: "Mexico" },
    { code: "+691", name: "Micronesia" },
    { code: "+373", name: "Moldova" },
    { code: "+377", name: "Monaco" },
    { code: "+976", name: "Mongolia" },
    { code: "+382", name: "Montenegro" },
    { code: "+1664", name: "Montserrat" },
    { code: "+212", name: "Morocco" },
    { code: "+258", name: "Mozambique" },
    { code: "+95", name: "Myanmar (Burma)" },
    { code: "+264", name: "Namibia" },
    { code: "+674", name: "Nauru" },
    { code: "+977", name: "Nepal" },
    { code: "+31", name: "Netherlands" },
    { code: "+687", name: "New Caledonia" },
    { code: "+64", name: "New Zealand" },
    { code: "+505", name: "Nicaragua" },
    { code: "+227", name: "Niger" },
    { code: "+234", name: "Nigeria" },
    { code: "+683", name: "Niue" },
    { code: "+672", name: "Norfolk Island" },
    { code: "+850", name: "North Korea" },
    { code: "+1670", name: "Northern Mariana Islands" },
    { code: "+47", name: "Norway" },
    { code: "+968", name: "Oman" },
    { code: "+92", name: "Pakistan" },
    { code: "+680", name: "Palau" },
    { code: "+970", name: "Palestinian Territories" },
    { code: "+507", name: "Panama" },
    { code: "+675", name: "Papua New Guinea" },
    { code: "+595", name: "Paraguay" },
    { code: "+51", name: "Peru" },
    { code: "+63", name: "Philippines" },
    { code: "+48", name: "Poland" },
    { code: "+351", name: "Portugal" },
    { code: "+1", name: "Puerto Rico" },
    { code: "+974", name: "Qatar" },
    { code: "+262", name: "Réunion" },
    { code: "+40", name: "Romania" },
    { code: "+7", name: "Russia" },
    { code: "+250", name: "Rwanda" },
    { code: "+590", name: "Saint Barthélemy" },
    { code: "+290", name: "Saint Helena" },
    { code: "+1869", name: "Saint Kitts and Nevis" },
    { code: "+1758", name: "Saint Lucia" },
    { code: "+590", name: "Saint Martin" },
    { code: "+508", name: "Saint Pierre and Miquelon" },
    { code: "+1784", name: "Saint Vincent and the Grenadines" },
    { code: "+685", name: "Samoa" },
    { code: "+378", name: "San Marino" },
    { code: "+239", name: "São Tomé and Príncipe" },
    { code: "+966", name: "Saudi Arabia" },
    { code: "+221", name: "Senegal" },
    { code: "+381", name: "Serbia" },
    { code: "+248", name: "Seychelles" },
    { code: "+232", name: "Sierra Leone" },
    { code: "+65", name: "Singapore" },
    { code: "+1721", name: "Sint Maarten" },
    { code: "+421", name: "Slovakia" },
    { code: "+386", name: "Slovenia" },
    { code: "+677", name: "Solomon Islands" },
    { code: "+252", name: "Somalia" },
    { code: "+27", name: "South Africa" },
    { code: "+82", name: "South Korea" },
    { code: "+211", name: "South Sudan" },
    { code: "+34", name: "Spain" },
    { code: "+94", name: "Sri Lanka" },
    { code: "+249", name: "Sudan" },
    { code: "+597", name: "Suriname" },
    { code: "+47", name: "Svalbard and Jan Mayen" },
    { code: "+268", name: "Swaziland" },
    { code: "+46", name: "Sweden" },
    { code: "+41", name: "Switzerland" },
    { code: "+963", name: "Syria" },
    { code: "+886", name: "Taiwan" },
    { code: "+992", name: "Tajikistan" },
    { code: "+255", name: "Tanzania" },
    { code: "+66", name: "Thailand" },
    { code: "+670", name: "Timor-Leste" },
    { code: "+228", name: "Togo" },
    { code: "+690", name: "Tokelau" },
    { code: "+676", name: "Tonga" },
    { code: "+1868", name: "Trinidad and Tobago" },
    { code: "+216", name: "Tunisia" },
    { code: "+90", name: "Turkey" },
    { code: "+993", name: "Turkmenistan" },
    { code: "+1649", name: "Turks and Caicos Islands" },
    { code: "+688", name: "Tuvalu" },
    { code: "+1340", name: "U.S. Virgin Islands" },
    { code: "+256", name: "Uganda" },
    { code: "+380", name: "Ukraine" },
    { code: "+971", name: "United Arab Emirates" },
    { code: "+44", name: "United Kingdom" },
    { code: "+1", name: "United States" },
    { code: "+598", name: "Uruguay" },
    { code: "+998", name: "Uzbekistan" },
    { code: "+678", name: "Vanuatu" },
    { code: "+39", name: "Vatican City" },
    { code: "+58", name: "Venezuela" },
    { code: "+84", name: "Vietnam" },
    { code: "+681", name: "Wallis and Futuna" },
    { code: "+212", name: "Western Sahara" },
    { code: "+967", name: "Yemen" },
    { code: "+260", name: "Zambia" },
    { code: "+263", name: "Zimbabwe" },
  ];

  const handlePhoneChange = (value, country) => {
    setIsNumber(value);

    const selectedCountry = countryOptions?.find(
      (option) => option?.code === `+${country?.dialCode}`
    );

    console.log("Selected country:", selectedCountry);

    // const contact_code = country?.dialCode;
    // const contact_number = value?.replace(country?.dialCode, "");

    // const isValidContact = contact_code && contact_number;

    if (selectedCountry) {
      setIsContactCode(country?.dialCode);
      setIsCountry(selectedCountry?.name);
      setIsNumber(value?.replace(country?.dialCode, ""));
      // setAddUser((prevStep3) => ({
      //   ...prevStep3,
      //   contact_code: country?.dialCode,
      //   contact_number: value?.replace(country?.dialCode, ""),
      //   country: selectedCountry?.name,
      // }));
      // setContactValid(isValidContact);
    } else {
      console.log("No matching country found for dial code:", country.dialCode);
    }
  };

  return (
    <>
      <Table
        dataSource={data}
        columns={columns}
        loading={!load}
        pagination={false}
        // onChange={handleTableChange}
        scroll={{ x: 800 }}
        size="large"
      />

      <Modal
        show={showCreateModal}
        onHide={() => setCreateModal(false)}
        backdrop="static"
        keyboard={false}
        centered
        size={"lg"}
        scrollable={true}
        dialogClassName={"bootStrapModal"}
        contentClassName={"bootStrapModalContent"}
      >
        <Modal.Body className="position-relative">
          <MdOutlineClose
            className="position-absolute"
            style={{ right: "15px", top: "15px", fontSize: "25px" }}
            onClick={() => setCreateModal(false)}
          />
          <div className="text-left">
            <h4 className="modalHeader">Edit User</h4>
          </div>
          <div>
            <Form form={form} layout="vertical">
              <div className="projects-modal-divs">
                <div className="row mt-5">
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label> Name *</label>
                      <input
                        type="text"
                        className="form-control"
                        name="username"
                        // placeholder="Johnson Willianson"
                        value={isName}
                        onChange={(e) => setIsName(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>Email Address *</label>
                      <input
                        type="email"
                        name="email"
                        className="form-control"
                        readOnly
                        // placeholder="Enter email address"
                        value={isEmail}
                        onChange={(e) => setIsEmail(e.target.value)}
                      />
                    </div>
                    {message === "" ? (
                      <></>
                    ) : (
                      <p className="text-danger">{message}</p>
                    )}
                  </div>

                  <div className="col-lg-6">
                    <div className="form-group">
                      <label> Contact Number * </label>
                      <div>
                        <PhoneInput
                          country={"us"}
                          enableSearch={true}
                          value={isNumber}
                          dropdownStyle={{
                            position: "fixed",
                          }}
                          onChange={(value, country) => {
                            handlePhoneChange(value, country);
                          }}
                          inputStyle={{
                            width: "100%",
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>Role *</label>
                      <select
                        className="form-select"
                        aria-label="Default select example"
                        value={isRoleId}
                        onChange={(e) => setIsRoleId(e.target.value)}
                        name="role_name"
                        id="role_name"
                      >
                        <option value="select"> select</option>
                        {rolesData?.map((option, index) => (
                          <option value={option?._id} key={index}>
                            {option?.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  {/* <div className="col-lg-12 mt-5">
                    <button
                      // data-bs-dismiss="modal"
                      type="submit"
                      className="btn col-lg-5 mx-auto btnsm"
                      onClick={() => updateUserModal()}
                      id="editUser"
                    >
                      {spinner ? (
                        <>
                          <Spinner
                            size="sm"
                            style={{
                              width: "1rem",
                              height: "1rem",
                              marginRight: "5px",
                            }}
                          />
                          Edit User
                        </>
                      ) : (
                        "Edit User"
                      )}
                    </button>
                  </div> */}
                </div>
              </div>
            </Form>
          </div>
          <div className="d-flex justify-content-end mt-3">
            <button
              className="secondaryBttn btn"
              onClick={() => setCreateModal(false)}
            >
              Cancel
            </button>
            <button
              type="submit"
              onClick={() => {
                setCreateModal(false);
                updateUserModal();
              }}
              className="viewButton btn ms-2"
            >
              Submit
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={showDeleteModal}
        onHide={() => setDeleteModal(false)}
        backdrop="static"
        keyboard={false}
        centered
        size={"lg"}
        scrollable={true}
        dialogClassName={"bootStrapModal"}
        contentClassName={"bootStrapModalContent"}
      >
        <Modal.Body className="position-relative">
          <MdOutlineClose
            className="position-absolute"
            style={{ right: "15px", top: "15px", fontSize: "25px" }}
            onClick={() => setDeleteModal(false)}
          />
          <div className="text-left">
            <h4 className="modalHeader">Are you sure ?</h4>
          </div>
          <div>
            <span style={{ fontSize: "16px" }}>
              This action cannot be undone. Are you sure you want to delete this{" "}
              meeting ?
            </span>
          </div>
          <div className="d-flex justify-content-end mt-3">
            <button
              className="secondaryBttn btn"
              onClick={() => setDeleteModal(false)}
            >
              No
            </button>
            <button
              onClick={() => {
                deleteUserModal();
                setDeleteModal(false);
              }}
              className="viewButton btn ms-2"
            >
              Yes
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
export default UserMangementTable;
