import React from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { FaRedo } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import userColored from "../assests/images/userColored.svg";
function NotesSetions({ noteList }) {
  // console.log(noteList);
  const convertDate = (date) => {
    if (date !== null && date !== "" && date !== undefined) {
      const localDate = new Date(date);
      const localDateString = localDate.toLocaleDateString(undefined, {
        day: "numeric",
        month: "short",
        year: "numeric",
      });

      const localTimeString = localDate.toLocaleTimeString(undefined, {
        hour: "2-digit",
        minute: "2-digit",
      });

      let p = `${localDateString} ${localTimeString}`;
      return p;
    } else {
      return "---------";
    }
  };

  function RemoveHtml(myString) {
    return myString.replace(/(<([^>]+)>)/gi, "");
  }

  const getNewsTable = () => {
    if (noteList instanceof Array) {
      return noteList.map(function (object, i) {
        const { _id, firstNmae, description, createdAt, permittedUsers, name } =
          object;
        return (
          <>
            <NavLink to={`/share/note/${_id}`}>
              <div className="col notesParent">
                <div className="comon-notes">
                  <h6 className="note-titel"> {name} </h6>
                  <p className="paragraph">
                    {" "}
                    <p className="text-wrap-afte-three-line">
                      {RemoveHtml(description)}
                    </p>{" "}
                  </p>
                  <div className="auto-sec">
                    <p
                      style={{ fontSize: "12px", fontWeight: 500 }}
                      className="m-0"
                    >
                      {" "}
                      Created By{" "}
                      <span className="createdByName">
                        {object.userId.firstNmae
                          ? object.userId.firstNmae
                          : object.userId.username}
                      </span>
                    </p>
                    <div className="d-flex align-items-center justify-content-between">
                      <h6 className="mt-3 mb-0"> {convertDate(createdAt)} </h6>
                      <OverlayTrigger
                        trigger="hover"
                        key={i}
                        placement={"top"}
                        overlay={
                          <Popover id={`popover-positioned-${i}`}>
                            <Popover.Body className="p-2">
                              {/* <h6 className="mb-0" style={{ fontSize: "12px" }}>
                                Public link enabled
                              </h6> */}
                              <div>
                                <small>
                                  Shared with {permittedUsers?.length} people
                                </small>
                              </div>
                            </Popover.Body>
                          </Popover>
                        }
                      >
                        {permittedUsers?.length > 0 ? (
                          <img
                            style={{ position: "relative", top: "11px" }}
                            src={userColored}
                          />
                        ) : (
                          <></>
                        )}
                      </OverlayTrigger>
                    </div>
                  </div>
                </div>
              </div>
            </NavLink>
          </>
        );
      });
    }
  };

  return (
    <>
      <div className="d-flex top-ps-seection justify-content-between align-items-center">
        <h2 className="mb-0 search-btn-back"> Notes </h2>
        {/* <button
          className="btn add-ps-btn"
          data-bs-toggle="modal"
          data-bs-target="#NotesModal"
        >
          {" "}
          <BsPlusLg /> Create{" "}
        </button> */}
      </div>

      <div className="notes-d-section">
        <div className="row row-cols-1 mt-4 row-cols-sm-2 row-cols-lg-2 row-cols-xxl-4 row-cols-xl-3">
          {getNewsTable()}
        </div>
        {noteList.length > 6 ? (
          <a
            id="#"
            className="loading-btn btn text-center mx-auto d-table mt-4"
          >
            {" "}
            <FaRedo /> Load More Notes{" "}
          </a>
        ) : null}
      </div>
    </>
  );
}
export default NotesSetions;
