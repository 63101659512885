import React, { useState } from "react";
import Icon from "react-icons-kit";
import { AiOutlineCheckCircle, AiFillCheckCircle } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Spinner } from "reactstrap";
import API_PATH from "../Constants/api-path";
import "./ChangePasswordForm.css";

const ChangePasswordForm = () => {
  let history = useNavigate();
  const [passwordError, setPasswordError] = useState("");
  const [passwordErrorTwo, setPasswordErrorTwo] = useState("");
  const [visible, setVisible] = useState(false);
  const InputType = visible ? "text" : "password";
  const [visible2, setVisible2] = useState(false);
  const InputType2 = visible2 ? "text" : "password";
  const [visible3, setVisible3] = useState(false);
  const InputType3 = visible3 ? "text" : "password";
  const [spinner, setSpinner] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [ModalToggle, setModalToggle] = useState(false);
  const [ResponseMSG, setResponseMSG] = useState("");
  const [lowerValidated, setLowerValidated] = useState(false);
  const [upperValidated, setUpperValidated] = useState(false);
  const [numberValidated, setNumberValidated] = useState(false);
  const [specialValidated, setSpecialValidated] = useState(false);
  const [lengthValidated, setLengthValidated] = useState(false);
  const [trackerBox, setTrackerBox] = useState(false);
  const [passwordDetails, setPasswordDetails] = useState({
    current_Password: "",
    new_Password: "",
    confirm_Password: "",
  });
  const UserPassword = JSON.parse(localStorage.getItem("ChangePassword"));
  const UserData = JSON.parse(localStorage.getItem("userData"));

  // console.log(UserPassword);

  const handleInputs = (e) => {
    const { name, value } = e.target;
    const list = { ...passwordDetails, [name]: value };
    // checkPassword(list);
    setPasswordDetails(list);
  };

  const handleTrackerBox = () => {
    setTrackerBox(true);
  };

  const handleChange = (e) => {
    const key = e.target.name;
    const value = e.target.value;

    setPasswordDetails({ ...passwordDetails, new_Password: value });
    const minMaxLength = /^[\s\S]{8,16}$/;
    const upper = new RegExp("[A-Z]");
    const lower = new RegExp("[a-z]");
    const special = new RegExp("(?=.*[!@#$%^&*])");
    const number = new RegExp("[0-9]");
    if (lower.test(value)) {
      setLowerValidated(true);
    } else {
      setLowerValidated(false);
    }
    if (upper.test(value)) {
      setUpperValidated(true);
    } else {
      setUpperValidated(false);
    }
    if (number.test(value)) {
      setNumberValidated(true);
    } else {
      setNumberValidated(false);
    }

    if (minMaxLength.test(value)) {
      setLengthValidated(true);
    } else {
      setLengthValidated(false);
    }
    if (special.test(value)) {
      setSpecialValidated(true);
    } else {
      setSpecialValidated(false);
    }
  };




  const checkPassword = (password) => {
    const { new_Password, confirm_Password, current_Password } = password;
    // if (UserPassword !== current_Password) {
    //   return setCurrentPassword("Please enter correct password");
    // } else {
    //   // console.log("here");
    //   setCurrentPassword("");
    // }
    if (UserPassword === new_Password) {
      setNewPassword("New password and old password should not be same")
    } else if (!lowerValidated ||
      !upperValidated ||
      !numberValidated ||
      !specialValidated ||
      !lengthValidated) {
      setNewPassword("Password should meet the following criteria")
      return false
    } else if (new_Password !== confirm_Password) {
      setNewPassword("New Password and confirm password must be same");
      return false
    } else {
      setNewPassword("");
      return true
    }

    // if (new_Password.length > 3 || confirm_Password.length > 3) {
    //   var minMaxLength = /^[\s\S]{8,16}$/,
    //     upper = /[A-Z]/,
    //     lower = /[a-z]/,
    //     number = /[0-9]/;

    //   if (
    //     (minMaxLength.test(new_Password) &&
    //       upper.test(new_Password) &&
    //       lower.test(new_Password) &&
    //       number.test(new_Password)) ||
    //     (minMaxLength.test(confirm_Password) &&
    //       upper.test(confirm_Password) &&
    //       lower.test(confirm_Password) &&
    //       number.test(confirm_Password))
    //   ) {
    //     //console.log("Is a Valid password")
    //     setNewPassword("");
    //   } else {
    //     //console.log("PassWord Should contain atleast one Uppercase, Lowercase and Numeric")
    //     return setNewPassword(
    //       `Password must contain:
    //       a minimum of 1 lower case letter [a-z] and
    //       a minimum of 1 upper case letter [A-Z] and
    //       a minimum of 1 numeric character [0-9]`
    //     );
    //   }
    // }
    if (confirm_Password.length > 3) {
      if (new_Password !== confirm_Password) {
        return setNewPassword("New Password and confirm password must be same");
      } else {
        setNewPassword("");
      }
    }
  };
  const handelSubmit = async (e) => {
    e.preventDefault();
    if (
      passwordDetails.current_Password !== "" &&
      passwordDetails.new_Password !== "" &&
      passwordDetails.confirm_Password !== "" && checkPassword(passwordDetails)
    ) {
      // if (passwordDetails.current_Password === passwordDetails.new_Password) {
      //   return toast.error("Please enter a new password", {
      //     position: toast.POSITION.TOP_RIGHT,
      //     autoClose: 3000,
      //   });
      // } else {
      setSpinner(true);
      // document.getElementById("changePassword").disabled = true;
      try {
        const res = await fetch(
          `${API_PATH.CHANGE_PASSWORD}/${UserData.user._id}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              password: passwordDetails.new_Password,
              current_Password: passwordDetails.current_Password,
            }),
          }
        );

        const data = await res.json();
        if (data) {
          setSpinner(false);
          if (data.success === true) {
            localStorage.setItem(
              "ChangePassword",
              JSON.stringify(passwordDetails.new_Password)
            );
            localStorage.setItem("isAuth", true);

            setTimeout(() => {
              history("/");
            }, 2000);
            return toast.success("Password updated successfully", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
            });
          } else {
            return toast.error(data.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
            });
          }
        } else {
          setSpinner(false);
          return toast.error("Something went wrong! Our team is looking into it.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
        }
      } catch (error) {
        setSpinner(false);
        // console.log("Not working", error);
        return toast.error("Something went wrong! Our team is looking into it.", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    }
  }

  if (ModalToggle) {
    if (ResponseMSG === "Your account has been updated") {
      return toast.success(ResponseMSG, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    } else {
      return toast.error(ResponseMSG, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
  }

  // useEffect(() => {
  //   document.body.style.overflow = "";
  //   return () => {
  //     document.body.style.overflow = "";
  //   };
  // }, []);
  return (
    <div className="row justify-content-center">
      <div className="col-12 col-md-6">
        <p className="" style={{ fontSize: "12px" }}>
          To change your current password, please enter your Old Password first,
          followed by your preference for a new one.
        </p>
      </div>
      <div className="row mt-2 d-flex justify-content-center align-items-center py-3">
        <div className="col-md-6">
          <div className="form-group mb-3">
            <label> Old Password </label>
            <div className="lable-input">
              <input
                name="current_Password"
                // id="password-field"
                value={passwordDetails.current_Password}
                type={InputType}
                onChange={handleInputs}
                onBlur={(e) => {
                  const value = e.target.value;
                  // const cleanInput = DOMPurify.sanitize(value);
                  if (value !== UserPassword) {
                    setCurrentPassword("Please enter a correct password")
                  } else {
                    setCurrentPassword("")
                  }

                }}
                className="form-control"
              // placeholder="Password"
              />
              <span onClick={() => setVisible((visibilty) => !visibilty)}>
                {visible ? (
                  <i className="fa fa-fw fa-eye" aria-hidden="false"></i>
                ) : (
                  <i className="fa fa-fw fa-eye-slash" aria-hidden="true"></i>
                )}
              </span>
            </div>
            {currentPassword === "" ? (
              <></>
            ) : (
              <p className="text-danger">{currentPassword}</p>
            )}
          </div>
          <div className="form-group mb-3">
            <label> New Password </label>
            <div className="lable-input">
              <input
                name="new_Password"
                // id="password-field"
                type={InputType2}
                value={passwordDetails.new_Password}
                onChange={handleChange}
                onBlur={(e) => {
                  const value = e.target.value;
                  // const cleanInput = DOMPurify.sanitize(value);
                  const list = { ...passwordDetails, [e.target.name]: value };
                  checkPassword(list);
                }}
                className="form-control"
                onFocus={() => handleTrackerBox()}
              // placeholder="Password"
              />
              <span onClick={() => setVisible2((visibilty2) => !visibilty2)}>
                {visible2 ? (
                  <i className="fa fa-fw fa-eye" aria-hidden="false"></i>
                ) : (
                  <i className="fa fa-fw fa-eye-slash" aria-hidden="true"></i>
                )}
              </span>
            </div>
            {newPassword === "" ? (
              <></>
            ) : (
              <p className="text-danger">{newPassword}</p>
            )}
            <main className={trackerBox ? "tracker-box active" : "tracker-box"}>
              <div className={lowerValidated ? "validated" : "not-validated"}>
                {lowerValidated ? (
                  <span className="list-icon green">
                    <AiFillCheckCircle style={{ fontSize: "20px" }} />
                  </span>
                ) : (
                  <span className="list-icon">
                    <AiOutlineCheckCircle style={{ fontSize: "20px" }} />
                  </span>
                )}
                At least one lowercase letter
              </div>
              <div className={upperValidated ? "validated" : "not-validated"}>
                {upperValidated ? (
                  <span className="list-icon green">
                    <AiFillCheckCircle style={{ fontSize: "20px" }} />
                  </span>
                ) : (
                  <span className="list-icon">
                    <AiOutlineCheckCircle style={{ fontSize: "20px" }} />
                  </span>
                )}
                At least one uppercase letter
              </div>
              <div className={specialValidated ? "validated" : "not-validated"}>
                {specialValidated ? (
                  <span className="list-icon green">
                    <AiFillCheckCircle style={{ fontSize: "20px" }} />
                  </span>
                ) : (
                  <span className="list-icon">
                    <AiOutlineCheckCircle style={{ fontSize: "20px" }} />
                  </span>
                )}
                At least one special character
              </div>
              <div className={numberValidated ? "validated" : "not-validated"}>
                {numberValidated ? (
                  <span className="list-icon green">
                    <AiFillCheckCircle style={{ fontSize: "20px" }} />
                  </span>
                ) : (
                  <span className="list-icon">
                    <AiOutlineCheckCircle style={{ fontSize: "20px" }} />
                  </span>
                )}
                At least one number
              </div>
              <div className={lengthValidated ? "validated" : "not-validated"}>
                {lengthValidated ? (
                  <span className="list-icon green">
                    <AiFillCheckCircle style={{ fontSize: "20px" }} />
                  </span>
                ) : (
                  <span className="list-icon">
                    <AiOutlineCheckCircle style={{ fontSize: "20px" }} />
                  </span>
                )}
                At least 8 characters
              </div>
            </main>
          </div>
          <div className="form-group mb-3">
            <label> Retype New Password</label>
            <div className="lable-input">
              <input
                name="confirm_Password"
                // id="password-field"
                value={passwordDetails.confirm_Password}
                type={InputType3}
                onChange={handleInputs}
                className="form-control"
                onBlur={(e) => {
                  const value = e.target.value;
                  // const cleanInput = DOMPurify.sanitize(value);
                  const list = { ...passwordDetails, [e.target.name]: value };
                  checkPassword(list);
                }}
              // placeholder="Password"
              />
              <span onClick={() => setVisible3((visibilty3) => !visibilty3)}>
                {visible3 ? (
                  <i className="fa fa-fw fa-eye" aria-hidden="false"></i>
                ) : (
                  <i className="fa fa-fw fa-eye-slash" aria-hidden="true"></i>
                )}
              </span>
            </div>
            {newPassword === "" ? (
              <></>
            ) : !["Password should meet the following criteria", "New password and old password should not be same"].includes(newPassword) && (
              <p className="text-danger">{newPassword}</p>
            )}
          </div>
          <div className="mt-5 col-md-12 text-center">
            {/* {currentPassword === "" && newPassword === "" ? ( */}
            <button
              className="btn btn-primary profile-button"
              style={{ backgroundColor: "#5746ec" }}
              type="button"
              onClick={handelSubmit}
              id="changePassword"
            >
              {spinner ? (
                <>
                  <Spinner
                    size="sm"
                    style={{
                      width: "1rem",
                      height: "1rem",
                      marginRight: "5px",
                    }}
                  />
                  Change Password
                </>
              ) : (
                "Change Password"
              )}
            </button>
            {/* // ) : (
            //   <button
            //     className="btn btn-primary profile-button"
            //     style={{ backgroundColor: "#5746ec" }}
            //     type="button"
            //     onClick={handelSubmit}
            //     disabled
            //   >
            //     Change Password
            //   </button>
            // )} */}
          </div>
        </div>
      </div>
    </div >
  );
};

export default ChangePasswordForm;
