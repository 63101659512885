import $ from "jquery";
import React, { useEffect, useState } from "react";
import { FaRedo } from "react-icons/fa";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../App.css";
import defaultNewsw from "../assests/images/default-news.png";
import ErrorMessage from "../assests/images/Group 39136.svg";
import API_PATH from "../Constants/api-path";
import MobileMenu from "../MobileMenu";
import Sidebar from "../Sidebar";
import TopMenu2 from "../TopMenu2";

function News() {
  document.title = "Expert Cloud -  News";
  const userData = JSON.parse(localStorage.getItem("userData"));
  const headers = {
    "x-access-token": userData.access_token,
  };
  const [newsData, setnewsData] = useState([]);
  const [newsDataOffset, setnewsDataOffset] = useState(0);
  const [isThereAnyMoreData, setisThereAnyMoreData] = useState(true);
  const [loading, setLoading] = useState(false);
  let allNewsData = [];

  useEffect(() => {
    document.body.style.overflow = "";
    return () => {
      document.body.style.overflow = "";
    };
  }, []);

  const getNewsList = async () => {
    try {
      const res = await fetch(
        `${API_PATH.NEWS_LIST}?offset=${newsDataOffset}&searchKeyword=India`,
        {
          method: "GET",
          headers: headers,
        }
      );

      const result = await res.json();
      console.log("ds", result);
      if (result) {
        setLoading(true);
        const previousLength = newsData.length;
        const lookingKey = "title";
        allNewsData = [...newsData, result];
        allNewsData = [
          ...new Map(
            allNewsData.map((item) => [item[lookingKey], item])
          ).values(),
        ];

        const newLength = allNewsData.length;
        console.log("newLength", newLength);
        if (newLength > previousLength) {
          setnewsData(allNewsData);
          let latestOffset = allNewsData.length;
          setnewsDataOffset(latestOffset);
        } else {
          setisThereAnyMoreData(false);
        }
      } else {
        return toast.error("something went wrong server error", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    } catch (error) {
      return toast.error("something went wrong server error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
  };

  useEffect(() => {
    getNewsList();
  }, []);

  const convertDate = (date) => {
    //console.log(date)
    if (date !== null && date !== "") {
      var d = new Date(date);
      var day = d.getDate();
      var x = d.toDateString().substr(4, 3);
      var year = d.getFullYear();
      let p = `${x} ${day}, ${year}`;
      //console.log(p)
      return p;
    } else {
      return "---------";
    }
  };

  $(window).scroll(function () {
    var scroll = $(window).scrollTop();
    if (scroll > 0) {
      $("#header").addClass("active");
    } else {
      $("#header").removeClass("active");
    }
  });

  const getNewsTable = () => {
    if (newsData instanceof Array) {
      return newsData[0]?.map(function (object, i) {
        const { description, title, source, publishedAt, urlToImage, url } =
          object;
        console.log("description", urlToImage);
        return (
          <>
            <div className="col" key={i}>
              <div className="comon-news">
                <figure>
                  {urlToImage ? (
                    <img src={urlToImage} alt="png" />
                  ) : (
                    <img src={defaultNewsw} alt="png" />
                  )}
                </figure>
                <h5 className="text-wrap-afte-two-line">
                  {" "}
                  <a href={url} target="_blank" rel="noreferrer" />
                  <a
                    href={url}
                    target="_blank"
                    style={{
                      fontSize: "15px",
                      color: "#5746EC",
                      textDecoration: "none",
                    }}
                    rel="noreferrer"
                  >
                    {title}
                  </a>{" "}
                </h5>
                <p className="text-wrap-afte-two-line"> {description}</p>
                <div className="">
                  <h6 className="tags"> {source?.name} </h6>
                </div>
                <h6 className="date-t"> at {convertDate(publishedAt)} </h6>
              </div>
            </div>
          </>
        );
      });
    } else {
      <>
        {loading ? (
          <div className="errorMsg">
            <img src={ErrorMessage} alt="Not Found" />
            <h2 style={{ marginTop: "50px", textAlign: "center" }}>
              Something went Wrong!
            </h2>
          </div>
        ) : (
          ""
        )}
      </>;
    }
  };

  return (
    <>
      <MobileMenu />
      <div className="page-wrapper chiller-theme toggled">
        <div className="main-sec d-lg-flex">
          <div className="sidebar-menu" id="navAccordion">
            <Sidebar />
          </div>
        </div>
        <main className="body-total content-wrapper float-start w-100">
          <div className="container-fluid">
            {/* <div className="d-block d-lg-block">
              <div className="top-bar-menu float-start" id="header">
                <TopMenu2 />
              </div>
            </div> */}

            <div className="dasb-div bk-div float-start w-100">
              <div className="body-expart-div projects-pages support-page">
                <div className="inside-support">
                  <h2> Latest News </h2>
                  <span style={{ marginTop: "32px" }} />
                </div>

                <div className="news-section mt-4 mb-5">
                  <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-2 row-cols-xl-3">
                    {getNewsTable()}
                  </div>
                  {isThereAnyMoreData ? (
                    <a
                      id="#"
                      className="loading-btn btn text-center mx-auto d-table mt-4"
                      onClick={() => getNewsList()}
                    >
                      <FaRedo /> Load More News{" "}
                    </a>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>

      <div
        className="modal fade projects-modal"
        id="ResubmitModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog ">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Modal title
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="projects-modal-divs">
                <div className="d-flex position-relative align-items-center">
                  <h2 className="text-center mx-auto"> Submit Your Request </h2>
                </div>

                <p className="text-center">
                  {" "}
                  Lorem ipsum dolor sit amet, consectetur{" "}
                </p>

                <div className="row mt-5">
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label>Email Address *</label>
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Registered Email Address"
                      />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label>Description *</label>
                      <textarea
                        className="form-control descrip-texarea"
                        placeholder="Please detail out the trouble you are facing and our support will be in touch with 
                          a suitable solution"
                      ></textarea>
                    </div>
                  </div>

                  <div className="col-lg-12 mt-3">
                    <button
                      data-bs-dismiss="modal"
                      type="button"
                      className="btn col-lg-5 mx-auto btnsm"
                    >
                      {" "}
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default News;
