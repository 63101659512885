import axios from "axios";
import React, { useEffect, useState } from "react";
import { Modal, Spinner, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import API_PATH from "../Constants/api-path";
import { MdDelete, MdOutlineClose } from "react-icons/md";
import { Dropdown, Space, Menu, message } from "antd";


function ScopeTeamsli({ datas, id }) {
  console.log(datas, "clientDatas");
  const userData = JSON.parse(localStorage.getItem("userData"));
  const [Loading, setLoading] = useState(true);
  const [ModalToggle, setModalToggle] = useState(false);
  const [ModalToggle1, setModalToggle1] = useState(false);
  const [deleteEmail, setDeleteEmail] = useState("");
  const [intData, setInvtData] = useState("");
  const [modal3Open, setModal3Open] = useState(false);

  const handleClose = () => setModalToggle(false);
  const handleClose1 = () => setModalToggle1(false);

  const [handleDeleteExpert, setDeleteExpert] = useState(false);


  useEffect(() => {
    setLoading(false);
  }, [datas]);

  const DeleteModal = (email, name) => {
    //console.log(email);
    let emailname = email.split(" ");
    // console.log(emailname[0]);
    if (emailname[0] !== "Invite") {
      setDeleteEmail(email);
    } else {
      setDeleteEmail(name);
    }
    setModalToggle(true);
  };

  const convertDate = (date) => {
    //console.log(date)
    if (date !== null && date !== "" && date) {
      var d = new Date(date);
      var day = d.getDate();
      var x = d.toDateString().substr(4, 3);
      var year = d.getFullYear();
      let p = `${x} ${day}, ${year}`;
      //console.log(p)
      return p;
    } else {
      return "--";
    }
  };

  const handelDelete = (deleteEmail) => {
    //console.log(deleteEmail);
    let emailID = deleteEmail.split("@")[0];
    // console.log(emailID);
    axios
      .get(`${API_PATH.REMOVE_INVITES}`, {
        // Add the request body here
        project_id: id,
        invite_team: emailID,
      })
      .then((res) => {
        // console.log(res);
        setModalToggle(false);
        setTimeout(() => {
          window.location.reload();
        }, 3000);
        if (res.data.data === "Remove Successfully") {
          return toast.success("Removed Successfully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
        }

        //window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const editName = (email) => {
    if (typeof email !== "string") {
      return "";
    }

    let emailID = email.split("@")[0];
    let [firstName, lastName] = emailID.split(".");
    firstName = firstName.charAt(0).toUpperCase() + firstName.slice(1);
    lastName = lastName
      ? lastName.charAt(0).toUpperCase() + lastName.slice(1)
      : "";
    return `${firstName} ${lastName}`.trim();
  };

  const DeleteInvite = async () => {
    // console.log(deleteId, "deleteId");
    // e.preventDefault();
    setLoading(true);

    try {
      const res = await fetch(`${API_PATH.PROJECT_INVITE_DELETE}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userData?.token}`,
        },
        body: JSON.stringify({
          project_id: id,
          invite_team: intData,
        }),
      });

      const result = await res.json();
      if (result) {
        if (result.status === 200 || result.status === true) {
          setModal3Open(false);
          message.success("Invite_team remove successfully!!");
          window.location.reload();
        } else {
          message.error("something went wrong server error");
        }
      }
    } catch (error) {
      setLoading(false);

      message.error("something went wrong server error");
    }
    setLoading(false);
  };

  const removeNextynFromEmail = (email) => {
    if (email && email.includes('@nextyn.com')) {
      return email.replace('@nextyn.com', '');
    }
    return email;
  };


  const getInvite = (inviteData) => {
    console.log(inviteData, "inviteDatass");
    setInvtData(inviteData);
  };

  if (!Loading) {
    return (
      <>
        <div className="client-teams-ul-li mt-4">
          <ul className="list-unstyled">
            {datas?.map((curElem, id) => {
              return (
                <>
                  <li key={id} className="mb-3">
                    <div className="teams-li ">
                      <figure>
                        <div className="avatarImg">
                          <img
                            width="40px"
                            src={`https://ui-avatars.com/api/?&rounded=true&font-size=0.38&background=EFEFEF&color=f33636&name=${curElem?.email || curElem?.name || "NoName"
                              }`}
                            alt={curElem?.email || curElem?.name || ""}
                          />
                        </div>
                      </figure>
                      <h5 className="m-0" key={id}>
                        {editName(curElem?.email || curElem?.name)}
                        {curElem?.email && (
                          <span className="d-block">{`${curElem?.email}`}</span>
                        )}
                        <span className="d-block" style={{ color: "#c40a36" }}>
                          {curElem?.Invite === "Invite pending"
                            ? `Invite pending (sent ${convertDate(
                              curElem?.created_at
                            )})`
                            : null}
                        </span>
                      </h5>
                    </div>
                    <div className="dropdown">
                      <div>
                        <MdDelete
                          style={{ fontSize: "20px", cursor: "pointer" }}

                          onClick={() => {
                            setDeleteExpert(true);
                            getInvite(removeNextynFromEmail(curElem?.email));
                          }}
                        />

                      </div>
                    </div>
                    <Modal
                      show={handleDeleteExpert}
                      onHide={() => setDeleteExpert(false)}
                      backdrop="static"
                      keyboard={false}
                      centered
                      size={"md"}
                      scrollable={true}
                      dialogClassName={"bootStrapModal"}
                      contentClassName={"bootStrapModalContent"}
                    >
                      <Modal.Body className="position-relative">
                        <MdOutlineClose
                          className="position-absolute"
                          style={{ right: "15px", top: "15px", fontSize: "25px" }}
                          onClick={() => setDeleteExpert(false)}
                        />
                        <div className="text-left">
                          <h4 className="modalHeader">Are you sure?</h4>
                        </div>
                        <div>
                          Do you really want to delete these records? This process cannot be undone.
                        </div>
                        <div className="d-flex justify-content-end mt-3">
                          <Button className="btn btn-light"
                            onClick={() => setDeleteExpert(false)}>
                            No
                          </Button>
                          <Button className="btn btn-primary ms-2"
                            style={{ backgroundColor: "#5746ec", border: "none" }} onClick={() => DeleteInvite()}>Yes</Button>
                        </div>
                      </Modal.Body>
                    </Modal>
                  </li>
                </>
              );
            })}
          </ul>
        </div>
      </>
    );
  } else {
    return (
      <div className="d-flex justify-content-center">
        <div role="status">
          <Spinner animation="border" />
        </div>
      </div>
    );
  }
}
export default ScopeTeamsli;
