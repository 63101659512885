import Button from "@mui/material/Button";
import React, { useEffect, useState } from "react";
import OtpInput from "react-otp-input-rc-17";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Spinner } from "reactstrap";
import API_PATH from "../Constants/api-path";
import "./OtpVerify.css";

const OtpVerify = () => {
  const history = useNavigate();
  const [otp, setOtp] = useState({
    otp1: "",
    otp2: "",
    otp3: "",
    otp4: "",
    otp5: "",
    otp6: "",
  });
  const [verificationToken, setVerificationToken] = useState("");
  const [count, setCount] = useState(1);
  const [spinner, setSpinner] = useState(false);
  const [disable, setDisable] = useState(false);

  const [state, setState] = useState({
    phone: "",
    otp: "",
  });

  const handleChange = (otp) => setState({ otp });

  const Refresh = async () => {
    try {
      const res = await fetch(`${API_PATH.OTPURL}/user/otp`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          email: localStorage.getItem("registered-email"),
        }),
      });
      const data = await res.json();
      if (data.token) {
        let token1 = atob(data.token);
        setVerificationToken(token1.toString());
      }
    } catch (error) {
      console.log(error);
      return toast.error("something went wrong server error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
  };

  useEffect(() => {
    Refresh();
  }, [count]);

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];

    window.dataLayer.push({
      event: "form_complete",
    });
  }, []);

  // document.addEventListener("DOMContentLoaded", function (event) {
  //   function OTPInput() {
  //     const inputs = document.querySelectorAll("#otp > *[id]");
  //     for (let i = 0; i < inputs.length; i++) {
  //       inputs[i].addEventListener("keydown", function (event) {
  //         if (event.key === "Backspace") {
  //           inputs[i].value = "";
  //           if (i !== 0) inputs[i - 1].focus();
  //         } else {
  //           if (i === inputs.length - 1 && inputs[i].value !== "") {
  //             return true;
  //           } else if (event.keyCode > 47 && event.keyCode < 58) {
  //             inputs[i].value = event.key;
  //             if (i !== inputs.length - 1) inputs[i + 1].focus();
  //             event.preventDefault();
  //           } else if (event.keyCode > 64 && event.keyCode < 91) {
  //             inputs[i].value = String.fromCharCode(event.keyCode);
  //             if (i !== inputs.length - 1) inputs[i + 1].focus();
  //             event.preventDefault();
  //           }
  //         }
  //       });
  //     }
  //   }
  //   OTPInput();
  // });

  const handleOTPChange = (e) => {
    const key = e.target.name;
    const value = e.target.value;
    setOtp({ ...otp, [key]: value });
  };

  const handleOtp = async () => {
    let token = state.otp;
    console.log(token, "token");
    // let pricing = localStorage.getItem("pricing-details");

    if (verificationToken === token) {
      try {
        const res = await fetch(
          `${API_PATH.OTPURL}/user/update-token/${token}`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              verification_token: null,
            }),
          }
        );
        let data = await res.json();
        if (data) {
          if (data.user) {
            // setSpinner(true);
            // setTimeout(() => {
            //   window.location.reload();
            // }, 2000);
            history("/register-step/?step=1");
            data.user = JSON.parse(atob(data.user));
            data.access_token = atob(data.access_token);
            data.refresh_token = atob(data.refresh_token);
            localStorage.setItem("userData", JSON.stringify(data));
          }
          return toast.success(data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
        }
      } catch (error) {
        console.log(error);
        return toast.error("something went wrong server error", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    } else {
      return toast.error("Please enter the otp we sent to your email", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
    setDisable(true);
  };

  const handleResendOTP = async () => {
    try {
      const res = await fetch(`${API_PATH.OTPURL}/user/resend-token`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          email: localStorage.getItem("registered-email"),
        }),
      });
      const data = await res.json();
      if (data) {
        setCount(count + 1);
        return toast.success("Otp resend successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    } catch (error) {
      console.log(error);
      return toast.error("something went wrong server error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
  };

  return (
    <>
      <div className="container-lg">
        <div
          className="row d-flex justify-content-center align-items-center"
          style={{ height: "100vh" }}
        >
          <div className="col-12 col-md-8 col-xl-5 col-xxl-5 text-center">
            <div className="card main-card py-4 px-3">
              <Link className="navbar-brand mb-3" to={"/"}>
                <img src="/images/logo-main.svg" alt="logo" />{" "}
              </Link>
              <h4 className="m-0 p-4">Verify your email address</h4>
              <span className="mobile-text mt-2">
                Enter the verification OTP we just sent you on your email
                address
              </span>
              <div
                id="otp"
                className="d-flex justify-content-center align-items-center flex-row mt-4"
              >
                <OtpInput
                  value={state.otp}
                  onChange={handleChange}
                  numInputs={6}
                  inputStyle={{
                    width: "3rem",
                    height: "3rem",
                    margin: "0 0.5rem",
                    fontSize: "2rem",
                    borderRadius: 4,
                    border: "1px solid #5746ec ",
                  }}
                />
              </div>
              <div className="pt-5 payment-button">
                {" "}
                <Button
                  className="btn verify-btn text-uppercase w-25 n"
                  variant="contained"
                  onClick={handleOtp}
                  disabled={disable}
                >
                  {spinner ? (
                    <>
                      <Spinner
                        size="sm"
                        style={{
                          width: "1rem",
                          height: "1rem",
                          marginRight: "5px",
                        }}
                      />
                      Verify
                    </>
                  ) : (
                    "Verify"
                  )}
                </Button>
              </div>{" "}
              <div className="text-center mt-5">
                <span className="d-block mobile-text">
                  Didn't receive the code?
                </span>
                <span
                  className="font-weight-bold cursor"
                  style={{ color: "#526fd7" }}
                  onClick={handleResendOTP}
                >
                  Resend
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OtpVerify;
