import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import { AutoComplete } from "antd";
import React, { useEffect, useState } from "react";
import { FaBars } from "react-icons/fa";
import "react-toastify/dist/ReactToastify.css";
import API_PATH from "./Constants/api-path";
import "./TopMenu.css";

const renderTitle = (title) => <span>{title}</span>;
const renderItem = (title, link) => ({
  value: title,
  label: (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <a href={link}>{title}</a>
    </div>
  ),
});

function TopMenu(props) {
  const [filteredData, setFilteredData] = useState({});
  const [options, setOptions] = useState([]);
  const [wordEntered, setWordEntered] = useState("");

  const clearInput = () => {
    setFilteredData({});
    setWordEntered("");
  };

  let isAuth = localStorage.getItem("isAuth");
  const handleLogout = () => {
    localStorage.removeItem("userData");
    localStorage.removeItem("isAuth");
  };

  const userData = JSON.parse(localStorage.getItem("userData"));

  const headers = {
    "x-access-token": userData.access_token,
  };

  const handleInputs = async (e) => {
    const searchWord = e.target.value;
    setWordEntered(searchWord);
    const elasticRes = await fetch(
      `${API_PATH.ELASTIC_SEARCH_BY_QUERY}?query=${searchWord}&userId=${userData.user._id}`,
      {
        method: "GET",
        headers: headers,
      }
    );
    let elasticResult = await elasticRes.json();
    if (searchWord === "") {
      setFilteredData({});
    } else {
      localStorage.setItem("searchResult", JSON.stringify(elasticResult));
      setFilteredData(elasticResult);
      console.log(elasticResult, "elasticResult");
      prepareFilterData(elasticResult);
    }
    console.log(elasticResult, "elasticResult");
  };

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  useEffect(() => {}, [options]);

  function randomColor() {
    var color = [];
    for (var i = 0; i < 15; i++) {
      let hex = Math.floor(Math.random() * 0xffffff);
      let color1 = "#" + hex.toString(16);
      color.push(color1);
    }

    return color;
  }

  function changeToBTag(myString) {
    if (myString !== undefined) {
      return {
        __html: myString
          .replace(/<em>/gi, "<b className='higlight-text'>")
          .replace("</em>", "</b>"),
      };
    }
  }

  const prepareFilterData = (elasticResult) => {
    if (elasticResult) {
      if (Object.keys(elasticResult).length !== 0) {
        console.log(elasticResult.length, "elasticResultss");
        // return filteredData.map(function (object, i) {
        //   const { _id, description, transcript } = object;

        let optionsList = [
          {
            label:
              elasticResult.recording.length > 0
                ? renderTitle(
                    <h3 style={{ fontSize: "18px", color: "#5746ec" }}>
                      Transcript
                    </h3>
                  )
                : "",
            options: elasticResult.recording.map((o) => {
              return renderItem(
                o.topic,
                `/call_recordings/transcript?meetingId=${o._id}`
              );
            }),
          },
          {
            label:
              elasticResult.note.length > 0
                ? renderTitle(
                    <h3 style={{ fontSize: "18px", color: "#5746ec" }}>
                      Notes{" "}
                    </h3>
                  )
                : "",
            options: elasticResult.note.map((o) => {
              return renderItem(
                o.name,
                `/call_recordings/notes-details?noteId=${o._id}`
              );
            }),
          },
          {
            label:
              elasticResult.note.sleeve > 0
                ? renderTitle(
                    <h3 style={{ fontSize: "18px", color: "#5746ec" }}>
                      Files
                    </h3>
                  )
                : "",
            options: elasticResult.sleeve.map((o) => {
              return renderItem(
                o.transcript,
                `/call_recordings/notes-details?noteId=${o._id}`
              );
            }),
          },
        ];
        setOptions(optionsList);
      } else {
        return (
          <>
            <p className="dataItem text-center d-flex justify-content-center align-items-center">
              There is no trends acoording to your search!
            </p>
          </>
        );
      }
    } else {
      return (
        <>
          <p className="dataItem text-center d-flex justify-content-center align-items-center">
            There is no trends acoording to your search!
          </p>
        </>
      );
    }
  };

  return (
    <>
      <div className="d-block col-lg-12">
        <div className="right-menu-sec">
          <div className="d-block d-lg-none">
            <a
              className="btn"
              data-bs-toggle="offcanvas"
              href="#offcanvasmenu"
              role="button"
              aria-controls="offcanvasExample"
            >
              <FaBars />
            </a>
          </div>

          <AutoComplete
            popupClassName="certain-category-search-dropdown"
            dropdownMatchSelectWidth={500}
            style={{
              width: "100%",
              position: "relative",
              display: "block",
            }}
            options={options}
          >
            <div className="d-flex serch-sec-div position-relative form-control align-items-center ais-SearchBox-form">
              <input
                name="transcript"
                type="text"
                className="form-control dropdown-toggle"
                id="dropdownMenuButton"
                placeholder="Search.."
                onBlur={handleInputs}
                value={wordEntered}
                onChange={handleInputs}
                autoComplete="off"
              />
              <div className="searchIcon">
                {Object.keys(filteredData).length === 0 ? (
                  <SearchIcon />
                ) : (
                  <CloseIcon id="clearBtn" onClick={clearInput} />
                )}
              </div>
            </div>
          </AutoComplete>
          {/* <div className="topmenu1">
            <div className="d-flex serch-sec-div position-relative form-control align-items-center ais-SearchBox-form">
              <input
                name="transcript"
                type="text"
                className="form-control dropdown-toggle"
                id="dropdownMenuButton"
                placeholder="Search.."
                // onBlur={handleInputs}
                value={wordEntered}
                onChange={handleInputs}
              />
              <div className="searchIcon">
                {filteredData.length === 0 ? null : (
                  <CloseOutlinedIcon id="clearBtn" onClick={clearInput} />
                )}
              </div>

              
            </div>
            {filteredData.length != 0 &&
            filteredData[0] &&
            filteredData[0].highlight ? (
              <div className="dataResult">
                {filteredData.slice(0, 15).map((value, key) => {
                  if (value.highlight) {
                    return (
                      <a
                        className="dataItem"
                        onClick={() => setWordEntered(value._source.sleeveName)}
                        key={key}
                        href={`/collaborate/collaborate-search-details`}
                      >
                        <p
                          dangerouslySetInnerHTML={changeToBTag(
                            value.highlight.transcript[0]
                          )}
                        ></p>
                      </a>
                    );
                  }
                })}
              </div>
            ) : (
              ""
            )}
          </div> */}

          {/* <ul className="list-unstyled m-0 d-flex">
            <Notification />

            <li className="nav-item dropdown">
              <a
                className="nav-link"
                href="#"
                role="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <div className="user-div-top d-flex align-items-center">
                  <figure className="m-0 ms-2">
                    <Avatar
                      style={{ backgroundColor: "#EFEFEF", color: "#f33636" }}
                      sx={{
                        bgcolor: "#EFEFEF",
                        height: "30px",
                        width: "30px",
                      }}
                    >
                      {userData.user.firstNmae === undefined
                        ? userData.user.firstNmae[0].toUpperCase()
                        : userData.user.firstNmae[0].toUpperCase()}
                    </Avatar>
                  </figure>
                </div>
              </a>
              <ul
                className="dropdown-menu account-div shadow"
                aria-labelledby="dropdownMenuButton1"
              >
                <li>
                  <NavLink
                    to="/profile"
                    className="dropdown-item text-decoration-none"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="18"
                      height="18"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path d="M4 22a8 8 0 1 1 16 0h-2a6 6 0 1 0-12 0H4zm8-9c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6zm0-2c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4z" />
                    </svg>
                    <span style={{ marginLeft: "10px" }}> Profile</span>
                  </NavLink>
                </li>

                <li>
                  <NavLink
                    to="/change-password"
                    className="dropdown-item text-decoration-none"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="18"
                      height="18"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path d="M19 10h1a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V11a1 1 0 0 1 1-1h1V9a7 7 0 1 1 14 0v1zM5 12v8h14v-8H5zm6 2h2v4h-2v-4zm6-4V9A5 5 0 0 0 7 9v1h10z" />
                    </svg>
                    <span style={{ marginLeft: "10px" }}>Change Password</span>
                  </NavLink>
                </li>

                <li>
                  <a
                    href="/"
                    className="dropdown-item text-decoration-none"
                    onClick={handleLogout}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="18"
                      height="18"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path d="M5 22a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v3h-2V4H6v16h12v-2h2v3a1 1 0 0 1-1 1H5zm13-6v-3h-7v-2h7V8l5 4-5 4z" />
                    </svg>
                    <span style={{ marginLeft: "10px" }}>Logout</span>
                  </a>
                </li>
              </ul>
            </li>
          </ul> */}
        </div>
      </div>
    </>
  );
}

export default TopMenu;
