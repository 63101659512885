import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import React, { useState,useEffect} from "react";
import { FaBars } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import data from "./Data.json";
import queryString from "query-string";

function TopMenu1({setSearchRgx,searchRgx}) {
  const [wordEntered, setWordEntered] = useState('');
  const [filteredData, setFilteredData] = useState("");

  let history = useNavigate();
  const [checkdatas, setCheckdatas] = useState("");
  const handelChange = (e) => {
    // console.log(e);
    setCheckdatas(e.target.value);
  };

  const clearInput = () => {
    setFilteredData([]);
    setWordEntered("");
    setSearchRgx('')
    let queryParams = queryString.parse(window.location.search);
    Replacepagechange(1,queryParams.country,queryParams.company,queryParams.previousCompany,queryParams.industry,queryParams.expertise,'')
    
  };

  const handleFilter = (event) => {
    const searchWord = event.target.value;
    setWordEntered(searchWord);
    setSearchRgx(searchWord);
    let queryParams = queryString.parse(window.location.search);
    Replacepagechange(1,queryParams.country,queryParams.company,queryParams.previousCompany,queryParams.industry,queryParams.expertise,searchWord)
    
    const newFilter = data.filter((value) => {
      return value.title.toLowerCase().includes(searchWord.toLowerCase());
    });

    if (searchWord === "") {
      setFilteredData([]);
    } else {
      setFilteredData(newFilter);
    }
  };
  //console.log(checkdatas);
  // if (checkdatas === "") {
  //   props(true);
  // } else {
  //   props(false);
  // }
  let isAuth = localStorage.getItem("isAuth");
  const handleLogout = () => {
    localStorage.removeItem("userData");
    localStorage.removeItem("isAuth");
  };

  const userData = JSON.parse(localStorage.getItem("userData"));
  function randomColor() {
    var color = [];
    for (var i = 0; i < 15; i++) {
      let hex = Math.floor(Math.random() * 0xffffff);
      let color1 = "#" + hex.toString(16);
      color.push(color1);
    }

    return color;
  }

  useEffect(() => {
  //   let queryParams = queryString.parse(window.location.search);
  //   if (1) {
  //     console.log(queryParams)
  //     1 = atob(1);
  //     if (!isNaN(parseInt(1))) {
      
  //       setSearchRgx(wordEntered);
  //       Replacepagechange(1,queryParams.country,queryParams.company,queryParams.industry,queryParams.expertise,wordEntered)
  //     }
  //   } else {
  //     Replacepagechange(1,queryParams.country, queryParams.company, queryParams.expertise, queryParams.industry, wordEntered);
  //   }
   
  //   setTimeout(() => {
      
  //   }, 3000);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  setWordEntered(searchRgx)
  }, [searchRgx]);



  const Replacepagechange = (ctPage,country, company,previousCompany, industry,tags,searchRgx) => {
    history({
      pathname: `/find-expert`,
      search: `?country=${country}&company=${company}&previousCompany=${previousCompany}&industry=${industry}&expertise=${tags}&search=${searchRgx}&ctPage=${btoa(ctPage)}`,
    });
  };


  return (
    <>
      <div className="d-block col-lg-12">
        <div className="right-menu-sec">
          <div className="d-block d-lg-none">
            <a
              className="btn"
              data-bs-toggle="offcanvas"
              href="#offcanvasmenu"
              role="button"
              aria-controls="offcanvasExample"
            >
              <FaBars />
            </a>
          </div>
          <div className="topmenu1">
            <div className="d-flex serch-sec-div position-relative form-control align-items-center ais-SearchBox-form">
              <input
                name="transcript"
                type="text"
                className="form-control dropdown-toggle"
                id="dropdownMenuButton"
                placeholder="Search.."
                // onBlur={handleInputs}
                value={wordEntered}
                onChange={handleFilter}
              />
              {wordEntered === "" ? (
                <SearchIcon />
              ) : (
                <CloseIcon id="clearBtn" onClick={clearInput} />
              )}
            </div>
          </div>

          {/* <ul className="list-unstyled m-0 d-flex">
            <Notification />

            <li className="nav-item dropdown">
              <a
                className="nav-link"
                href="#"
                role="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <div className="user-div-top d-flex align-items-center">
                  <figure className="m-0 ms-2">
                    <Avatar
                      style={{ backgroundColor: "#EFEFEF", color: "#f33636" }}
                      sx={{
                        bgcolor: blue[500],
                        height: "30px",
                        width: "30px",
                      }}
                    >
                      {userData.user.firstNmae === undefined
                        ? userData.user.firstNmae[0].toUpperCase()
                        : userData.user.firstNmae[0].toUpperCase()}
                    </Avatar>
                  </figure>
                </div>
              </a>
              <ul
                className="dropdown-menu account-div shadow"
                aria-labelledby="dropdownMenuButton1"
              >
                <li>
                  <NavLink
                    to="/profile"
                    className="dropdown-item text-decoration-none"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="18"
                      height="18"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path d="M4 22a8 8 0 1 1 16 0h-2a6 6 0 1 0-12 0H4zm8-9c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6zm0-2c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4z" />
                    </svg>
                    <span style={{ marginLeft: "10px" }}>Profile</span>
                  </NavLink>
                </li>

                <li>
                  <NavLink
                    to="/change-password"
                    className="dropdown-item text-decoration-none"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="18"
                      height="18"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path d="M19 10h1a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V11a1 1 0 0 1 1-1h1V9a7 7 0 1 1 14 0v1zM5 12v8h14v-8H5zm6 2h2v4h-2v-4zm6-4V9A5 5 0 0 0 7 9v1h10z" />
                    </svg>
                    <span style={{ marginLeft: "10px" }}>Change Password</span>
                  </NavLink>
                </li>

                <li>
                  <a
                    href="/"
                    className="dropdown-item text-decoration-none"
                    onClick={handleLogout}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="18"
                      height="18"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path d="M5 22a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v3h-2V4H6v16h12v-2h2v3a1 1 0 0 1-1 1H5zm13-6v-3h-7v-2h7V8l5 4-5 4z" />
                    </svg>
                    <span style={{ marginLeft: "10px" }}>Logout</span>
                  </a>
                </li>
              </ul>
            </li>
          </ul> */}
        </div>
      </div>
    </>
  );
}

export default TopMenu1;
