import React, { useEffect } from "react";
import { Avatar, Button, Comment, Form, Input, List } from "antd";
import moment from "moment";
import { useState } from "react";
import { Tooltip } from "antd";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";

import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import ReactPlayer from "react-player";
import { DocumentViewer } from "react-documents";
import FileViewer from "react-file-viewer";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";

const { TextArea } = Input;

const CommentList = ({ comments }) => (
  <List
    dataSource={comments}
    header={`${comments.length} ${comments.length > 1 ? "replies" : "reply"}`}
    itemLayout="horizontal"
    renderItem={(props) => <Comment {...props} />}
  />
);

const Editor = ({ onChange, onSubmit, submitting, value }) => (
  <>
    <Form.Item>
      <TextArea rows={4} onChange={onChange} value={value} />
    </Form.Item>
    <Form.Item>
      <button
        type="submit"
        className="btn col-lg-5 btnsm"
        style={{ padding: "10px 10px" }}
      >
        Add Comment
      </button>
    </Form.Item>
  </>
);

const SharedRecentViewDetails = ({ datas }) => {
  // console.log(datas);

  // const handleSubmit = () => {
  //   if (!value) return;
  //   setSubmitting(true);
  //   setTimeout(() => {
  //     setSubmitting(false);
  //     setValue("");
  //     setComments([
  //       ...comments,
  //       {
  //         author: "Han Solo",
  //         avatar: "https://joeschmoe.io/api/v1/random",
  //         content: <p>{value}</p>,
  //         datetime: moment().fromNow(),
  //       },
  //     ]);
  //   }, 1000);
  // };

  // const handleChange = (e) => {
  //   setValue(e.target.value);
  // };

  document.addEventListener("contextmenu", function (e) {
    e.preventDefault();
  });

  const setFiles = (data, publicUrl) => {
    // console.log(data);
    if (data !== null && data !== undefined) {
      let str = data.split("/");
      // console.log(str[0]);
      if (str[0] === "audio") {
        return (
          <AudioPlayer
            src={publicUrl}
            onPlay={(e) => console.log("onPlay")}
            // other props here
          />
        );
      } else if (str[0] === "video") {
        return (
          <ReactPlayer
            width="100%"
            height="calc(100vh - 200px)"
            controls={true}
            url={publicUrl}
            config={{ file: { attributes: { controlsList: "nodownload" } } }}
          />
        );
      } else if (
        str[0] === "doc" ||
        str[0] === "docx" ||
        str[0] === "pdf" ||
        str[0] === "xls" ||
        str[0] === "xlsx" ||
        str[0] === "ppt" ||
        str[0] === "pptx" ||
        str[0] === "txt" ||
        str[0] === "csv" ||
        str[0] === "system"
      ) {
        return (
          <DocumentViewer
            queryParams="hl=Nl"
            url={publicUrl}
            style={{ height: "80vh", width: "100%" }}
          ></DocumentViewer>
        );
      } else if (str[0] === "image") {
        return <img src={publicUrl} alt="No Image" style={{ width: "100%" }} />;
      }
    } else {
      return "";
    }
  };
  if (Object.keys(datas).length !== 0) {
    return (
      <>
        <div className="row">
          <div className="col-md-12">
            {setFiles(datas.sleeveType, datas.publicUrl)}
          </div>
          {/* <div className="col-md-4">
            <>
              <h6>Comment</h6>
              {comments.length > 0 && <CommentList comments={comments} />}
              <Comment
                avatar={
                  <Avatar
                    src="https://joeschmoe.io/api/v1/random"
                    alt="Han Solo"
                  />
                }
                content={
                  <Editor
                    onChange={handleChange}
                    onSubmit={handleSubmit}
                    submitting={submitting}
                    value={value}
                  />
                }
              />
              <List
                className="comment-list"
                header={`${data.length} replies`}
                itemLayout="horizontal"
                dataSource={data}
                renderItem={(item) => (
                  <li>
                    <Comment
                      actions={item.actions}
                      author={item.author}
                      avatar={item.avatar}
                      content={item.content}
                      datetime={item.datetime}
                    />
                  </li>
                )}
              />
            </>
          </div> */}
        </div>
      </>
    );
  }
};

export default SharedRecentViewDetails;
