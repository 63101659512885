import "antd/dist/antd.css"; // or 'antd/dist/antd.less'
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Spinner } from "reactstrap";
import API_PATH from "../Constants/api-path";
import Sidebar from "../Sidebar";
const AddRoleManagement = () => {
  const userData = JSON.parse(localStorage.getItem("userData"));
  const history = useNavigate();
  const [isRoleName, setIsRoleName] = useState("");
  const [isRoleDescrip, setIsRoleDescript] = useState("");
  const [loading, setLoading] = useState(false);
  const [spinner, setSpinner] = useState(false);

  const [isChecked, setIsChecked] = useState({
    expertView: false,
    projectAdd: false,
    projectView: false,
    projectMessage: false,
    projectList: false,
    collaborationView: false,
    collabrationNotesAdd: false,
    collaborationNotesView: false,
    collaborationNotesDelete: false,
    collaborationTranscriptView: false,
    expertCallView: false,
    userManagementView: false,
    userManagementEdit: false,
    userManagementUpdate: false,
    userManagementDelete: false,
    sharedWithMe: false,
    projectSelectAll: false,
    collaborationSelectAll: false,
    conversationSelectAll: false,
    userManagementSelectAll: false,
  });

  console.log(isChecked, "isChecked");

  let name, value;

  const handleInputs = (e) => {
    name = e.target.name;
    value = e.target.value;

    // Handle "Select All" for each category
    if (name === "projectSelectAll") {
      setIsChecked((prev) => ({
        ...prev,
        projectAdd: !prev.projectAdd,
        projectView: !prev.projectView,
        projectMessage: !prev.projectMessage,
        projectList: !prev.projectList,
        projectSelectAll: !prev.projectSelectAll,
      }));
    } else if (name === "collaborationSelectAll") {
      setIsChecked((prev) => ({
        ...prev,
        collaborationView: !prev.collaborationView,
        collabrationNotesAdd: !prev.collabrationNotesAdd,
        collaborationNotesView: !prev.collaborationNotesView,
        collaborationNotesDelete: !prev.collaborationNotesDelete,
        collaborationFolderView: !prev.collaborationFolderView,
        collaborationFolderAdd: !prev.collaborationFolderAdd,
        collaborationFolderDelete: !prev.collaborationFolderDelete,
        collaborationFilesAdd: !prev.collaborationFilesAdd,
        collaborationFilesDelete: !prev.collaborationFilesDelete,
        collaborationFilesView: !prev.collaborationFilesView,
        collaborationTranscriptView: !prev.collaborationTranscriptView,
        collaborationSelectAll: !prev.collaborationSelectAll,
      }));
    } else if (name === "conversationSelectAll") {
      setIsChecked((prev) => ({
        ...prev,
        conversationView: !prev.conversationView,
        conversationSelectAll: !prev.conversationSelectAll,
      }));
    } else if (name === "userManagementSelectAll") {
      setIsChecked((prev) => ({
        ...prev,
        userManagementView: !prev.userManagementView,
        userManagementEdit: !prev.userManagementEdit,
        userManagementUpdate: !prev.userManagementUpdate,
        userManagementDelete: !prev.userManagementDelete,
        userManagementSelectAll: !prev.userManagementSelectAll,
      }));
    } else {
      setIsChecked((prev) => ({
        ...prev,
        [name]: !prev[name],
      }));

      // Check if all individual checkboxes are selected
      const allSelected =
        Object.keys(isChecked).filter((key) =>
          key.includes(name) && key !== `${name}SelectAll`
        ).every((key) => isChecked[key]);

      // Update "Select All" checkbox accordingly
      setIsChecked((prev) => ({
        ...prev,
        [`${name}SelectAll`]: allSelected,
      }));
    }
  };

  // const handleInputsParam = (name, obj) => {
  //   isChecked[name] === false
  //     ? (isChecked[name] = true)
  //     : (isChecked[name] = false);
  //   console.log(isChecked[name]);
  // };

  // const selectAll = (e) => {
  //   Object.keys(isChecked).forEach((elem) => {
  //     handleInputsParam(elem,obj);
  //   });
  // };

  useEffect(() => { }, []);

  const headers = {
    "x-access-token": userData.access_token,
  };

  // console.log("user", isChecked);

  const submitRolesForm = async (e) => {
    e.preventDefault();

    const payload = {
      ...isChecked,
    };

    if (isRoleName !== "") {
      setSpinner(true);
      document.getElementById("addRoleManagement").disabled = true;
      try {
        const res = await fetch(`${API_PATH.ROLE_MANAGEMENT_POST}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "x-access-token": userData.access_token,
          },
          body: JSON.stringify({
            name: isRoleName,
            description: isRoleDescrip,
            company_id: userData?.user?.company_id?._id,
            type: "company",
            findExperts: {
              view: isChecked.expertView,
            },
            sharedWithMe: {
              view: isChecked.sharedWithMe,
            },

            newsView: {
              view: isChecked.newsView,
            },
            projects: {
              add: isChecked.projectAdd,
              view: isChecked.projectView,
              message: isChecked.projectMessage,
              expertList: isChecked.projectList,
            },
            collaborate: {
              view: isChecked.collaborationView,
              notesAdd: isChecked.collabrationNotesAdd,
              notesView: isChecked.collaborationNotesView,
              notesDelete: isChecked.collaborationNotesDelete,
              transcriptView: isChecked.collaborationTranscriptView,
            },
            expertsCall: {
              view: isChecked.expertCallView,
            },
            userManagement: {
              view: isChecked.userManagementView,
              edit: isChecked.userManagementEdit,
              update: isChecked.userManagementUpdate,
              delete: isChecked.userManagementDelete,
            },
          }),
        });

        const result = await res.json();
        if (result) {
          console.log(result, "result");
          setSpinner(false);
          document.getElementById("addRoleManagement").disabled = false;
          if (result.status === 200) {
            history("/usermangement/roles");
            return toast.success("Role added successfully!", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
            });
          } else {
            return toast.error(result.msg, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
            });
          }
        } else {
          return toast.error(result.msg, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
        }
      } catch (error) {
        return toast.error("something went wrong server error", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    } else {
      return toast.warning("All fields are mandatory!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
    }
  };

  return (
    <>
      <div className="page-wrapper chiller-theme toggled">
        <div className="main-sec d-lg-flex">
          <div className="sidebar-menu" id="navAccordion">
            <Sidebar />
          </div>
        </div>
        <main className="body-total content-wrapper float-start w-100">
          <div className="container-fluid">
            {/* <div className="d-block d-lg-block">
              <div className="top-bar-menu float-start" id="header">
                <TopMenu2 />
              </div>
            </div> */}

            <div className="dasb-div bk-div float-start w-100">
              <div className="body-expart-div projects-pages expert-cell-page">
                <div className="d-flex top-ps-seection justify-content-between align-items-center mb-2">
                  <h2>Add Roles </h2>

                  {/* <NavLink
                    to="/usermangement/addroles"
                    className="btn add-ps-btn"
                  >
                    <BsPlusLg /> Add Role
                  </NavLink> */}
                </div>

                <div className="container rounded bg-white comon-fild">
                  <div className="row">
                    <form onSubmit={submitRolesForm} method="POST">
                      <div className="">
                        <div className="">
                          <div className="d-flex position-relative align-items-center"></div>
                          <div className="row my-3">
                            <div className="col-lg-12">
                              <div className="form-group mb-3">
                                <label>Role *</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="name"
                                  placeholder="Ex. Superadmin, Manager"
                                  value={isRoleName}
                                  onChange={(e) =>
                                    setIsRoleName(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-lg-12">
                              <div className="form-group mb-3">
                                <label>Description</label>
                                <textarea
                                  type="text"
                                  className="form-control"
                                  name="name"
                                  placeholder="Role Description"
                                  value={isRoleDescrip}
                                  onChange={(e) =>
                                    setIsRoleDescript(e.target.value)
                                  }
                                ></textarea>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-12">
                                <div className="form-group">
                                  <label>Role Permission</label>
                                  <div className="table-responsive">
                                    <table className="table-flush-spacing table">
                                      <tbody>
                                        {/* <tr className="">
                                          <td className="text-wrap">
                                            <span
                                              className="me-50"
                                              style={{
                                                color: "#526fd7",
                                                fontWeight: "500",
                                              }}
                                            >
                                              {" "}
                                              Find Experts
                                            </span>{" "}
                                          </td>
                                          <td>
                                            <div
                                              className="form-check me-3 me-lg-5"
                                              style={{ paddingLeft: "3.5rem" }}
                                            >
                                              <input
                                                type="checkbox"
                                                className="form-check-input"
                                                checked={isChecked.expertView}
                                                name="expertView"
                                                value={isChecked.expertView}
                                                onChange={handleInputs}
                                              />
                                              <label
                                                htmlFor="select-all"
                                                className="form-check-label"
                                              >
                                                View
                                              </label>
                                            </div>
                                          </td>
                                        </tr> */}
                                        <tr>
                                          <td
                                            className="text-nowrap"
                                            style={{
                                              color: "#526fd7",
                                              fontWeight: "500",
                                            }}
                                          >
                                            Project
                                          </td>
                                          <td>
                                            <div className="d-flex">
                                              <div className="form-check me-3 me-lg-5" >
                                                <input
                                                  type="checkbox"
                                                  className="form-check-input"
                                                  checked={isChecked.projectSelectAll}
                                                  name="projectSelectAll"
                                                  onChange={handleInputs}
                                                />
                                                <label className="form-check-label">Select All</label>
                                              </div>
                                              <div
                                                className="form-check me-3 me-lg-5"

                                              >
                                                <input
                                                  type="checkbox"
                                                  className="form-check-input"
                                                  checked={isChecked.projectAdd}
                                                  name="projectAdd"
                                                  value={isChecked.projectAdd}
                                                  onChange={handleInputs}
                                                />
                                                <label className="form-check-label">
                                                  Add
                                                </label>
                                              </div>
                                              <div className="form-check me-3 me-lg-5">
                                                <input
                                                  type="checkbox"
                                                  className="form-check-input"
                                                  checked={
                                                    isChecked.projectView
                                                  }
                                                  name="projectView"
                                                  value={isChecked.projectView}
                                                  onChange={handleInputs}
                                                />
                                                <label className="form-check-label">
                                                  View
                                                </label>
                                              </div>
                                              <div className="form-check me-3 me-lg-5">
                                                <input
                                                  name="projectMessage"
                                                  type="checkbox"
                                                  className="form-check-input"
                                                  checked={
                                                    isChecked.projectMessage
                                                  }
                                                  value={
                                                    isChecked.projectMessage
                                                  }
                                                  onChange={handleInputs}
                                                />
                                                <label className="form-check-label">
                                                  Message
                                                </label>
                                              </div>
                                              <div className="form-check me-3 me-lg-5">
                                                <input
                                                  name="projectList"
                                                  type="checkbox"
                                                  className="form-check-input"
                                                  value={isChecked.projectList}
                                                  checked={
                                                    isChecked.projectList
                                                  }
                                                  onChange={handleInputs}
                                                />
                                                <label className="form-check-label">
                                                  Expert List
                                                </label>
                                              </div>
                                            </div>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td
                                            className="text-nowrap"
                                            style={{
                                              color: "#526fd7",
                                              fontWeight: "500",
                                            }}
                                          >
                                            Call Recordings
                                          </td>
                                          <td>
                                            <div className="d-flex">
                                              <div className="form-check me-3 me-lg-5">
                                                <input
                                                  type="checkbox"
                                                  className="form-check-input"
                                                  checked={isChecked?.collaborationSelectAll}
                                                  onChange={handleInputs}
                                                  name="collaborationSelectAll"
                                                />
                                                <label className="form-check-label">Select All</label>
                                              </div>
                                              <div
                                                className="form-check me-3 me-lg-5"

                                              >
                                                <input
                                                  name="collaborationView"
                                                  type="checkbox"
                                                  className="form-check-input"
                                                  value={
                                                    isChecked.collaborationView
                                                  }
                                                  checked={
                                                    isChecked.collaborationView
                                                  }
                                                  onChange={handleInputs}
                                                />
                                                <label className="form-check-label">
                                                  View
                                                </label>
                                              </div>
                                              <div className="form-check me-3 me-lg-5">
                                                <input
                                                  name="collabrationNotesAdd"
                                                  type="checkbox"
                                                  className="form-check-input"
                                                  value={
                                                    isChecked.collabrationNotesAdd
                                                  }
                                                  checked={
                                                    isChecked.collabrationNotesAdd
                                                  }
                                                  onChange={handleInputs}
                                                />
                                                <label className="form-check-label">
                                                  Add Notes
                                                </label>
                                              </div>
                                              <div className="form-check me-3 me-lg-5">
                                                <input
                                                  name="collaborationNotesView"
                                                  type="checkbox"
                                                  className="form-check-input"
                                                  value={
                                                    isChecked.collaborationNotesView
                                                  }
                                                  checked={
                                                    isChecked.collaborationNotesView
                                                  }
                                                  onChange={handleInputs}
                                                />
                                                <label className="form-check-label">
                                                  View Notes
                                                </label>
                                              </div>
                                              <div className="form-check me-3 me-lg-5">
                                                <input
                                                  name="collaborationNotesDelete"
                                                  type="checkbox"
                                                  className="form-check-input"
                                                  value={
                                                    isChecked.collaborationNotesDelete
                                                  }
                                                  checked={
                                                    isChecked.collaborationNotesDelete
                                                  }
                                                  onChange={handleInputs}
                                                />
                                                <label className="form-check-label">
                                                  Delete Notes
                                                </label>
                                              </div>
                                              <div className="form-check me-3 me-lg-5">
                                                <input
                                                  name="collaborationTranscriptView"
                                                  type="checkbox"
                                                  className="form-check-input"
                                                  value={
                                                    isChecked.collaborationTranscriptView
                                                  }
                                                  checked={
                                                    isChecked.collaborationTranscriptView
                                                  }
                                                  onChange={handleInputs}
                                                />
                                                <label className="form-check-label">
                                                  Recording and Transcript View
                                                </label>
                                              </div>
                                              {/* <div className="form-check me-3 me-lg-5">
                                                <input
                                                  name="collaborationFolderAdd"
                                                  type="checkbox"
                                                  className="form-check-input"
                                                  value={
                                                    isChecked.collaborationFolderAdd
                                                  }
                                                  checked={
                                                    isChecked.collaborationFolderAdd
                                                  }
                                                  onChange={handleInputs}
                                                />
                                                <label className="form-check-label">
                                                  Folder Add
                                                </label>
                                              </div>
                                              <div className="form-check me-3 me-lg-5">
                                                <input
                                                  name="collaborationFolderDelete"
                                                  type="checkbox"
                                                  className="form-check-input"
                                                  value={
                                                    isChecked.collaborationFolderDelete
                                                  }
                                                  checked={
                                                    isChecked.collaborationFolderDelete
                                                  }
                                                  onChange={handleInputs}
                                                />
                                                <label className="form-check-label">
                                                  Folder Delete
                                                </label>
                                              </div>
                                              <div className="form-check me-3 me-lg-5">
                                                <input
                                                  name="collaborationFilesAdd"
                                                  type="checkbox"
                                                  className="form-check-input"
                                                  value={
                                                    isChecked.collaborationFilesAdd
                                                  }
                                                  checked={
                                                    isChecked.collaborationFilesAdd
                                                  }
                                                  onChange={handleInputs}
                                                />
                                                <label className="form-check-label">
                                                  Files Add
                                                </label>
                                              </div>

                                              <div className="form-check me-3 me-lg-5">
                                                <input
                                                  name="collaborationFilesDelete"
                                                  type="checkbox"
                                                  className="form-check-input"
                                                  value={
                                                    isChecked.collaborationFilesDelete
                                                  }
                                                  checked={
                                                    isChecked.collaborationFilesDelete
                                                  }
                                                  onChange={handleInputs}
                                                />
                                                <label className="form-check-label">
                                                  Files Delete
                                                </label>
                                              </div>
                                              <div className="form-check me-3 me-lg-5">
                                                <input
                                                  name="collaborationFilesView"
                                                  type="checkbox"
                                                  className="form-check-input"
                                                  value={
                                                    isChecked.collaborationFilesView
                                                  }
                                                  checked={
                                                    isChecked.collaborationFilesView
                                                  }
                                                  onChange={handleInputs}
                                                />
                                                <label className="form-check-label">
                                                  Files View
                                                </label>
                                              </div> */}
                                            </div>
                                          </td>
                                        </tr>
                                        <tr className="">
                                          <td className="text-wrap">
                                            <span
                                              className="me-50"
                                              style={{
                                                color: "#526fd7",
                                                fontWeight: "500",
                                              }}
                                            >
                                              {" "}
                                              Share with me
                                            </span>{" "}
                                          </td>
                                          <td>
                                            <div
                                              className="form-check me-3 me-lg-5"

                                            >
                                              <input
                                                type="checkbox"
                                                className="form-check-input"
                                                checked={isChecked.sharedWithMe}
                                                name="sharedWithMe"
                                                value={isChecked.sharedWithMe}
                                                onChange={handleInputs}
                                              />
                                              <label
                                                htmlFor="select-all"
                                                className="form-check-label"
                                              >
                                                View
                                              </label>
                                            </div>
                                          </td>
                                        </tr>

                                        {/* <tr>
                                    <td className="text-nowrap">
                                      Conversation
                                    </td>
                                    <td>
                                      <div className="d-flex">
                                        <div
                                          className="form-check me-3 me-lg-5"
                                          style={{ paddingLeft: "3.5rem" }}
                                        >
                                          <input
                                            name="conversationView"
                                            type="checkbox"
                                            className="form-check-input"
                                            value={isChecked.conversationView}
                                            checked={isChecked.conversationView}
                                            onChange={handleInputs}
                                          />
                                          <label className="form-check-label">
                                            View
                                          </label>
                                        </div>
                                      </div>
                                    </td>
                                  </tr> */}
                                        {/* <tr>
                                          <td
                                            className="text-nowrap"
                                            style={{
                                              color: "#526fd7",
                                              fontWeight: "500",
                                            }}
                                          >
                                            News
                                          </td>
                                          <td>
                                            <div className="d-flex">
                                              <div
                                                className="form-check me-3 me-lg-5"
                                                style={{
                                                  paddingLeft: "3.5rem",
                                                }}
                                              >
                                                <input
                                                  name="newsView"
                                                  type="checkbox"
                                                  className="form-check-input"
                                                  value={isChecked.newsView}
                                                  checked={isChecked.newsView}
                                                  onChange={handleInputs}
                                                />
                                                <label className="form-check-label">
                                                  View
                                                </label>
                                              </div>
                                            </div>
                                          </td>
                                        </tr> */}
                                        {/* <tr>
                                          <td
                                            className="text-nowrap"
                                            style={{
                                              color: "#526fd7",
                                              fontWeight: "500",
                                            }}
                                          >
                                            Experts Call
                                          </td>
                                          <td>
                                            <div className="d-flex">
                                              <div
                                                className="form-check me-3 me-lg-5"
                                                style={{
                                                  paddingLeft: "3.5rem",
                                                }}
                                              >
                                                <input
                                                  name="expertCallView"
                                                  type="checkbox"
                                                  className="form-check-input"
                                                  value={
                                                    isChecked.expertCallView
                                                  }
                                                  checked={
                                                    isChecked.expertCallView
                                                  }
                                                  onChange={handleInputs}
                                                />
                                                <label className="form-check-label">
                                                  View
                                                </label>
                                              </div>
                                            </div>
                                          </td>
                                        </tr> */}
                                        <tr>
                                          <td
                                            className="text-nowrap"
                                            style={{
                                              color: "#526fd7",
                                              fontWeight: "500",
                                            }}
                                          >
                                            User Management
                                          </td>
                                          <td>
                                            <div className="d-flex">
                                              <div className="form-check me-3 me-lg-5" >
                                                <input
                                                  type="checkbox"
                                                  className="form-check-input"
                                                  checked={isChecked.userManagementSelectAll}
                                                  name="userManagementSelectAll"
                                                  onChange={handleInputs}
                                                />
                                                <label className="form-check-label">Select All</label>
                                              </div>
                                              <div
                                                className="form-check me-3 me-lg-5"
                                                style={{ paddingLeft: "3.5rem" }}
                                              >
                                                <input
                                                  name="userManagementView"
                                                  type="checkbox"
                                                  className="form-check-input"
                                                  value={isChecked.userManagementView}
                                                  checked={isChecked.userManagementView}
                                                  onChange={handleInputs}
                                                />
                                                <label className="form-check-label">
                                                  View
                                                </label>
                                              </div>
                                              <div
                                                className="form-check me-3 me-lg-5"
                                                style={{
                                                  paddingLeft: "3.5rem",
                                                }}
                                              >
                                                <input
                                                  name="userManagementView"
                                                  type="checkbox"
                                                  className="form-check-input"
                                                  value={
                                                    isChecked.userManagementView
                                                  }
                                                  checked={
                                                    isChecked.userManagementView
                                                  }
                                                  onChange={handleInputs}
                                                />
                                                <label className="form-check-label">
                                                  View
                                                </label>
                                              </div>
                                              <div className="form-check me-3 me-lg-5">
                                                <input
                                                  name="userManagementEdit"
                                                  type="checkbox"
                                                  className="form-check-input"
                                                  value={
                                                    isChecked.userManagementEdit
                                                  }
                                                  checked={
                                                    isChecked.userManagementEdit
                                                  }
                                                  onChange={handleInputs}
                                                />
                                                <label className="form-check-label">
                                                  Edit
                                                </label>
                                              </div>
                                              <div className="form-check me-3 me-lg-5">
                                                <input
                                                  name="userManagementUpdate"
                                                  type="checkbox"
                                                  className="form-check-input"
                                                  value={
                                                    isChecked.userManagementUpdate
                                                  }
                                                  checked={
                                                    isChecked.userManagementUpdate
                                                  }
                                                  onChange={handleInputs}
                                                />
                                                <label className="form-check-label">
                                                  Update
                                                </label>
                                              </div>
                                              <div className="form-check me-3 me-lg-5">
                                                <input
                                                  name="userManagementDelete"
                                                  type="checkbox"
                                                  className="form-check-input"
                                                  value={
                                                    isChecked.userManagementDelete
                                                  }
                                                  checked={
                                                    isChecked.userManagementDelete
                                                  }
                                                  onChange={handleInputs}
                                                />
                                                <label className="form-check-label">
                                                  Delete
                                                </label>
                                              </div>
                                            </div>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-lg-12 mt-5">
                              <button
                                // data-bs-dismiss="modal"
                                type="submit"
                                className="btn col-lg-5 mx-auto btnsm"
                                id="addRoleManagement"
                              >
                                {spinner ? (
                                  <>
                                    <Spinner
                                      size="sm"
                                      style={{
                                        width: "1rem",
                                        height: "1rem",
                                        marginRight: "5px",
                                      }}
                                    />
                                    Add Role
                                  </>
                                ) : (
                                  "Add Role"
                                )}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>{" "}
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default AddRoleManagement;
