import axios from "axios";
import { Markup } from "interweave";
import React, { useEffect, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import "../App.css";
import "../assests/css/custom.css";
import no_result from "../assests/images/Empty-bro.svg";
import API_PATH from "../Constants/api-path";

function RequestTableCell({ data }) {
  const [RequestedExperts, setRequestedExperts] = useState("");
  const UserData = JSON.parse(localStorage.getItem("userData"));
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [expert_popup, setExpertPopup] = useState({});
  const [modalToggle, setModalToggle] = useState(false);
  const [modalToggle1, setModalToggle1] = useState(false);

  const [expertID, setExpertID] = useState("");
  const [status, setStatus] = useState("");
  const headers = {
    "Content-Type": "text/plain",
  };
  //console.log(UserData.user.email)
  const handleClose = () => setModalToggle(false);
  const handleClose1 = () => setModalToggle(false);
  const handleClose2 = () => setModalToggle1(false);

  const convertDate = (date) => {
    //console.log(date)
    if (date !== null && date !== "") {
      var d = new Date(date);
      var day = d.getDate();
      var x = d.toDateString().substr(4, 3);
      var year = d.getFullYear();
      let p = `${x} ${day}, ${year}`;
      //console.log(p)
      return p;
    } else {
      return "---------";
    }
  };

  useEffect(() => {
    getExperts();
  }, []);

  // useEffect(() => {
  //   setRequestedExperts(data);
  //   document.body.style.overflow = "";
  //   return () => {
  //     document.body.style.overflow = "";
  //   };
  // }, [RequestedExperts]);

  const getExperts = () => {
    console.log("reached");
    axios
      .get(
        `${API_PATH.ALL_CLIENT_PROJECT_EXPERT_LIST}/${UserData?.user?.email}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${UserData?.refresh_token}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data.data, "resData");
        setRequestedExperts(res?.data?.data);
        setLoading(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // console.log("RequestedExperts", RequestedExperts);
  // const getExpertDetails = (id) => {
  //   setExpertID(id);
  //   axios
  //     .post(
  //       `${API_PATH.EXPERT_POPUP_DETAILS}/${id}/${project_id}`,
  //       UserData.user.email,
  //       { headers }
  //     )
  //     .then((res) => {
  //       setLoading1(true);
  //       setExpertPopup({ ...res.data.data });
  //       //console.log("results", res);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };
  //console.log(data);
  // console.log("expertpopup", expert_popup);

  const expertStatusChanger = (status) => {
    setStatus(status.trim());
    setModalToggle(true);
    //console.log(status);
  };

  const first_charc_uppercase = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const statusSetter = (status) => {
    if (status === "Schedule" || status === "Scheduled") {
      return (
        <p>
          Are you sure you want to schedule a call with this expert? Once you
          confirm, our team will get in touch with you to lock in a convenient
          day and time for the same.{" "}
        </p>
      );
    } else if (status === "Decline") {
      return (
        <project_id>
          Are you sure you want to decline a call with this expert?
        </project_id>
      );
    } else if (status === "Cancel") {
      return <p>Are you sure you want to cancel a call with this expert?</p>;
    } else if (status === "Reschedule" || status === "Rescheduled") {
      return (
        <p>
          Are you sure you want to reschedule a call with this expert? Once you
          confirm, our team will get in touch with you to lock in a convenient
          day and time for the same.
        </p>
      );
    }
  };

  const statusButton = (status) => {
    if (status === "Schedule" || status === "Scheduled") {
      return "Schedule";
    } else if (status === "Decline") {
      return "Decline";
    } else if (status === "Cancel") {
      return "Cancel";
    } else if (status === "Reschedule" || status === "Rescheduled") {
      return "Reschedule";
    }
  };

  // const handleSubmit = async (status) => {
  //   if (
  //     status === "Schedule" ||
  //     status === "Reschedule" ||
  //     status === "Scheduled" ||
  //     status === "Rescheduled"
  //   ) {
  //     axios
  //       .post(
  //         `${API_PATH.CLIENT_SCHEDULE}/${project_id}/${expertID}`,
  //         UserData.user.email,
  //         { headers }
  //       )
  //       .then((res) => {
  //         // console.log(res);
  //         setModalToggle(false);
  //         setModalToggle1(true);
  //         setTimeout(() => {
  //           setModalToggle1(false);
  //           window.location.reload();
  //         }, 2000);
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       });
  //   } else if (status === "Decline") {
  //     axios
  //       .post(
  //         `${API_PATH.CLIENT_DECLINE}/${project_id}/${expertID}`,
  //         UserData.user.email,
  //         { headers }
  //       )
  //       .then((res) => {
  //         // console.log(res);
  //         setModalToggle(false);
  //         setModalToggle1(true);
  //         setTimeout(() => {
  //           setModalToggle1(false);
  //           window.location.reload();
  //         }, 2000);
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       });
  //   } else if (status === "Cancel") {
  //     axios
  //       .post(
  //         `${API_PATH.CLIENT_SCHEDULECANCEL}/${project_id}/${expertID}`,
  //         UserData.user.email,
  //         { headers }
  //       )
  //       .then((res) => {
  //         // console.log(res);
  //         setModalToggle(false);
  //         setModalToggle1(true);
  //         setTimeout(() => {
  //           setModalToggle1(false);
  //           window.location.reload();
  //         }, 2000);
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       });
  //   }
  // };

  // const getCallHistory = () => {
  //   if (RequestedExperts) {
  //     if (RequestedExperts instanceof Array) {
  //       return (
  //         <>
  //           {RequestedExperts.length > 0 ? (
  //             <>
  //               <div>
  //                 <table className="table mt-4">
  //                   <thead>
  //                     <tr>
  //                       <th title="Gender" style={{ width: "40%" }}>
  //                        Most Relevant Title
  //                       </th>
  //                       <th title="Age">Company</th>
  //                       <th title="Age" style={{ width: "140px" }}>
  //                         Duration{" "}
  //                       </th>
  //                       <th title="Age">Currency</th>
  //                       <th title="Age" style={{ width: "140px" }}>
  //                         DATE ADDED
  //                       </th>
  //                       <th style={{ width: "90px" }}>View</th>
  //                     </tr>
  //                   </thead>
  //                   <tbody>
  //                     {RequestedExperts.map((curElem, index) => {
  //                       const {
  //                         status,
  //                         project_title,
  //                         client_company,
  //                         created_at,
  //                         call_duration,
  //                         call_history_id,
  //                         client_rate,
  //                         currency,
  //                       } = curElem;
  //                       const date = created_at.split(" ");
  //                       if (
  //                         project_title !== "") {
  //                         return (
  //                           <tr>
  //                             <td>{project_title}</td>
  //                             <td>{client_company}</td>
  //                             {/* <td>{`$${rate[1]}`}</td> */}
  //                             <td>{call_duration}</td>
  //                             <td>{call_history_id}</td>
  //                             <td>{client_rate}</td>
  //                             <td>{currency}</td>

  //                             <td>{convertDate(date[0])}</td>
  //                             {/* <td>
  //                               <a
  //                                 type="button"
  //                                 style={{ fontWeight: "600", color: "#5746EC" }}
  //                                 data-bs-toggle="offcanvas"
  //                                 data-bs-target="#requested"
  //                                 aria-controls="offcanvasRight"
  //                                 // onClick={() =>
  //                                 //   getExpertDetails(curElem.expert_update)
  //                                 // }
  //                                 key={call_history_id}
  //                               >
  //                                 View
  //                               </a>
  //                             </td> */}
  //                           </tr>
  //                         );
  //                       }
  //                     })}
  //                   </tbody>
  //                 </table>
  //               </div>
  //             </>
  //           ) : (
  //             <>
  //               {loading ? (
  //                 <div className="text-center noResultFound">
  //                   <div className="container">
  //                     <div className="row d-flex justify-content-center align-items-center">
  //                       <div className="col-md-6">
  //                         <img
  //                           src={no_result}
  //                           alt="srv"
  //                           style={{ marginTop: "10px", height: "450px" }}
  //                         ></img>
  //                       </div>
  //                       <div className="col-md-6">
  //                         <h3
  //                           style={{
  //                             fontSize: "18px",
  //                             textAlign: "left",
  //                             fontWeight: "300",
  //                             lineHeight: "26px",
  //                           }}
  //                         >
  //                           <span style={{ fontWeight: "500" }}>Oops,</span> It
  //                           doesn't seem like you have requested for any experts
  //                           as yet. Please visit the submitted tab to choose your
  //                           preferred expert.{" "}
  //                         </h3>
  //                       </div>
  //                     </div>
  //                   </div>
  //                 </div>
  //               ) : (
  //                 ""
  //               )}
  //             </>
  //           )}

  //           <div
  //             className="offcanvas offcanvas-end comon-tabs-expertcalls"
  //             tabIndex="-1"
  //             id="requested"
  //             aria-labelledby="offcanvasRightLabel"
  //           >
  //             {loading1 ? (
  //               <>
  //                 <div
  //                   className="offcanvas-header align-items-center justify-content-between"
  //                   style={{
  //                     borderBottom: "1px solid rgb(197, 195, 195)",
  //                   }}
  //                 >
  //                   <button
  //                     type="button"
  //                     className="btn-close "
  //                     data-bs-dismiss="offcanvas"
  //                     aria-label="Close"
  //                   ></button>
  //                   <h5 id="offcanvasRightLabel">Expert Profile</h5>
  //                 </div>
  //                 <div className="offcanvas-body">
  //                   <div>
  //                     <h2
  //                       style={{
  //                         fontWeight: "500",
  //                         fontSize: "15px",
  //                         color: "#000",
  //                       }}
  //                     >
  //                       {expert_popup.current_designation}
  //                     </h2>
  //                     <div
  //                       className="nameAndCompany_M"
  //                       style={{
  //                         fontWeight: "400",
  //                         fontSize: "14px",
  //                         color: "#444",
  //                         marginTop: "-8px",
  //                       }}
  //                     >
  //                       <strong
  //                         id="nameAndCompany_M"
  //                         style={{
  //                           fontWeight: "600",
  //                           fontSize: "15px",
  //                           color: "#444",
  //                         }}
  //                       >
  //                         {expert_popup.current_compny}
  //                       </strong>
  //                       <span> {expert_popup.current_location}</span>
  //                     </div>
  //                   </div>
  //                   {/* <div className="d-flex mt-2 gap-4">
  //                 {expertPopupStatus(expert_popup.status)}
  //               </div> */}
  //                   <div className="mt-2">
  //                     <div className="expertPanel_3Zo3_">
  //                       <div className="section_3ByhA">
  //                         <div className="detailsRows_2038l">
  //                           <span className="detailsRowLeft_3h_zH">Region</span>
  //                           <span className="detailsRowRight_3h_zH">
  //                             {expert_popup.current_location}
  //                           </span>
  //                         </div>
  //                         <div className="detailsRows_2038l">
  //                           <span className="detailsRowLeft_3h_zH">
  //                             Consulting Rate
  //                           </span>
  //                           <span className="detailsRowRight_3h_zH">
  //                             $ {expert_popup.hourly_consulting_rate}/hr
  //                           </span>
  //                         </div>
  //                       </div>
  //                     </div>
  //                   </div>
  //                   <div className="overview mt-2">
  //                     <h2
  //                       style={{
  //                         fontWeight: "500",
  //                         color: "#000",
  //                         fontSize: "14px",
  //                       }}
  //                     >
  //                       Overview
  //                     </h2>
  //                     <Markup
  //                       className="markup-para"
  //                       content={expert_popup.overview}
  //                     />
  //                   </div>
  //                   <div className="experien-list mt-4">
  //                     <h6
  //                       style={{
  //                         fontWeight: "500",
  //                         color: "#000",
  //                         fontSize: "14px",
  //                       }}
  //                     >
  //                       Experience
  //                     </h6>
  //                     <div>
  //                       <ul className="nextyn_list_items">
  //                         <li className="nextyn_list_item">
  //                           <div className="expertCompany_2UyI2">
  //                             <span className="title_1p_LH">
  //                               {expert_popup.current_designation}
  //                             </span>
  //                             <div className="companyName_1dSwA">
  //                               {" "}
  //                               {expert_popup.current_compny}
  //                             </div>
  //                           </div>
  //                           <span className="careerDates_3sJ2b">
  //                             {" "}
  //                             {expert_popup.current_date}
  //                           </span>
  //                         </li>

  //                         {getExperienceList(expert_popup).map((curElem, i) => {
  //                           return (
  //                             <>
  //                               <li className="nextyn_list_item" key={i}>
  //                                 <div className="expertCompany_2UyI2">
  //                                   <span className="title_1p_LH">
  //                                     {" "}
  //                                     {curElem.previous_designation}
  //                                   </span>
  //                                   <div className="companyName_1dSwA">
  //                                     {curElem.previous_compny}
  //                                   </div>
  //                                 </div>
  //                                 <span className="careerDates_3sJ2b">
  //                                   {curElem.previous_date}
  //                                 </span>
  //                               </li>
  //                             </>
  //                           );
  //                         })}
  //                       </ul>
  //                     </div>
  //                   </div>
  //                 </div>
  //                 <div className="offcanvas-header">
  //                   <h6 style={{ color: "#39c4a3" }} id="offcanvasRightLabel">
  //                     Requested
  //                   </h6>
  //                 </div>
  //               </>
  //             ) : (
  //               ""
  //             )}
  //           </div>
  //         </>
  //       )
  //     }
  //   }
  // }

  const getExperienceList = (data) => {
    if (
      data.previous_designation.length <= 0 ||
      data.previous_compny.length <= 0 ||
      data.previous_date.length <= 0
    ) {
      return null;
    } else {
      let experienceList = [];
      for (let i = 0; i < data.previous_designation.length; i++) {
        let obj = {};
        obj.previous_designation = data.previous_designation[i];
        obj.previous_compny = data.previous_compny[i];
        obj.previous_date = data.previous_date[i];
        experienceList.push(obj);
      }
      return experienceList;
    }
  };
  // console.log("RequestedExperts", RequestedExperts);
  if (!loading) {
    return (
      <div className="d-flex justify-content-center">
        <div role="status">
          <Spinner animation="border" />
        </div>
      </div>
    );
  } else {
    return (
      // {/* {getCallHistory()} */}

      // {/* <Modal
      //   show={modalToggle}
      //   onHide={handleClose1}
      //   dialogClassName="my-modal"
      //   aria-labelledby="contained-modal-title-vcenter"
      //   centered
      // >
      //   <Modal.Header
      //     style={{ marginBottom: "20px", border: "none", marginTop: "-8px" }}
      //     closeButton
      //   >
      //     <Modal.Title style={{ textAlign: "center" }}>
      //       <h4>{first_charc_uppercase(status)} a call with the expert</h4>
      //     </Modal.Title>
      //   </Modal.Header>
      //   <Modal.Body>
      //     <div style={{ width: "100%", textAlign: "left", fontSize: "18px" }}>
      //       {statusSetter(status)}
      //     </div>
      //   </Modal.Body>
      //   <Modal.Footer
      //     className="d-flex justify-content-end"
      //     style={{ border: "none" }}
      //   >
      //     {status === "Cancel" ? (
      //       <>
      //         <Button
      //           variant="primary"
      //           style={{ backgroundColor: "#5746ec", border: "none" }}
      //           data-bs-dismiss="modal"
      //         // onClick={() => {
      //         //   handleSubmit(status);
      //         // }}
      //         >
      //           {statusButton(status)}
      //         </Button>
      //       </>
      //     ) : (
      //       <>
      //         <Button className="btn btn-secondary"
      //         // onClick={handleClose}
      //         >

      //           Cancel
      //         </Button>
      //         <Button
      //           variant="primary"
      //           style={{ backgroundColor: "#5746ec", border: "none" }}
      //           data-bs-dismiss="modal"
      //           onClick={() => {
      //             // handleSubmit(status);
      //           }}
      //         >
      //           {statusButton(status)}
      //         </Button>
      //       </>
      //     )}
      //   </Modal.Footer>
      // </Modal> */}

      <>
        {RequestedExperts?.length > 0 ? (
          <>
            <div>
              <table className="table mt-4">
                <thead>
                  <tr>
                    <th title="Gender" style={{ width: "40%" }}>
                      Most Relevant Title
                    </th>
                    <th title="Age">Company</th>
                    <th title="Age" style={{ width: "140px" }}>
                      RATE/HR{" "}
                    </th>
                    <th title="Age" style={{ width: "140px" }}>
                      DATE ADDED
                    </th>
                    {/* <th style={{ width: "90px" }}>View</th> */}
                  </tr>
                </thead>
                <tbody>
                  {RequestedExperts?.map((curElem, index) => {
                    return (
                      <tr key={index}>
                        <td style={{ color: "#526fd7", fontWeight: "500" }}>
                          {curElem?.admin_invite_experts
                            ?.admin_expert_experience?.current_designation
                            ? curElem?.admin_invite_experts
                                ?.admin_expert_experience?.current_designation
                            : curElem?.admin_invite_experts
                                ?.admin_expert_experience?.previous_designation}
                        </td>
                        <td>
                          {curElem?.admin_invite_experts
                            ?.admin_expert_experience?.current_compny
                            ? curElem?.admin_invite_experts
                                ?.admin_expert_experience?.current_compny
                            : curElem?.admin_invite_experts
                                ?.admin_expert_experience?.previous_compny}
                        </td>
                        {/* <td>{`$${rate[1]}`}</td> */}
                        <td style={{ color: "#526fd7", fontWeight: "500" }}>
                          {
                            curElem?.admin_invite_experts
                              ?.admin_forward_experts_detail?.expertCurrency
                          }{" "}
                          {
                            curElem?.admin_invite_experts
                              ?.admin_forward_experts_detail
                              ?.hourly_consulting_rate
                          }
                        </td>
                        <td style={{ width: "150px" }}>
                          {convertDate(
                            curElem?.admin_invite_experts?.created_at
                              ? curElem?.admin_invite_experts?.created_at
                              : curElem?.admin_invite_experts
                                  ?.admin_expert_experience?.created_at
                          )}
                        </td>
                        {/* <td>
                            <a
                              type="button"
                              style={{ fontWeight: "600", color: "#5746EC" }}
                              data-bs-toggle="offcanvas"
                              data-bs-target="#requested"
                              aria-controls="offcanvasRight"
                              key={index}
                            >
                              View
                            </a>
                          </td> */}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </>
        ) : (
          <>
            {loading ? (
              <div className="text-center noResultFound">
                <div className="container">
                  <div
                    className="row d-flex justify-content-center align-items-center"
                    style={{ height: "calc(100vh - 200px)" }}
                  >
                    <div className="col-md-6">
                      <img
                        src={no_result}
                        alt="srv"
                        style={{ marginTop: "10px", height: "450px" }}
                      ></img>
                    </div>
                    <div className="col-md-6">
                      <h3
                        style={{
                          fontSize: "18px",
                          textAlign: "left",
                          fontWeight: "300",
                          lineHeight: "26px",
                        }}
                      >
                        <span style={{ fontWeight: "500" }}>Oops,</span> It
                        doesn't seem like you have requested for any experts as
                        yet.{" "}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </>
        )}
        {/* {getCallHistory()} */}

        <div
          className="offcanvas offcanvas-end comon-tabs-expertcalls"
          tabIndex="-1"
          id="requested"
          aria-labelledby="offcanvasRightLabel"
        >
          {loading1 ? (
            <>
              <div
                className="offcanvas-header align-items-center justify-content-between"
                style={{
                  borderBottom: "1px solid rgb(197, 195, 195)",
                }}
              >
                <button
                  type="button"
                  className="btn-close "
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                ></button>
                <h5 id="offcanvasRightLabel">Expert Profile</h5>
              </div>
              <div className="offcanvas-body">
                <div>
                  <h2
                    style={{
                      fontWeight: "500",
                      fontSize: "15px",
                      color: "#000",
                    }}
                  >
                    {expert_popup.current_designation}
                  </h2>
                  <div
                    className="nameAndCompany_M"
                    style={{
                      fontWeight: "400",
                      fontSize: "14px",
                      color: "#444",
                      marginTop: "-8px",
                    }}
                  >
                    <strong
                      id="nameAndCompany_M"
                      style={{
                        fontWeight: "600",
                        fontSize: "15px",
                        color: "#444",
                      }}
                    >
                      {expert_popup.current_compny}
                    </strong>
                    <span> {expert_popup.current_location}</span>
                  </div>
                </div>
                {/* <div className="d-flex mt-2 gap-4">
                  {expertPopupStatus(expert_popup.status)}
                </div> */}
                <div className="mt-2">
                  <div className="expertPanel_3Zo3_">
                    <div className="section_3ByhA">
                      <div className="detailsRows_2038l">
                        <span className="detailsRowLeft_3h_zH">Region</span>
                        <span className="detailsRowRight_3h_zH">
                          {expert_popup.current_location}
                        </span>
                      </div>
                      <div className="detailsRows_2038l">
                        <span className="detailsRowLeft_3h_zH">
                          Consulting Rate
                        </span>
                        <span className="detailsRowRight_3h_zH">
                          $ {expert_popup.hourly_consulting_rate}/hr
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="overview mt-2">
                  <h2
                    style={{
                      fontWeight: "500",
                      color: "#000",
                      fontSize: "14px",
                    }}
                  >
                    Overview
                  </h2>
                  <Markup
                    className="markup-para"
                    content={expert_popup.overview}
                  />
                </div>
                <div className="experien-list mt-4">
                  <h6
                    style={{
                      fontWeight: "500",
                      color: "#000",
                      fontSize: "14px",
                    }}
                  >
                    Experience
                  </h6>
                  <div>
                    <ul className="nextyn_list_items">
                      <li className="nextyn_list_item">
                        <div className="expertCompany_2UyI2">
                          <span className="title_1p_LH">
                            {expert_popup.current_designation}
                          </span>
                          <div className="companyName_1dSwA">
                            {" "}
                            {expert_popup.current_compny}
                          </div>
                        </div>
                        <span className="careerDates_3sJ2b">
                          {" "}
                          {expert_popup.current_date}
                        </span>
                      </li>

                      {getExperienceList(expert_popup).map((curElem, i) => {
                        return (
                          <>
                            <li className="nextyn_list_item" key={i}>
                              <div className="expertCompany_2UyI2">
                                <span className="title_1p_LH">
                                  {" "}
                                  {curElem.previous_designation}
                                </span>
                                <div className="companyName_1dSwA">
                                  {curElem.previous_compny}
                                </div>
                              </div>
                              <span className="careerDates_3sJ2b">
                                {curElem.previous_date}
                              </span>
                            </li>
                          </>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="offcanvas-header">
                <h6 style={{ color: "#39c4a3" }} id="offcanvasRightLabel">
                  Requested
                </h6>
              </div>
            </>
          ) : (
            ""
          )}
        </div>

        <Modal
          show={modalToggle1}
          onHide={handleClose2}
          dialogClassName="my-modal"
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body>
            <div
              style={{ width: "100%", textAlign: "center", marginTop: "20px" }}
            >
              <img
                src="images/right-s.svg"
                alt="svg"
                style={{ marginBottom: "20px" }}
              />
              <h3> {status} Successfully </h3>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
export default RequestTableCell;
