import { Upload } from "antd";
import axios from "axios";
import React, { useState } from "react";
import { Spinner } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../App.css";
import robot from "../assests/images/Artificial intelligence-pana.svg";
import Sidebar from "../Sidebar";

const { Dragger } = Upload;

function ChatGpt() {
  let UserData = JSON.parse(localStorage.getItem("userData"));

  const openaiApiKey = "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";

  const [input, setInput] = useState("");
  const [response, setResponse] = useState("");
  const [showParagraph, setShowParagraph] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    console.log("handlesubmit clicked");
    setShowParagraph(!showParagraph);

    try {
      const result = await axios.post(
        "https://api.openai.com/v1/engines/text-davinci-003/completions",
        {
          prompt: input,
          temperature: 0.5,
          max_tokens: 1024, // Increase max tokens to get more text
          n: 1,
        },
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${openaiApiKey}`,
          },
        }
      );

      if (result) {
        if (result.status === 200) {
          console.log(result, "result");
          setIsLoading(false);
          setResponse(result.data.choices[0].text);
        }
      }
    } catch (error) {
      console.log(error);
    }

    // setInput("");
  };

  console.log(response, "response");

  document.title = "Expert Cloud -  ChatGpt";
  return (
    <>
      <div className="page-wrapper chiller-theme toggled">
        <div className="main-sec d-lg-flex">
          <Sidebar />
        </div>
        <main className="body-total content-wrapper float-start w-100">
          <div className="container-fluid">
            {/* <div className="d-block d-lg-block">
              <div className="top-bar-menu float-start" id="header">
                <TopMenu />
              </div>
            </div> */}

            <div className="w-100">
              <div className="body-expart-div messageScreen request-page projects-scope">
                <span className="sp-hr w-100" />

                <div
                  className="projects-details-page details-message-part"
                  style={{ marginBottom: "20px" }}
                >
                  <div
                    className="d-flex align-items-center"
                    style={{ justifyContent: "space-between" }}
                  >
                    <h3 className="d-flex align-items-center ms-3">Chat Gpt</h3>
                  </div>

                  <div className="message-text-area12 mt-4">
                    <>
                      <ul className="mainComment2" id="messagesEndRef">
                        <div className="elastic-header2">
                          {/* <figure className="me-2">
                            <Avatar
                              style={{
                                backgroundColor: "#EFEFEF",
                                color: "#f33636",
                              }}
                              sx={{
                                bgcolor: blue[500],
                                height: "42px",
                                width: "42px",
                                fontSize: "30px",
                                margin: "auto",
                              }}
                            >
                              {UserData.user.firstNmae === undefined
                                ? UserData.user.firstNmae[0]?.toUpperCase()
                                : UserData.user.firstNmae[0]?.toUpperCase()}
                            </Avatar>
                          </figure> */}
                          {/* {chatHistory.map((chat, index) => (
                            <div key={index}> */}
                          {/* {showParagraph && <p>{input}</p>} */}
                          <form
                            onSubmit={handleSubmit}
                            action="POST"
                            className="bg-white"
                          >
                            <div className="input-group">
                              <input
                                type="text"
                                value={input}
                                onChange={(e) => setInput(e.target.value)}
                                placeholder="Type a message"
                                aria-describedby="button-addon2"
                                className="form-control rounded-0 border-0 py-4 bg-white"
                              />
                              <div
                                className="input-group-append"
                                style={{ height: "70px" }}
                              >
                                <button
                                  id="button-addon2"
                                  type="submit"
                                  className="btn btn-link"
                                >
                                  {" "}
                                  <i className="fa fa-paper-plane"></i>
                                </button>
                              </div>
                            </div>
                          </form>
                          {/* </div>
                          ))} */}
                        </div>

                        <div>
                          <li className="adminMessage clearfix p-3">
                            <div>
                              <div>
                                <div className="position-relative mt-2">
                                  {/* <div
                                    className="speakerName"
                                    style={{
                                      backgroundColor: "#EFEFEF",
                                    }}
                                  >
                                    efefefefefefefefefefe
                                  </div> */}
                                  <div>
                                    {/* {chatHistory.map((chat, index) => (
                                      <div key={index}> */}
                                    {isLoading ? (
                                      <div style={{ textAlign: "center" }}>
                                        <Spinner animation="border" />
                                      </div>
                                    ) : (
                                      <>
                                        {response.length === 0 ? (
                                          <>
                                            <>
                                              <div className="text-center noResultFound">
                                                <div className="container">
                                                  <div className="row d-flex justify-content-center align-items-center">
                                                    <div className="col-md-12">
                                                      <img
                                                        src={robot}
                                                        alt="sv"
                                                        style={{
                                                          height: "300px",
                                                          // marginTop: "50px",
                                                        }}
                                                      ></img>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </>
                                          </>
                                        ) : (
                                          <>
                                            <p
                                              style={{
                                                fontSize: "16px",
                                                color: "#05294b",
                                              }}
                                            >
                                              {response}
                                            </p>
                                          </>
                                        )}
                                      </>
                                    )}

                                    {/* </div>
                                    ))} */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                        </div>
                      </ul>
                    </>

                    <div />
                    {/* <div className="messageEditorWrapper2">
                      <div className="form-group">
                        <div className="main3">
                          <form
                            onSubmit={handleSubmit}
                            action="POST"
                            className="bg-white"
                          >
                            <div className="input-group">
                              <input
                                type="text"
                                value={input}
                                onChange={(e) => setInput(e.target.value)}
                                placeholder="Type a message"
                                aria-describedby="button-addon2"
                                className="form-control rounded-0 border-0 py-4 bg-white"
                              />
                              <div
                                className="input-group-append"
                                style={{ height: "70px" }}
                              >
                                <button
                                  id="button-addon2"
                                  type="submit"
                                  className="btn btn-link"
                                >
                                  {" "}
                                  <i className="fa fa-paper-plane"></i>
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
      <ToastContainer />
    </>
  );
}

export default ChatGpt;
