import React, { useState, useEffect } from "react";
import Sidebar from "../Sidebar";
import TopMenu2 from "../TopMenu2";
import axios from "axios";
import MobileMenu from "../MobileMenu";
import { App as SendbirdApp } from "sendbird-uikit";
import "sendbird-uikit/dist/index.css";
import "./style.css";
import API_PATH from "../Constants/api-path";
import $ from "jquery";
import { EditUserProfileProvider } from "@sendbird/uikit-react/EditUserProfile/context";

const THEME = "light";

function Conversations() {
  document.title = "Expert Cloud -  Conversations";
  const loginDetails = JSON.parse(localStorage.getItem("userData"));

  let listener = document.querySelector(".sendbird-channel-header__title");
  // useEffect(() => {
  //   listener.addEventListener("click", () => {
  //     //return <EditUserProfileProvider />;
  //   });
  // }, [listener]);

  //console.log(userDetails);
  const [user_id, setUser_id] = useState(loginDetails.user.username);
  const [nickname, setNickname] = useState(loginDetails.user.username);
  const [userData, setUserData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [createUser, setCreateUser] = useState({
    user_id: user_id,
    nickname: nickname,
    profile_url: "",
  });
  const headers = {
    "Api-Token": API_PATH.Api_Token,
  };

  useEffect(() => {
    axios
      .get(`https://api-${API_PATH.APP_ID}.sendbird.com/v3/users`, {
        headers,
      })
      .then((res) => {
        //console.log(res.data.users);
        setUserData(res.data.users);
        setLoading(true);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "hidden";
    };
  }, []);

  // console.log(userData);
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  // console.log(userDetails);

  const check_user = userData.filter((user) => {
    if (user.user_id === user_id) {
      localStorage.setItem(
        "userDetails",
        JSON.stringify({
          user_id: user.user_id,
          nickname: user.nickname,
        })
      );

      return user.user_id === user_id;
    }
  });
  // console.log(check_user);

  if (loading) {
    if (check_user.length > 0) {
      setUser_id(check_user[0].user_id);
      setNickname(check_user[0].nickname);
    } else {
      axios
        .post(
          `https://api-${API_PATH.APP_ID}.sendbird.com/v3/users`,
          createUser,
          {
            headers,
          }
        )
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    setLoading(false);
  }

  $(window).scroll(function () {
    var scroll = $(window).scrollTop();
    if (scroll > 0) {
      $("#header").addClass("active");
    } else {
      $("#header").removeClass("active");
    }
  });

  //console.log(user_id, nickname);

  return (
    <>
      <MobileMenu />
      <div className="page-wrapper chiller-theme toggled">
        <div className="main-sec d-lg-flex">
          <Sidebar />
        </div>
        <main className="body-total content-wrapper float-start w-100">
          {/* <div className="container-fluid">
            <div className="d-block d-lg-block">
              <div className="top-bar-menu float-start" id="header">
                <TopMenu2 />
              </div>
            </div>
          </div> */}
          <div div className="dasb-div bk-div float-start w-100">
            <div className="customized-app">
              <>
                <h2 style={{ marginBottom: "30px" }}>Conversations</h2>
                <SendbirdApp
                  appId={API_PATH.APP_ID}
                  userId={user_id}
                  nickname={nickname}
                  theme={THEME}
                  useReaction={true}
                  useMessageGrouping={true}
                  allowProfileEdit={true}
                  setUseTypingIndicator={true}
                  icon_done_all={true}
                  stringSeting={true}
                />
              </>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}

export default Conversations;
