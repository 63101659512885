import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../Register/Register.css";
import { categoryArray } from "./categories";
import { categoryArrayFour } from "./categoryFour";
import { categoryList } from "./CategoryList";
import { categoryArrayThree } from "./categoryThree";
import { categoryArrayTwo } from "./categoryTwo";

const PersonalizationTwo = () => {
  const history = useNavigate();
  const [selected, setChecked] = useState([]);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [list, setList] = useState([]);

  const handleChange = (e) => {
    if (selected.includes(e.target.value)) {
      setChecked(selected.filter((item) => item !== e.target.value));
    } else {
      setChecked([...selected, e.target.value]);
      localStorage.setItem(
        "selected",
        JSON.stringify([...selected, e.target.value])
      );
    }
  };

  function addHyphen(str) {
    return str.replace(/[^A-Z0-9]/gi, "_");
  }

  useEffect(() => {
    setList(categoryList);
  }, [list]);

  const setCategoryArray = () => {
    return categoryArray.map((category) => {
      console.log(category, "category1");
      return (
        <>
          {category.key === "selectAll" ? (
            <>
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input categoryInput"
                  name="expertView"
                  for="select-all"
                  value={category.key}
                  id={addHyphen(category.key)}
                  checked={isCheckAll}
                  onChange={handleSelectAll}
                  hidden
                />
                <label
                  className="form-check-label ltsStrd selectAll"
                  for={addHyphen(category.key)}
                >
                  {category.label}
                </label>
              </div>
            </>
          ) : (
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input categoryInput"
                name="expertView"
                for="select-all"
                value={category.key}
                id={addHyphen(category.key)}
                onChange={handleChange}
                checked={selected.includes(category.key)}
                hidden
              />
              <label
                className="form-check-label ltsStrd"
                for={addHyphen(category.key)}
              >
                {category.label}
              </label>
            </div>
          )}
        </>
      );
    });
  };

  const setCategoryArrayTwo = () => {
    return categoryArrayTwo.map((category, index) => {
      return (
        <>
          {category.key === "selectAll" ? (
            <>
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input categoryInput"
                  name="expertView"
                  for="select-all"
                  value={category.key}
                  id={addHyphen(category.key)}
                  checked={isCheckAll}
                  onChange={handleSelectAll}
                  hidden
                />
                <label
                  className="form-check-label ltsStrd selectAll"
                  for={addHyphen(category.key)}
                >
                  {category.label}
                </label>
              </div>
            </>
          ) : (
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input categoryInput"
                name="expertView"
                for="select-all"
                value={category.key}
                id={addHyphen(category.key)}
                onChange={handleChange}
                checked={selected.includes(category.key)}
                hidden
              />
              <label
                className="form-check-label ltsStrd"
                for={addHyphen(category.key)}
              >
                {category.label}
              </label>
            </div>
          )}
        </>
      );
    });
  };

  const setCategoryArrayThree = () => {
    return categoryArrayThree.map((category, index) => {
      return (
        <>
          {category.key === "selectAll" ? (
            <>
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input categoryInput"
                  name="expertView"
                  for="select-all"
                  value={category.key}
                  id={addHyphen(category.key)}
                  checked={isCheckAll}
                  onChange={handleSelectAll}
                  hidden
                />
                <label
                  className="form-check-label ltsStrd selectAll"
                  for={addHyphen(category.key)}
                >
                  {category.label}
                </label>
              </div>
            </>
          ) : (
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input categoryInput"
                name="expertView"
                for="select-all"
                value={category.key}
                id={addHyphen(category.key)}
                onChange={handleChange}
                checked={selected.includes(category.key)}
                hidden
              />
              <label
                className="form-check-label ltsStrd"
                for={addHyphen(category.key)}
              >
                {category.label}
              </label>
            </div>
          )}
        </>
      );
    });
  };

  const handleSelectAll = (e) => {
    setIsCheckAll(!isCheckAll);
    setChecked(list.map((li) => li.key));
    if (isCheckAll) {
      setChecked([]);
    }
  };

  const setCategoryArrayFour = () => {
    return categoryArrayFour.map((category, index) => {
      return (
        <>
          {category.key === "selectAll" ? (
            <>
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input categoryInput"
                  name="expertView"
                  for="select-all"
                  value={category.key}
                  id={addHyphen(category.key)}
                  checked={isCheckAll}
                  onChange={handleSelectAll}
                  hidden
                />
                <label
                  className="form-check-label ltsStrd selectAll"
                  for={addHyphen(category.key)}
                >
                  {category.label}
                </label>
              </div>
            </>
          ) : (
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input categoryInput"
                name="expertView"
                for="select-all"
                value={category.key}
                id={addHyphen(category.key)}
                onChange={handleChange}
                checked={selected.includes(category.key)}
                hidden
              />
              <label
                className="form-check-label ltsStrd"
                for={addHyphen(category.key)}
              >
                {category.label}
              </label>
            </div>
          )}
        </>
      );
    });
  };

  return (
    <>
      <section className="contactUs">
        <div className="text-center">
          {/* <h4 className="mb-3" style={{ color: "#5e5873" }}>
                      Welcome to NextynTrends!
                    </h4> */}

          <form>
            <div className="row">
              <div className="col-md-12">
                <div className="form-outline mb-3">
                  {/* <h6 className="form-label form-label work-label mb-4">
                    Select categories that interest you:
                  </h6> */}
                  <div className="row">
                    <div className="col">{setCategoryArray()}</div>
                    <div className="col">{setCategoryArrayTwo()}</div>
                    <div className="col">{setCategoryArrayThree()}</div>
                    <div className="col">{setCategoryArrayFour()}</div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </section>
    </>
  );
};

export default PersonalizationTwo;
