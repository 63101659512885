import axios from "axios";
import $ from "jquery";
import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import Pagination1 from "react-js-pagination";
import "../App.css";
import API_PATH from "../Constants/api-path";
import "../Dashboard/Dashboard.css";
import TopIndustry from "./TopIndustry";

function FindExpert() {
  const [loading, setLoading] = useState("");
  const [experts, setExperts] = useState([]);
  const userData = JSON.parse(localStorage.getItem("userData"));
  const [currentPage, setCurrentPage] = useState(1);
  const [allDatas, setAlldatas] = useState(0);
  const [company, setCompany] = useState("");
  const [previousCompany, setpCompany] = useState("");
  const [industry, setIndustry] = useState("");
  const [tags, setExpertise] = useState("");
  const [country, setCountry] = useState("");
  const [searchRgx, setSearchRgx] = useState("");

  const headers = {
    "x-access-token": userData.access_token,
  };

  useEffect(() => {
    document.body.style.overflow = "";
    return () => {
      document.body.style.overflow = "";
    };
  }, []);

  const handlePageChange = (pageNumber) => {
    //console.log(`active page is ${pageNumber}`);
    setCurrentPage(pageNumber);
  };

  const changeStatus = () => {
    //console.log("here");
    return setLoading(true);
  };

  const changeQueryStatus = () => {
    //console.log("here");
    return setLoading(false);
  };

  $(window).scroll(function () {
    var scroll = $(window).scrollTop();
    if (scroll > 0) {
      $("#header").addClass("active");
    } else {
      $("#header").removeClass("active");
    }
  });

  const getExperts = () => {
    setLoading(true);
    axios
      .get(`${API_PATH.EXPERT_LIST}/${currentPage}/${userData.user._id}`, {
        headers,
        params: {
          country,
          company,
          pcompany:previousCompany,
          tags,
          industry,
          searchRgx,
          category: userData.user
            ? userData.user.selectedCategory?.join(",")
            : "",
          share: true,
        },
      })
      .then((res) => {
        setLoading(false);
        setExperts([...res.data.data]);
        setAlldatas(res.data.count);
      })
      .catch((error) => {
        setLoading(false);
        // console.log(error);
      });
  };

  // console.log(allDatas);

  useEffect(() => {
    getExperts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, country, company,previousCompany, tags, industry, searchRgx]);

  // console.log(loading);

  // console.log(experts);

  let isAuth = localStorage.getItem("isAuth");
  //console.log(isAuth);
  document.title = "Expert Cloud -  Find Experts";
  if (!isAuth) {
    return (window.location.href = "/");
  }
  return (
    <>
      {/* <hr className="sp-hr" /> */}
      <div className="body-expart-div">
        <h2>Experts </h2>

        <div className="tab-content maxWidth" id="pills-tabContent">
          <div
            className="tab-pane pt-2 fade show active"
            id="pills-home"
            role="tabpanel"
            aria-labelledby="pills-home-tab"
          >
            {loading ? (
              <>
                <div className="d-flex justify-content-center">
                  <div role="status">
                    <Spinner animation="border" />
                  </div>
                </div>
                {/* {console.log("props is empty")} */}.
              </>
            ) : (
              <>
                {!loading ? (
                  <>
                    {!loading ? (
                      <>
                        <TopIndustry props={experts} />
                        {experts.length >= 10 ? (
                          <>
                            <div className="pagination-background">
                              <Pagination1
                                activePage={currentPage}
                                itemsCountPerPage={10}
                                totalItemsCount={allDatas}
                                pageRangeDisplayed={10}
                                onChange={handlePageChange}
                                itemclassName="page-item"
                                linkclassName="page-link"
                                showLast
                              />
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </>
            )}

            {/* <TopIndustry /> */}
            {/* <TopIndustry props={experts} /> */}
          </div>
          <div
            className="tab-pane fade"
            id="pills-profile"
            role="tabpanel"
            aria-labelledby="pills-profile-tab"
          >
            ...
          </div>
          <div
            className="tab-pane fade"
            id="pills-contact"
            role="tabpanel"
            aria-labelledby="pills-contact-tab"
          >
            ...
          </div>
        </div>
      </div>
    </>
  );
}

export default FindExpert;
