import Button from "@mui/material/Button";
import React, { useEffect, useState } from "react";
import {
  Dropdown,
  FormControl,
  InputGroup,
  OverlayTrigger,
  Popover
} from "react-bootstrap";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Spinner } from "reactstrap";
import userColored from "../assests/images/userColored.svg";
import API_PATH from "../Constants/api-path";
import MessageEditor from "../messageEditor";
import SharedEditNotes from "../share/SharedEditNotes";
import Sidebar from "../Sidebar";
import { CopyToClipboard } from "../Utils/CopyToClipboard";
import { BsChevronLeft, MoreHorizontal } from "../Utils/Icons";
import CreateNotesForm from "./CreateNotesForm";

const config = {
  readonly: false,
  height: "200px",
  width: "100%",
  enableDragAndDropFileToEditor: true,
  buttons: [
    "source",
    "|",
    "bold",
    "italic",
    "underline",
    "|",
    "ul",
    "ol",
    "|",
    "font",
    "fontsize",
    "brush",
    "paragraph",
    "|",
    "image",
    "table",
    "link",
    "|",
    "left",
    "center",
    "right",
    "justify",
    "|",
    "undo",
    "redo",
    "|",
    "hr",
    "eraser",
    "fullsize",
  ],
  uploader: { insertImageAsBase64URI: false },
  removeButtons: [
    "brush",
    "file",
    "hr",
    "font",
    "fontsize",
    "brush",
    "image",
    "undo",
    "redo",
    "link",
    "fullsize",
    "table",
    "undo",
    "redo",
    "back",
    "paint",
    "eraser",
    "paragraph",
    "source",
    "|",
    "justify",
  ],
  showXPathInStatusbar: false,
  showCharsCounter: false,
  showWordsCounter: true,
  toolbarAdaptive: false,
  toolbarSticky: false,
  toolbarButtonSize: "middle",
};

const CreateNotes = () => {
  let history = useNavigate();
  const userData = JSON.parse(localStorage.getItem("userData"));

  const headers = {
    "x-access-token": userData.access_token,
  };
  const [noteDetailsData, setNoteDetailsData] = useState({});
  const [userPermission, setUserPermission] = useState("");
  const [userEmail, setEmail] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [spinner, setSpinner] = useState(false);
  const [folderData, setFolderData] = useState("");

  const [payload, setPayload] = useState({
    email: "",
    permission: "editi",
  });

  const [show, setShow] = useState(false);
  const handleChange = (event) => {
    setPayload({ ...payload, [event.target.name]: event.target.value });
    if (event.target.value.length > 0) setShow(true);
    else setShow(false);
  };

  const modalClose = () => {
    setEmail("");
  };

  // console.log(payload);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [noteData, setNoteData] = useState({
    name: "",
    description: "",
    userId: "",
    companyId: "",
    roleId: "",
  });

  const handleRequestInputs = (e) => {
    const key = e.target.name;
    const value = e.target.value;

    setEmail(value);
  };

  const [value, setValue] = useState("");
  const [newCommentValue, setnewCommentValue] = useState("");

  function Calculate_days_hours(date1, date2) {
    var diff = date2.getTime() - date1.getTime();
    var days = Math.floor(diff / (1000 * 60 * 60 * 24));
    var hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    var minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((diff % (1000 * 60)) / 1000);
    ///return { days, hours, minutes, seconds };
    if (days !== 0) {
      return days + " " + "days ago ";
    } else if (hours !== 0) {
      return hours + " " + "hours ago";
    } else if (minutes !== 0) {
      return minutes + " " + "min. ago";
    } else if (seconds < 60) {
      return "Less than a minute";
    }
  }

  function RemoveHtml(myString) {
    if (myString) {
      return myString.replace(/(<([^>]+)>)/gi, "");
    } else {
      return "Empty message";
    }
  }

  const getNotes = async () => {
    if (searchParams.get("noteId")) {
      try {
        const res = await fetch(
          `${API_PATH.NOTE_DETAILS}/${searchParams.get("noteId")}`,
          {
            method: "GET",
            headers: headers,
          }
        );

        const result = await res.json();
        if (result) {
          setNoteDetailsData(result);
          setValue(result.description);
          setNoteData({ ...result });
        } else {
          return toast.error("Something went wrong! Our team is looking into it.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
        }
      } catch (error) {
        return toast.error("Something went wrong! Our team is looking into it.r", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    } else {
      try {
        const res = await fetch(
          `${API_PATH.CONTENT_DETAILS}/${searchParams.get("contentId")}`,
          {
            method: "GET",
            headers: headers,
          }
        );

        const result = await res.json();
        if (result) {
          setNoteDetailsData(result);
          setValue(result.description);
        } else {
          return toast.error("Something went wrong! Our team is looking into it.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
        }
      } catch (error) {
        return toast.error("Something went wrong! Our team is looking into it.", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    }
  };

  // console.log(noteDetailsData);

  const stopSharing = async (e) => {
    e.preventDefault();
    if (searchParams.get("noteId")) {
      try {
        let payload = {};
        payload.permittedUsers = [];
        const res = await fetch(
          `${API_PATH.NOTE_UPDATE}?NoteID=${searchParams.get("noteId")}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              "x-access-token": userData.access_token,
            },
            body: JSON.stringify(payload),
          }
        );

        const result = await res.json();
        if (result) {
          getNotes();
          return toast.success("User removed successfully!", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
        } else {
          return toast.error("Something went wrong! Our team is looking into it.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
        }
      } catch (error) {
        return toast.error("Something went wrong! Our team is looking into it.", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    } else {
      try {
        let payload = {};
        payload.permittedUsers = [];
        const res = await fetch(
          `${API_PATH.CONTENT_UPDATE}?ContentID=${searchParams.get(
            "contentId"
          )}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              "x-access-token": userData.access_token,
            },
            body: JSON.stringify(payload),
          }
        );

        const result = await res.json();
        if (result) {
          getNotes();
        } else {
          return toast.error("Something went wrong! Our team is looking into it.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
        }
      } catch (error) {
        return toast.error("Something went wrong! Our team is looking into it.", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    }
  };

  const updateNotePermission = async (permission, email) => {
    setPayload({
      email: email,
      permission: permission,
    });
  };

  const makeUpdate = async () => {
    // console.log(payload);

    if (searchParams.get("noteId")) {
      try {
        const res = await fetch(
          `${API_PATH.NOTE_PERMISSION}/${searchParams.get("noteId")}/${userData.user._id
          }`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              "x-access-token": userData.access_token,
            },
            body: JSON.stringify({
              permission: payload.permission,
              email: payload.email,
            }),
          }
        );

        const result = await res.json();
        if (result) {
          setEmail("");
          getNotes();
          return toast.success("User added successfully!", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
        } else {
          return toast.error("Something went wrong! Our team is looking into it.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
        }
      } catch (error) {
        return toast.error("Something went wrong! Our team is looking into it.", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    } else {
      try {
        const res = await fetch(
          `${API_PATH.CONTENT_PERMISSION}/${searchParams.get("contentId")}/${userData.user._id
          }`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              "x-access-token": userData.access_token,
            },
            body: JSON.stringify({
              permission: payload.permission,
              email: payload.email,
            }),
          }
        );

        const result = await res.json();
        if (result) {
          setEmail("");
          getNotes();
        } else {
          return toast.error("Something went wrong! Our team is looking into it.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
        }
      } catch (error) {
        return toast.error("Something went wrong! Our team is looking into it.", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    }
  };

  const updatePermission = async (permission, email) => {
    // console.log(permission, email);
    if (searchParams.get("noteId")) {
      try {
        const res = await fetch(
          `${API_PATH.NOTE_PERMISSION}/${searchParams.get("noteId")}/${userData.user._id
          }`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              "x-access-token": userData.access_token,
            },
            body: JSON.stringify({ permission: permission, email: email }),
          }
        );

        const result = await res.json();
        if (result) {
          getNotes();
        } else {
          return toast.error("Something went wrong! Our team is looking into it.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
        }
      } catch (error) {
        return toast.error("Something went wrong! Our team is looking into it.", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    } else {
      try {
        const res = await fetch(
          `${API_PATH.CONTENT_PERMISSION}/${searchParams.get("contentId")}/${userData.user._id
          }`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              "x-access-token": userData.access_token,
            },
            body: JSON.stringify({
              permission: permission,
              email: email,
            }),
          }
        );

        const result = await res.json();
        if (result) {
          setEmail("");
          getNotes();
        } else {
          return toast.error("Something went wrong! Our team is looking into it.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
        }
      } catch (error) {
        return toast.error("Something went wrong! Our team is looking into it.", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    }
  };

  const deleteNote = async (e) => {
    e.preventDefault();
    if (searchParams.get("noteId")) {
      try {
        const res = await fetch(
          `${API_PATH.NOTE_DELETE}?NoteID=${searchParams.get("noteId")}`,
          {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
              "x-access-token": userData.access_token,
            },
          }
        );

        const result = await res.json();
        if (result) {
          document.getElementById("DeleteModal1").click();
          window.history.go(-1);
          // setTimeout(() => {
          //   window.location.reload();
          // }, 1000);
          return toast.success(result?.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
        } else {
          return toast.error("Something went wrong! Our team is looking into it.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
        }
      } catch (error) {
        return toast.error("Something went wrong! Our team is looking into it.", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    } else {
      //console.log("no note id");
      try {
        const res = await fetch(
          `${API_PATH.CONTENT_DELETE}?ContentID=${searchParams.get(
            "contentId"
          )}`,
          {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
              "x-access-token": userData.access_token,
            },
          }
        );

        const result = await res.json();
        if (result) {
          document.getElementById("DeleteModal1").click();
          window.history.go(-1);
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        } else {
          return toast.error("Something went wrong! Our team is looking into it.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
        }
      } catch (error) {
        return toast.error("Something went wrong! Our team is looking into it.", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    }
  };

  const getDefaultText = (permission) => {
    if (permission === "editi") return "Can edit and invite";
    if (permission === "edit") return "Can edit";
    if (permission === "view") return "Can view";
  };

  // console.log(noteDetailsData);
  const getPUserList = () => {
    if (noteDetailsData.permittedUsers instanceof Array) {
      return noteDetailsData.permittedUsers.map(function (object, i) {
        const { _id, firstNmae, email, permission, username } = object;
        return (
          <>
            <div className="shareWrppr" id={_id}>
              <div className="emailSection">
                <div className="profileIconWrppr">
                  <div className="avatarImg">
                    <img
                      width="45px"
                      src={
                        "https://ui-avatars.com/api/?&rounded=true&font-size=0.38&background=EFEFEF&color=f33636&name=" +
                        email
                      }
                    />
                  </div>

                  {/* <div
                    className="profileIcon"
                    style={{
                      backgroundImage: `url(https://ui-avatars.com/api/?background=0D8ABC&color=fff&name=${email})`,
                    }}
                  ></div> */}
                  <div className="ms-3">
                    <div id="qa-USER_LIST_USER_NAME" className="nameInfo">
                      <span className="">
                        {firstNmae ? firstNmae : username}
                      </span>
                    </div>
                    <div className="emailInfo" id="qa-USER_LIST_EMAIL">
                      <span className="">{email}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="Yourr_9w71MjtL3S9I4G">
                <div className="">
                  <Dropdown>
                    <Dropdown.Toggle
                      className="dropdownBtn"
                      id="dropdown-basic"
                      style={{
                        background: "none",
                        border: "none",
                        color: "#000000",
                      }}
                    >
                      {getDefaultText(permission)}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        href="#/action-1"
                        onClick={() => updatePermission("editi", email)}
                      >
                        Can edit and invite
                      </Dropdown.Item>
                      <Dropdown.Item
                        href="#/action-2"
                        onClick={() => updatePermission("edit", email)}
                      >
                        Can edit
                      </Dropdown.Item>
                      <Dropdown.Item
                        href="#/action-3"
                        onClick={() => updatePermission("view", email)}
                      >
                        Can view
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>
          </>
        );
      });
    }
  };

  const getFolders = async () => {
    try {
      const res = await fetch(
        `${API_PATH.COVER_LIST}/${userData.user._id}/false`,
        {
          method: "GET",
          headers: headers,
        }
      );

      const result = await res.json();
      if (result) {
        // console.log(result);
        setFolderData(result);
      } else {
        return toast.error("Something went wrong! Our team is looking into it.", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    } catch (error) {
      return toast.error("Something went wrong! Our team is looking into it.", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
  };

  const getCoverList = () => {
    if (folderData instanceof Array) {
      return folderData.map(function (object, i) {
        const { _id, firstNmae } = object;
        return (
          <>
            <option value={_id}>{firstNmae}</option>
          </>
        );
      });
    }
  };

  const convertDate = (date) => {
    if (date !== null && date !== "" && date !== undefined) {
      const localDate = new Date(date);
      const localDateString = localDate.toLocaleDateString(undefined, {
        day: "numeric",
        month: "short",
        year: "numeric",
      });

      const localTimeString = localDate.toLocaleTimeString(undefined, {
        hour: "2-digit",
        minute: "2-digit",
      });

      let p = `${localDateString} ${localTimeString}`;
      return p;
    } else {
      return "---------";
    }
  };

  const getComments = (comments) => {
    if (comments instanceof Array) {
      return comments.map(function (object, i) {
        const { commnt, createdAt, userName } = object;
        return (
          <>
            <div className="commentBody">
              <div className="d-flex align-items-center">
                <img
                  draggable="false"
                  alt="ui-avatar"
                  src={
                    "https://ui-avatars.com/api/?size=60&&font-size=.38&color=%23f33636&background=%23EFEFEF&length=2&name=" +
                    userName
                  }
                  size="60"
                  className=" css-ibnyz4 userImage"
                />
                <h6
                  className="mb-0 mt-0"
                  style={{ fontWeight: "600", marginLeft: "15px" }}
                >
                  {userName}
                </h6>
                <p
                  className="mb-0"
                  style={{ color: "grey", marginLeft: "15px" }}
                >
                  {Calculate_days_hours(
                    new Date(createdAt),
                    new Date(Date.now())
                  )}
                </p>
              </div>
              <div className="mt-2 mb-4">
                <p className="mb-0">{RemoveHtml(commnt)}</p>
              </div>
            </div>
          </>
        );
      });
    }
  };

  const addComment = async (e) => {
    e.preventDefault();
    if (!newCommentValue) {
      return toast.warning("All fields are Mandatory", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
    let payload = {};
    payload.comments = [
      ...noteDetailsData.comments,
      {
        userId: userData.user._id,
        commnt: newCommentValue,
        createdAt: Date.now(),
        userName: userData.user.username,
        likeCount: 0,
        disLikeCount: 0,
      },
    ];
    getComments(payload.comments);
    //console.log(payload);
    if (searchParams.get("contentId")) {
      setSpinner(true);
      try {
        const res = await fetch(
          `${API_PATH.CONTENT_UPDATE}?ContentID=${searchParams.get(
            "contentId"
          )}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              "x-access-token": userData.access_token,
            },
            body: JSON.stringify(payload),
          }
        );
        const result = await res.json();
        if (result) {
          // console.log(result);
          setSpinner(false);
          setnewCommentValue("");
        } else {
          return toast.error("Something went wrong! Our team is looking into it.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
        }
      } catch (error) {
        return toast.error("Something went wrong! Our team is looking into it.", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    } else {
      try {
        const res = await fetch(
          `${API_PATH.NOTE_UPDATE}?NoteID=${searchParams.get("noteId")}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              "x-access-token": userData.access_token,
            },
            body: JSON.stringify(payload),
          }
        );
        const result = await res.json();
        if (result) {
          // console.log(result);
          setSpinner(false);
          setnewCommentValue("");
        } else {
          return toast.error("Something went wrong! Our team is looking into it.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
        }
      } catch (error) {
        return toast.error("Something went wrong! Our team is looking into it.", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    }
  };

  useEffect(() => {
    getNotes();
    getFolders();
  }, []);

  return (
    <>
      <div className="page-wrapper chiller-theme toggled">
        <div className="main-sec d-lg-flex">
          <Sidebar />
        </div>
        <main className="body-total content-wrapper float-start w-100">
          <div className="container-fluid">
            {/* <div className="d-block d-lg-block">
              <div className="top-bar-menu float-start" id="header">
                <TopMenu />
              </div>
            </div> */}

            <div className="bk-div float-start w-100">
              <div className="body-expart-div projects-pages support-page">
                <div className="d-flex top-ps-seection justify-content-between align-items-center mb-4 mt-3">
                  <div className="">
                    <h3 className="d-flex align-items-center">
                      <Link to={"/call_recordings"}>
                        <BsChevronLeft className="me-3 search-btn-back" />
                      </Link>
                      Note
                    </h3>
                  </div>
                  {/* <p
                      style={{
                        marginLeft: "10px",
                        paddingTop: "20px",
                        fontSize: "15px",
                        lineHeight: "15px",
                      }}
                    >
                      {convertDate(noteDetailsData.createdAt)}
                    </p> */}
                  <div className="d-flex align-items-center">
                    {/* {userPermission === 'all' ? ( */}
                    {noteDetailsData ? (
                      noteDetailsData.permittedUsers?.length > 0 ? (
                        <OverlayTrigger
                          trigger="hover"
                          key={Math.random()}
                          placement={"top"}
                          overlay={
                            <Popover id={`popover-positioned-${Math.random()}`}>
                              <Popover.Body className="p-2">
                                {/* <h6 className="mb-0" style={{ fontSize: "12px" }}>
                              Public link enabled
                            </h6> */}
                                <div>
                                  <small>
                                    Shared with{" "}
                                    {noteDetailsData.permittedUsers?.length}{" "}
                                    people
                                  </small>
                                </div>
                              </Popover.Body>
                            </Popover>
                          }
                        >
                          {noteDetailsData.permittedUsers?.length > 0 ? (
                            <img
                              style={{
                                position: "relative",
                                // top: "11px",
                                marginRight: "12px",
                              }}
                              src={userColored}
                            />
                          ) : (
                            <></>
                          )}
                        </OverlayTrigger>
                      ) : (
                        <div style={{ color: "#737373", marginRight: "12px" }}>
                          <span style={{ whiteSpace: "nowrap" }}>Only you</span>
                        </div>
                      )
                    ) : (
                      <></>
                    )}
                    {/* <button
                      style={{
                        // backgroundColor: "rgb(0, 168, 45)",
                        color: "#ffffff",
                      }}
                      type="button"
                      to="#"
                      className="one-ex-btn btn me-2  "
                      data-bs-toggle="modal"
                      data-bs-target="#shareModal"
                    >
                      Share
                    </button> */}

                    {userData?.user?.role_id?.collaborate?.notesDelete === true ? (
                      <>
                        <Dropdown>
                          <Dropdown.Toggle
                            className="moreOptions"
                            id="dropdown-basic"
                          >
                            <MoreHorizontal className="moreIcon" />
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item
                              href="#"
                              className="actionNav me-4"
                              data-bs-toggle="modal"
                              data-bs-target="#shareModal"
                            >
                              Share
                            </Dropdown.Item>
                            <Dropdown.Item
                              href="#"
                              className="actionNav me-4"
                              data-bs-toggle="modal"
                              data-bs-target="#DeleteModal1"
                            // onClick={handleClose}
                            >
                              Delete
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </>
                    ) : null}


                    {/* <Menu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                    >
                      <MenuItem
                        className="dropdown-item"
                        data-bs-toggle="modal"
                        data-bs-target="#DeleteModal1"
                        href="#"
                        onClick={handleClose}
                      >
                        <i className="fa fa-trash-o" aria-hidden="true"></i>
                        <span style={{ marginLeft: "10px" }}>Delete</span>
                      </MenuItem>
                    </Menu> */}

                    {/* ) :
                      ()
                    } */}
                  </div>
                </div>

                <span style={{ marginTop: "32px" }} />
                <div className="faq-section">
                  {searchParams.get("noteId") ? (
                    <SharedEditNotes noteid={searchParams.get("noteId")} />
                  ) : (
                    <CreateNotesForm data={noteDetailsData} />
                  )}
                </div>

                {/* Comments */}
                {noteDetailsData?.comments?.length > 0 ? (
                  <>
                    <div className="row">
                      <div className="col-12">
                        <h5 className="commentTitle">
                          <span
                            style={{
                              borderBottom: "2px solid darkgrey",
                              paddingBottom: "8px",
                            }}
                          >
                            Comments{" "}
                            <span
                              style={{
                                fontSize: "12px",
                                background: "#ededf0",
                                padding: "5px 6px",
                                borderRadius: "5px",
                              }}
                            >
                              {noteDetailsData?.comments?.length}
                            </span>
                          </span>
                        </h5>
                        <div className="bg-white mt-5 p-4 stylishEditor">
                          {getComments(noteDetailsData.comments)}
                        </div>

                        <div className="col-12 mt-4">
                          <div
                            style={{
                              backgroundColor: "#fff",
                              position: "relative",
                            }}
                          >
                            <div className="form-group">
                              <div
                                className="createNotesEditor commentSection"
                                style={{ border: "none" }}
                              >
                                <textarea
                                  type="text"
                                  className="form-control d-none"
                                  placeholder="Project brief"
                                  // onChange={handleNotesInputs}
                                  id="message_insert"
                                  value={newCommentValue}
                                ></textarea>

                                <MessageEditor
                                  setValue={setnewCommentValue}
                                  value={newCommentValue}
                                  config={config}
                                />
                              </div>
                            </div>{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12 mt-2">
                        <Button
                          className="btn col-lg-5 mx-auto btnsm"
                          style={{ textTransform: "capitalize" }}
                          onClick={addComment}
                          id="editContentdisabled1"
                        >
                          {spinner ? (
                            <>
                              <Spinner
                                size="sm"
                                style={{
                                  width: "1rem",
                                  height: "1rem",
                                  marginRight: "5px",
                                }}
                              />
                              Add Comment
                            </>
                          ) : (
                            " Add Comment"
                          )}
                        </Button>
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </main>
      </div>
      {/*share modals*/}
      <div
        className="modal fade pro-jects1"
        id="shareModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div
              className="modal-header"
              style={{ border: "none", marginTop: "-5px" }}
            >
              <h5
                className="modal-titles"
                id="exampleModalLabel"
                style={{ visibility: "visible" }}
              >
                Share
              </h5>
              <button
                id="closeContentModal"
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={modalClose}
              ></button>
            </div>
            <div className="modal-body">
              <div className="mt-4">
                <div className="modalWrapper">
                  <div className="linkWrapper">
                    <div className="linkChild">
                      <span className="linkTitle">Shareable link</span>
                    </div>
                    <div className="copyLink">
                      <CopyToClipboard copyText={window.location.href} />
                    </div>
                  </div>
                  <div role="link" tabIndex="0">
                    <input
                      className="shareableLink"
                      type="text"
                      readOnly
                      value={window.location.href}
                    />
                  </div>
                  <div style={{ marginTop: "5px", fontSize: "14px" }}>
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="me-2"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M8.07 11.373a3.483 3.483 0 11.002-6.967 3.483 3.483 0 01-.001 6.967zm0-8.707c-3.243 0-5.962 2.221-6.736 5.224.774 3.003 3.493 5.224 6.737 5.224s5.962-2.221 6.736-5.224c-.774-3.003-3.492-5.224-6.736-5.224zM9.813 7.89a1.741 1.741 0 11-3.482 0 1.741 1.741 0 013.482 0z"
                        fill="#00A82D"
                      ></path>
                    </svg>
                    Anyone with the link can <strong>view</strong>
                  </div>
                </div>

                <div className="mt-4 inviteSection">
                  <div className="inviteTitle">Invite someone</div>
                  <InputGroup className="mb-3 inputGroupWrppr">
                    <FormControl
                      placeholder="Enter email"
                      aria-label="Enter email"
                      aria-describedby="basic-addon2"
                      className="inputGroupInput"
                      name="email"
                      value={userEmail}
                      onChange={(event) => {
                        handleRequestInputs(event);
                        handleChange(event);
                      }}
                    />
                    <InputGroup.Text
                      id="basic-addon2"
                      className="inputGroups"
                      style={{ backgroud: "#fff" }}
                    >
                      <Dropdown>
                        <Dropdown.Toggle
                          className="dropdownBtn"
                          id="dropdown-basic"
                          style={{
                            background: "none",
                            border: "none",
                            color: "#000000",
                          }}
                        >
                          {payload.email !== "" && payload.permission !== ""
                            ? payload.permission === "editi"
                              ? "edit and invite"
                              : payload.permission
                            : "Can edit and invite"}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item
                            href="#/action-1"
                            onClick={() =>
                              updateNotePermission("editi", userEmail)
                            }
                          >
                            Can edit and invite
                          </Dropdown.Item>
                          <Dropdown.Item
                            href="#/action-2"
                            onClick={() =>
                              updateNotePermission("edit", userEmail)
                            }
                          >
                            Can edit
                          </Dropdown.Item>
                          <Dropdown.Item
                            href="#/action-3"
                            onClick={() =>
                              updateNotePermission("view", userEmail)
                            }
                          >
                            Can view
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </InputGroup.Text>
                  </InputGroup>
                </div>

                {show && (
                  <div
                    className="d-flex justify-content-end"
                    style={{ border: "none" }}
                  >
                    <button
                      type="button"
                      className="btn btn-secondary"
                      style={{ marginRight: "1rem" }}
                      onClick={() => {
                        setEmail("");
                        setPayload({ email: "", permission: "" });
                      }}
                    >
                      Cancel
                    </button>
                    {noteDetailsData.permittedUsers.filter(
                      (o) => o.email === userEmail.trim()
                    ).length > 0 ? (
                      <button
                        type="button"
                        className="btn btn-primary"
                        style={{ backgroundColor: "#5746ec", border: "none" }}
                        disabled
                      // onClick={makeUpdate}
                      >
                        Submit
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-primary"
                        style={{ backgroundColor: "#5746ec", border: "none" }}
                        onClick={makeUpdate}
                      >
                        Submit
                      </button>
                    )}
                  </div>
                )}

                {noteDetailsData.permittedUsers &&
                  noteDetailsData?.permittedUsers?.length > 0 ? (
                  <>
                    <button className="accessInfo" type="button">
                      <span style={{ transform: "rotateZ(90deg)" }}>
                        <svg
                          width="24"
                          height="24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          id="qa-SHARE_DIALOG_SHARED_WITH_ARROW"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M10 7l6 5-6 5V7z"
                            fill="currentColor"
                          ></path>
                        </svg>
                      </span>
                      <div
                        id="qa-SHARE_DIALOG_SHARED_WITH_MORE"
                        className="s1E8jgYqDWXyHdyBw59R awJGfcqMABRSyxZlnZGS _7_HBJzy3qX1OrTcUZny"
                      >
                        Who has access
                        <span className="Oy7u6Y9EGKJJM_TfDDta">
                          {noteDetailsData.permittedUsers
                            ? noteDetailsData.permittedUsers?.length
                            : 0}
                        </span>
                      </div>
                    </button>
                  </>
                ) : null}

                <div>
                  <div className="sharedInfoWrapper">
                    <div className="sharedInfoChild">
                      <div className="sharedInfoProfile">{getPUserList()}</div>
                    </div>
                  </div>
                </div>

                <div className="ZP9BDnxHhGA9rSLoFsa3">
                  <button
                    id="qa-SHARE_DIALOG_STOP"
                    className="cDCnsp1vcViX1pVJM7MV"
                    type="button"
                    tabIndex="0"
                    onClick={(e) => stopSharing(e)}
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    Stop sharing
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="DeleteModal1"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div
              className="modal-header"
              style={{ border: "none", marginTop: "-10px" }}
            >
              <h4 className="modal-title">Are you sure ?</h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>

            <div className="modal-body">
              <div className="">
                <div className="text-left">
                  <p style={{ marginTop: "12px", marginBottom: "-1rem" }}>
                    Do you really want to delete this record? This process cannot be
                    undone.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="modal-footer d-flex justify-content-end"
              style={{ border: "none" }}
            >
              <button
                data-bs-dismiss="modal"
                type="button"
                className="btn btn-secondary"
                style={{ border: "none" }}
              >
                Cancel
              </button>
              <button
                type="button"
                onClick={deleteNote}
                className="btn btn-primary"
                style={{ backgroundColor: "#5746ec", border: "none" }}
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* <div
        className="modal fade"
        id="DeleteModal1"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div
              className="modal-header"
              style={{ border: "none", marginTop: "-10px" }}
            >
              <h4 className="modal-title">Are you sure ?</h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>

            <div className="modal-body">
              <div className="">
                <div className="text-left">
                  <p style={{ marginTop: "12px", marginBottom: "-1rem" }}>
                    Do you really want to deactivate these records? This process
                    cannot be undone.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="modal-footer d-flex justify-content-end"
              style={{ border: "none" }}
            >
              <button
                data-bs-dismiss="modal"
                type="button"
                className="btn btn-secondary"
                style={{ border: "none" }}
              >
                Cancel
              </button>
              <button
                type="button"
                onClick={deleteNote}
                className="btn btn-primary"
                style={{ backgroundColor: "#5746ec", border: "none" }}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default CreateNotes;
