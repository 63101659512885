import { Comment, Form, Input, List } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import {
    Dropdown,
    FormControl,
    InputGroup,
    OverlayTrigger,
    Popover
} from "react-bootstrap";
import { createSearchParams, NavLink, useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../assests/css/custom.css";
import audioImg from "../assests/images/audio.svg";
import docxImg from "../assests/images/docx.svg";
import folder_icons1 from "../assests/images/folder-icons1.png";
import imageImg from "../assests/images/image.svg";
import messageColoredSvg from "../assests/images/message-colored.svg";
import messageSvg from "../assests/images/message.svg";
import pdfImg from "../assests/images/pdf.svg";
import pptxImg from "../assests/images/pptx.svg";
import videoImg from "../assests/images/video.svg";
import API_PATH from "../Constants/api-path";
import MessageEditor from "../messageEditor";
import { CopyToClipboard } from "../Utils/CopyToClipboard";
const config = {
  readonly: false,
  width: "100%",
  enableDragAndDropFileToEditor: true,
  buttons: [
    "source",
    "|",
    "bold",
    "italic",
    "underline",
    "|",
    "ul",
    "ol",
    "|",
    "font",
    "fontsize",
    "brush",
    "paragraph",
    "|",
    "image",
    "table",
    "link",
    "|",
    "left",
    "center",
    "right",
    "justify",
    "|",
    "undo",
    "redo",
    "|",
    "hr",
    "eraser",
    "fullsize",
  ],
  uploader: { insertImageAsBase64URI: false },
  removeButtons: [
    "brush",
    "file",
    "hr",
    "font",
    "fontsize",
    "brush",
    "image",
    "undo",
    "redo",
    "link",
    "fullsize",
    "table",
    "undo",
    "redo",
    "back",
    "paint",
    "eraser",
    "paragraph",
    "source",
    "|",
    "justify",
  ],
  showXPathInStatusbar: false,
  showCharsCounter: false,
  showWordsCounter: true,
  toolbarAdaptive: false,
  toolbarSticky: false,
  toolbarButtonSize: "middle",
};

const { TextArea } = Input;

const CommentList = ({ comments }) => (
  <List
    dataSource={comments}
    header={`${comments.length} ${comments.length > 1 ? "replies" : "reply"}`}
    itemLayout="horizontal"
    renderItem={(props) => <Comment {...props} />}
  />
);

const Editor = ({ onChange, onSubmit, submitting, value }) => (
  <>
    <Form.Item>
      <TextArea rows={4} onChange={onChange} value={value} />
    </Form.Item>
    <div
      className="col-lg-12 d-flex justify-content-end"
      style={{ marginTop: "-10px", marginBottom: "-14px" }}
    >
      <button
        type="submit"
        htmlType="submit"
        loading={submitting}
        onClick={onSubmit}
        className="btn  btnsm"
      >
        {" "}
        Add Comment{" "}
      </button>
    </div>
  </>
);

const FolderViewTable = () => {
  const [data, setData] = useState([]);
  const userData = JSON.parse(localStorage.getItem("userData"));
  const [searchParams, setSearchParams] = useSearchParams();
  searchParams.get("noteId");
  const [value, setValue] = useState("");
  const [sleeve, setSleeve] = useState(data.sleeve);
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [locationDetails, setLocationDetails] = useState("");
  const [deleteData, setDeleteData] = useState("");
  const [isSleeve, setIsSleeve] = useState("");
  const [userEmail, setEmail] = useState("");
  const [noteDetailsData, setNoteDetailsData] = useState("");
  const [permittedUsers, setPermittedUsers] = useState("");
  const [commentStatus, setCommentStatus] = useState(false);
  const [commentDetails, setCommentsDetails] = useState({
    id: "",
    name: "",
    userID: "",
    username: "",
    comments: [],
  });
  const [filename, setFileName] = useState("");
  const [comment, setComment] = useState("");
  const [payload, setPayload] = useState({
    email: "",
    permission: "editi",
  });
  const history = useNavigate();
  const [show, setShow] = useState(false);
  const handleChange = (event) => {
    setPayload({ ...payload, [event.target.name]: event.target.value });
    if (event.target.value.length > 0) setShow(true);
    else setShow(false);
  };

  useEffect(() => {
    getCover();
  }, []);

  const current = new Date();
  const date = `${current.getDate()}/${
    current.getMonth() + 1
  }/${current.getFullYear()}`;

  const getCover = async () => {
    try {
      const res = await fetch(
        `${API_PATH.COVER_DETAILS}/${searchParams.get("coverID")}/${
          userData.user._id
        }`,
        {
          method: "GET",
          headers: headers,
        }
      );

      const result = await res.json();
      if (result) {
        setData(result);
        setTimeout(() => {
          setLoading(true);
        }, 1000);
      } else {
        return toast.error("something went wrong server error", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    } catch (error) {
      return toast.error("something went wrong server error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
  };

  useEffect(() => {
    if (locationDetails !== "") {
      get_content_sleeve();
    }
  }, [locationDetails]);

  if (loading) {
    var contentdata = data.contents;
    // console.log(contentdata);
    var sleevedata = data.sleeve;
    if (contentdata.length > 0 && sleevedata.length > 0) {
      var allDatas = [...contentdata, ...sleevedata];
    } else if (contentdata.length > 0) {
      var allDatas = [...contentdata];
    } else if (sleevedata.length > 0) {
      var allDatas = [...sleevedata];
    }
  }

  // console.log(allDatas);

  const get_content_sleeve = async () => {
    let name1 = locationDetails.split("=")[2];
    //console.log(name1);
    if (name1 === undefined) {
      let data = locationDetails.split("=")[1];

      try {
        const res = await fetch(`${API_PATH.CONTENT_DETAILS}/${data}`, {
          method: "GET",
          headers: headers,
        });

        const result = await res.json();
        if (result) {
          //console.log(result.permittedUsers);
          setPermittedUsers(result.permittedUsers);
          setLoading2(true);
        } else {
          // console.log(10);
          return toast.error("something went wrong server error", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
        }
      } catch (error) {
        // console.log("11");
        return toast.error("something went wrong server error", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    } else {
      let data = locationDetails.split("=")[1];
      let id = data.split("&")[0];

      try {
        const res = await fetch(`${API_PATH.SLEEVE_DETAILS}/${id}`, {
          method: "GET",
          headers: headers,
        });

        const result = await res.json();
        if (result) {
          // console.log(result.permittedUsers);
          setPermittedUsers(result.permittedUsers);
          setLoading2(true);
        } else {
          return toast.error("something went wrong server error", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
        }
      } catch (error) {
        return toast.error("something went wrong server error", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    }
  };

  // console.log(permittedUsers);

  const convertDate = (date) => {
    if (date !== null && date !== "" && date !== undefined) {
      const localDate = new Date(date);
      const localDateString = localDate.toLocaleDateString(undefined, {
        day: "numeric",
        month: "short",
        year: "numeric",
      });

      const localTimeString = localDate.toLocaleTimeString(undefined, {
        hour: "2-digit",
        minute: "2-digit",
      });

      let p = `${localDateString} ${localTimeString}`;
      return p;
    } else {
      return "---------";
    }
  };

  const getUserId = (id, name) => {
    setDeleteData(id);
    setIsSleeve(name);
    // console.log(deleteId);
  };

  // console.log(deleteData);

  const headers = {
    "x-access-token": userData.access_token,
  };

  const RemoveDatas = async () => {
    //console.log(data);
    if (isSleeve === undefined) {
      //console.log(isSleeve);
      axios
        .delete(`${API_PATH.CONTENT_DELETE}?ContentID=${deleteData}`, {
          method: "DELETE",
          headers: headers,
        })
        .then((res) => {
          //getUsers();
          // console.log(res);
          if (res.status === 200) {
            // console.log(res.status);
            setTimeout(() => {
              window.location.reload();
            }, 3000);
            toast.success("Content deleted successfully!", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
            });
          } else {
            return toast.error("something went wrong server error", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      axios
        .put(`${API_PATH.SLEEVE_DELETE}/${deleteData}`, {
          method: "PUT",
          headers: headers,
        })
        .then((res) => {
          //getUsers();
          // console.log(res);
          if (res.status === 200) {
            // console.log(res.status);
            setTimeout(() => {
              window.location.reload();
            }, 1000);
            toast.success("sleeve deleted successfully!", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
            });
          } else {
            return toast.error("something went wrong server error", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const goToDetailsPage = (id, name, share, permit) => {
    if (share) {
      if (name === undefined) {
        name = "content";
        var locationDetails =
          window.location.origin +
          "/collaborate/notes-details" +
          "?contentId=" +
          id;
        //console.log(location);
      } else {
        name = "sleeve";
        var locationDetails =
          window.location.origin +
          "/collaborate/recent-details" +
          "?" +
          createSearchParams({ id, name });
        //console.log(location);
      }
      setLocationDetails(locationDetails);
      setLoading1(true);
    } else if (name === undefined) {
      //console.log("content", id);

      name = "content";
      history("/collaborate/notes-details" + "?contentId=" + id);
    } else {
      //console.log("sleeve", id);
      name = "sleeve";
      history({
        pathname: "/collaborate/recent-details",
        search: `?${createSearchParams({ id, name })}`,
      });
    }
    //console.log(project_id);
    // history({
    //   pathname: "/projects-details",
    //   search: `?${createSearchParams({ project_id })}`,
    // });
  };

  const handleRequestInputs = (e) => {
    const key = e.target.name;
    const value = e.target.value;

    setEmail(value);
  };
  // console.log(userEmail);

  const updateNotePermission = async (permission, email) => {
    setPayload({
      email: email,
      permission: permission,
    });
  };
  //console.log(payload);
  const makeUpdate = async () => {
    let name1 = locationDetails.split("=")[2];
    // console.log(name1);
    if (name1 === undefined) {
      let data = locationDetails.split("=")[1];

      //console.log(id.split("&")[0]);

      try {
        const res = await fetch(
          `${API_PATH.CONTENT_PERMISSION}/${data}/${userData.user._id}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              "x-access-token": userData.access_token,
            },
            body: JSON.stringify({
              permission: payload.permission,
              email: payload.email,
            }),
          }
        );
        const result = await res.json();
        if (result) {
          //console.log(result);
          setEmail("");
          get_content_sleeve();
        } else {
          return toast.error("something went wrong server error", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
        }
      } catch (error) {
        return toast.error("something went wrong server error", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    } else {
      let data = locationDetails.split("=")[1];
      let id = data.split("&")[0];
      //console.log(id.split("&")[0]);
      // console.log(id);
      try {
        const res = await fetch(
          `${API_PATH.SLEEVE_PERMISSION}/${id}/${userData.user._id}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              "x-access-token": userData.access_token,
            },
            body: JSON.stringify({
              permission: payload.permission,
              email: payload.email,
            }),
          }
        );
        const result = await res.json();
        if (result) {
          // console.log(result);
          get_content_sleeve();
        } else {
          return toast.error("something went wrong server error", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
        }
      } catch (error) {
        return toast.error("something went wrong server error", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    }
  };

  const updatePermission = async (permission, email) => {
    let name1 = locationDetails.split("=")[2];
    //console.log(name1);
    if (name1 === undefined) {
      let data = locationDetails.split("=")[1];
      //console.log(id.split("&")[0]);
      // console.log(permission);
      // console.log(email);
      try {
        const res = await fetch(
          `${API_PATH.CONTENT_PERMISSION}/${data}/${userData.user._id}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              "x-access-token": userData.access_token,
            },
            body: JSON.stringify({ permission: permission, email: email }),
          }
        );
        const result = await res.json();
        if (result) {
          // console.log(result);
          get_content_sleeve();
        } else {
          return toast.error("something went wrong server error", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
        }
      } catch (error) {
        return toast.error("something went wrong server error", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    } else {
      let data = locationDetails.split("=")[1];
      let id = data.split("&")[0];
      //console.log(id.split("&")[0]);
      // console.log(id);
      try {
        const res = await fetch(
          `${API_PATH.SLEEVE_PERMISSION}/${id}/${userData.user._id}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              "x-access-token": userData.access_token,
            },
            body: JSON.stringify({ permission: permission, email: email }),
          }
        );
        const result = await res.json();
        if (result) {
          // console.log(result);
          get_content_sleeve();
        } else {
          return toast.error("something went wrong server error", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
        }
      } catch (error) {
        return toast.error("something went wrong server error", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    }
  };

  const getDefaultText = (permission) => {
    if (permission === "editi") return "Can edit and invite";
    if (permission === "edit") return "Can edit";
    if (permission === "view") return "Can view";
  };

  const getPUserList = () => {
    //console.log(permittedUsers);
    if (permittedUsers instanceof Array) {
      return permittedUsers.map(function (object, i) {
        const { _id, firstNmae, email, username, permission } = object;
        return (
          <>
            <div className="shareWrppr" id={_id}>
              <div className="emailSection">
                <div className="profileIconWrppr">
                  <div className="avatarImg">
                    <img
                      width="45px"
                      src={
                        "https://ui-avatars.com/api/?&rounded=true&font-size=0.38&background=EFEFEF&color=f33636&name=" +
                        email
                      }
                    />
                  </div>

                  {/* <div
                    className="profileIcon"
                    style={{
                      backgroundImage: `url(https://ui-avatars.com/api/?background=0D8ABC&color=fff&name=${email})`,
                    }}
                  ></div> */}
                  <div className="ms-3">
                    <div id="qa-USER_LIST_USER_NAME" className="nameInfo">
                      <span className="">{firstNmae ? firstNmae : username}</span>
                    </div>
                    <div className="emailInfo" id="qa-USER_LIST_EMAIL">
                      <span className="">{email}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="Yourr_9w71MjtL3S9I4G">
                <div className="">
                  <Dropdown>
                    <Dropdown.Toggle
                      className="dropdownBtn"
                      id="dropdown-basic"
                      style={{
                        background: "none",
                        border: "none",
                        color: "#000000",
                      }}
                    >
                      {getDefaultText(permission)}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        href="#/action-1"
                        onClick={() => updatePermission("editi", email)}
                      >
                        Can edit and invite
                      </Dropdown.Item>
                      <Dropdown.Item
                        href="#/action-2"
                        onClick={() => updatePermission("edit", email)}
                      >
                        Can edit
                      </Dropdown.Item>
                      <Dropdown.Item
                        href="#/action-3"
                        onClick={() => updatePermission("view", email)}
                      >
                        Can view
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>
          </>
        );
      });
    }
  };

  const stopSharing = async (e) => {
    e.preventDefault();
    let name1 = locationDetails.split("=")[2];
    //console.log(name1);
    if (name1 === undefined) {
      let data = locationDetails.split("=")[1];

      try {
        let payload = {};
        payload.permittedUsers = [];
        const res = await fetch(
          `${API_PATH.CONTENT_UPDATE}?ContentID=${data}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              "x-access-token": userData.access_token,
            },
            body: JSON.stringify(payload),
          }
        );

        const result = await res.json();
        if (result) {
          get_content_sleeve();
        } else {
          return toast.error("something went wrong server error", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
        }
      } catch (error) {
        return toast.error("something went wrong server error", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    } else {
      let data = locationDetails.split("=")[1];
      let id = data.split("&")[0];

      try {
        let payload = {};
        payload.permittedUsers = [];
        const res = await fetch(`${API_PATH.SLEEVE_UPDATE}/${id}`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            "x-access-token": userData.access_token,
          },
          body: JSON.stringify(payload),
        });

        const result = await res.json();
        if (result) {
          get_content_sleeve();
        } else {
          return toast.error("something went wrong server error", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
        }
      } catch (error) {
        return toast.error("something went wrong server error", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    }
  };

  const commentsDetails = (id, type, name, userID, username, comments) => {
    // console.log(comments);
    // if (name === undefined) {
    //   name = "content";
    //   setCommentsDetails({
    //     id: id,
    //     name: name,
    //     userID: userID,
    //     username: username,
    //     comments: comments,
    //   });
    // } else {
    //   name = "sleeve";
    //   setCommentsDetails({
    //     id: id,
    //     name: name,
    //     userID: userID,
    //     username: username,
    //     comments: comments,
    //   });
    // }
    setCommentsDetails({
      id: id,
      type,
      name: name,
      userID: userID,
      username: username,
      comments: comments,
    });
    setFileName(username);
  };

  // console.log(setFileName, "comments");

  const handledChanges = (e) => {
    setValue(e.target.value);
  };

  const handleSubmit = async () => {
    if (!value) {
      return toast.warning("All fields are Mandatory", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
    const { id, name, userID, username } = commentDetails;
    let payload = {};
    payload.comments = [
      ...commentDetails.comments,
      {
        userId: userID,
        commnt: value,
        createdAt: Date.now(),
        userName: username,
        likeCount: 0,
        disLikeCount: 0,
      },
    ];
    //console.log(payload);
    if (commentDetails.name === "content") {
      try {
        const res = await fetch(`${API_PATH.CONTENT_UPDATE}?ContentID=${id}`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            "x-access-token": userData.access_token,
          },
          body: JSON.stringify(payload),
        });
        const result = await res.json();
        if (result) {
          // console.log(result);
          getCover();
          setValue("");
          document.getElementById("commentModal1").click();
        } else {
          return toast.error("something went wrong server error", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
        }
      } catch (error) {
        return toast.error("something went wrong server error", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    } else if (commentDetails.name === "sleeve") {
      try {
        const res = await fetch(`${API_PATH.SLEEVE_UPDATE}/${id}`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            "x-access-token": userData.access_token,
          },
          body: JSON.stringify(payload),
        });
        const result = await res.json();
        if (result) {
          // console.log(result);
          getCover();
          setValue("");
          document.getElementById("commentModal1").click();
        } else {
          return toast.error("something went wrong server error", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
        }
      } catch (error) {
        return toast.error("something went wrong server error", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    } else {
      try {
        const res = await fetch(`${API_PATH.NOTE_UPDATE}?NoteID=${id}`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            "x-access-token": userData.access_token,
          },
          body: JSON.stringify(payload),
        });
        const result = await res.json();
        if (result) {
          // console.log(result);
          getCover();
          setValue("");
          document.getElementById("commentModal1").click();
        } else {
          return toast.error("something went wrong server error", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
        }
      } catch (error) {
        return toast.error("something went wrong server error", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    }
  };

  // {Update Api}

  const updateApiData = async (payload, name) => {
    //console.log(name);
    if (name === "content") {
      try {
        const res = await fetch(
          `${API_PATH.CONTENT_UPDATE}?ContentID=${payload._id}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              "x-access-token": userData.access_token,
            },
            body: JSON.stringify(payload),
          }
        );

        const result = await res.json();
        if (result) {
          //getNotes();
          // console.log(result);
        } else {
          return toast.error("something went wrong server error1", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
        }
      } catch (error) {
        // console.log(error);
        return toast.error("something went wrong server error2", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    } else {
      try {
        const res = await fetch(`${API_PATH.SLEEVE_UPDATE}/${payload._id}`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            "x-access-token": userData.access_token,
          },
          body: JSON.stringify(payload),
        });

        const result = await res.json();
        if (result) {
          //getNotes();
          // console.log(result);
        } else {
          return toast.error("something went wrong server error1", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
        }
      } catch (error) {
        // console.log(error);
        return toast.error("something went wrong server error2", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    }
  };

  const UpdateApi = async (id, name) => {
    //console.log(name1);
    if (name === undefined) {
      name = "content";
      try {
        const res = await fetch(`${API_PATH.CONTENT_DETAILS}/${id}`, {
          method: "GET",
          headers: headers,
        });

        const result = await res.json();
        if (result) {
          let payload = {
            ...result,
          };
          payload.viewedOn = new Date();
          //console.log(payload);

          updateApiData(payload, name);
        } else {
          return toast.error("something went wrong server error", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
        }
      } catch (error) {
        return toast.error("something went wrong server error", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    } else {
      try {
        const res = await fetch(`${API_PATH.SLEEVE_DETAILS}/${id}`, {
          method: "GET",
          headers: headers,
        });

        const result = await res.json();
        if (result) {
          let payload = {
            ...result,
          };
          payload.viewedOn = new Date();
          //console.log(payload);

          updateApiData(payload);
        } else {
          return toast.error("something went wrong server error", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
        }
      } catch (error) {
        return toast.error("something went wrong server error", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    }
  };

  return (
    <>
      <div>
        <table className="mt-4 table">
          <thead>
            <tr>
              <th title="File name" className="file-name">
                File name
              </th>
              <th title="Created at" className="createdby">
                Created at
              </th>
              <th
                title="Comments"
                style={{ textAlign: "center" }}
                className="comments"
              >
                Comments
              </th>
              <th title="Comments" className="">
                Action
              </th>
              <th title="options" className=""></th>
            </tr>
          </thead>
          <tbody>
            {allDatas ? (
              allDatas.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <div
                        className="d-flex align-items-center"
                        style={{ cursor: "pointer" }}
                      >
                        <OverlayTrigger
                          trigger="hover"
                          key={Math.random()}
                          placement={"top"}
                          overlay={
                            <Popover id={`popover-positioned-${Math.random()}`}>
                              <Popover.Body className="p-2">
                                <div>
                                  {item.sleeveType === "doc"
                                    ? "Document"
                                    : item.sleeveType === "system"
                                    ? "PPTX"
                                    : item.sleeveType === "audio"
                                    ? "Audio"
                                    : item.sleeveType === "pdf"
                                    ? "PDF"
                                    : item.sleeveType === "video"
                                    ? "Video"
                                    : item.sleeveType === "image"
                                    ? "Image"
                                    : "Other"}
                                </div>
                              </Popover.Body>
                            </Popover>
                          }
                        >
                          <img
                            className="me-2"
                            style={{ width: "45px" }}
                            src={
                              item.sleeveType === "doc"
                                ? docxImg
                                : item.sleeveType === "system"
                                ? pptxImg
                                : item.sleeveType === "audio"
                                ? audioImg
                                : item.sleeveType === "pdf"
                                ? pdfImg
                                : item.sleeveType === "video"
                                ? videoImg
                                : item.sleeveType === "image"
                                ? imageImg
                                : folder_icons1
                            }
                            alt="file"
                            onClick={() => {
                              UpdateApi(item._id, item.sleeveName);
                              goToDetailsPage(item._id, item.sleeveName);
                            }}
                          />
                        </OverlayTrigger>
                        <h6 className="comon-table-td">
                          <div
                            className="comon-table-td"
                            style={{ textDecoration: "none" }}
                            onClick={() => {
                              UpdateApi(item._id, item.sleeveName);
                              goToDetailsPage(item._id, item.sleeveName);
                            }}
                          >
                            <span className="d-block">
                              {" "}
                              {/* {console.log(item[1])} */}
                              {item.sleeveName ? item.sleeveName : item.name}
                            </span>
                          </div>
                        </h6>
                      </div>
                    </td>
                    <td>
                      <h6
                        className="mt-0 mb-1"
                        style={{ textTransform: "capitalize" }}
                      >
                        {data.cover.userId.name}
                      </h6>
                      <p className="mb-0" style={{ fontSize: "14px" }}>
                        {convertDate(item.createdAt)}
                      </p>
                    </td>

                    <td style={{ textAlign: "center" }}>
                      <div className="">
                        {/* {commentnumber} */}
                        {item.comments.length > 0 ? (
                          <>
                            <img
                              src={messageColoredSvg}
                              className="me-1"
                              loading="lazy"
                            />
                            <span style={{ fontSize: "14px" }}>
                              {item.comments.length > 9
                                ? "9+"
                                : item.comments.length}
                            </span>
                          </>
                        ) : (
                          <>
                            <img src={messageSvg} />
                          </>
                        )}
                      </div>
                    </td>

                    <td>
                      <NavLink
                        to="#"
                        className="actionNav"
                        data-bs-toggle="modal"
                        data-bs-target="#shareModal2"
                        onClick={() => {
                          goToDetailsPage(
                            item._id,
                            item.sleeveName,
                            "share",
                            item.permittedUsers
                          );
                        }}
                      >
                        {" "}
                        <img src="/images/share.png" alt="png" />{" "}
                      </NavLink>
                      <span
                        onClick={() =>
                          commentsDetails(
                            item._id,
                            item.sleeveName
                              ? "sleev"
                              : item.contentName
                              ? "content"
                              : "note",
                            item.userId._id,
                            item.userId.username,
                            item.name ? item.name : item.sleeveName,
                            item.comments
                          )
                        }
                      >
                        <NavLink
                          to="#"
                          className="actionNav"
                          data-bs-toggle="modal"
                          data-bs-target="#commentModal1"
                        >
                          {" "}
                          <img src="/images/chat.png" alt="png" />{" "}
                        </NavLink>
                      </span>
                    </td>
                    <td>
                      <div className="dropdown">
                        <button
                          className="btn p-0 "
                          type="button"
                          id="dropdownMenuButton1"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <img src="/images/blue-dots.png" alt="png" />
                        </button>
                        <ul
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenuButton1"
                        >
                          <li>
                            <a
                              className="dropdown-item"
                              href="#"
                              onClick={() =>
                                goToDetailsPage(item._id, item.sleeveName)
                              }
                            >
                              <i className="fa fa-eye" aria-hidden="true"></i>
                              <span style={{ marginLeft: "10px" }}>View</span>
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              data-bs-toggle="modal"
                              data-bs-target="#deleteModal"
                              href="#"
                              onClick={() =>
                                getUserId(item._id, item.sleeveName)
                              }
                            >
                              <i
                                className="fa fa-trash-o"
                                aria-hidden="true"
                              ></i>
                              <span style={{ marginLeft: "10px" }}>Delete</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                );
              })
            ) : (
              <></>
            )}
          </tbody>
        </table>{" "}
        {/*share modals*/}
        <div
          className="modal fade addmodal cm-modal-all"
          id="shareModal2"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel"></h5>
                <button
                  id="closeContentModal"
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="mt-4">
                  <div className="modalWrapper">
                    <div className="linkWrapper">
                      <div className="linkChild">
                        <span className="linkTitle">Shareable link</span>
                      </div>
                      <div className="copyLink">
                        {loading1 ? (
                          <>
                            <CopyToClipboard copyText={locationDetails} />
                            {/* {console.log(locationDetails)} */}
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                    <div role="link" tabIndex="0">
                      <input
                        className="shareableLink"
                        type="text"
                        readOnly
                        value={locationDetails}
                      />
                    </div>
                    <div style={{ marginTop: "5px", fontSize: "14px" }}>
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="me-2"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M8.07 11.373a3.483 3.483 0 11.002-6.967 3.483 3.483 0 01-.001 6.967zm0-8.707c-3.243 0-5.962 2.221-6.736 5.224.774 3.003 3.493 5.224 6.737 5.224s5.962-2.221 6.736-5.224c-.774-3.003-3.492-5.224-6.736-5.224zM9.813 7.89a1.741 1.741 0 11-3.482 0 1.741 1.741 0 013.482 0z"
                          fill="#00A82D"
                        ></path>
                      </svg>
                      Anyone with the link can <strong>view</strong>
                    </div>
                  </div>

                  <div className="mt-4 inviteSection">
                    <div className="inviteTitle">Invite someone</div>
                    <InputGroup className="mb-3 inputGroupWrppr">
                      <FormControl
                        placeholder="Enter email"
                        aria-label="Enter email"
                        aria-describedby="basic-addon2"
                        className="inputGroupInput"
                        name="email"
                        value={userEmail}
                        onChange={(event) => {
                          handleRequestInputs(event);
                          handleChange(event);
                        }}
                      />
                      <InputGroup.Text
                        id="basic-addon2"
                        className="inputGroups"
                        style={{ backgroud: "#fff" }}
                      >
                        <Dropdown>
                          <Dropdown.Toggle
                            className="dropdownBtn"
                            id="dropdown-basic"
                          >
                            {payload.email !== "" && payload.permission !== ""
                              ? payload.permission === "editi"
                                ? "edit and invite"
                                : payload.permission
                              : "Can edit and invite"}
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item
                              href="#/action-1"
                              onClick={() =>
                                updateNotePermission("editi", userEmail)
                              }
                            >
                              Can edit and invite
                            </Dropdown.Item>
                            <Dropdown.Item
                              href="#/action-2"
                              onClick={() =>
                                updateNotePermission("edit", userEmail)
                              }
                            >
                              Can edit
                            </Dropdown.Item>
                            <Dropdown.Item
                              href="#/action-3"
                              onClick={() =>
                                updateNotePermission("view", userEmail)
                              }
                            >
                              Can view
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </InputGroup.Text>
                    </InputGroup>
                  </div>

                  {show && (
                    <div
                      className="d-flex justify-content-end"
                      style={{ border: "none" }}
                    >
                      <button
                        type="button"
                        className="btn btn-secondary"
                        style={{ marginRight: "1rem" }}
                        onClick={() => {
                          setEmail("");
                          setPayload({ email: "", permission: "" });
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        className="btn btn-primary"
                        style={{ backgroundColor: "#5746ec", border: "none" }}
                        onClick={makeUpdate}
                      >
                        Submit
                      </button>
                    </div>
                  )}

                  {permittedUsers ? (
                    <>
                      {permittedUsers?.length > 0 ? (
                        <>
                          <button className="accessInfo" type="button">
                            <span style={{ transform: "rotateZ(90deg)" }}>
                              <svg
                                width="24"
                                height="24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                id="qa-SHARE_DIALOG_SHARED_WITH_ARROW"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M10 7l6 5-6 5V7z"
                                  fill="currentColor"
                                ></path>
                              </svg>
                            </span>

                            <div
                              id="qa-SHARE_DIALOG_SHARED_WITH_MORE"
                              className="s1E8jgYqDWXyHdyBw59R awJGfcqMABRSyxZlnZGS _7_HBJzy3qX1OrTcUZny"
                            >
                              Who has access
                              <span className="Oy7u6Y9EGKJJM_TfDDta">
                                {permittedUsers ? permittedUsers?.length : 0}
                              </span>
                            </div>
                          </button>
                        </>
                      ) : null}
                    </>
                  ) : (
                    <></>
                  )}

                  <div>
                    <div className="sharedInfoWrapper">
                      <div className="sharedInfoChild">
                        <div className="sharedInfoProfile">
                          {getPUserList()}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="ZP9BDnxHhGA9rSLoFsa3">
                    <button
                      id="qa-SHARE_DIALOG_STOP"
                      className="cDCnsp1vcViX1pVJM7MV"
                      type="button"
                      tabIndex="0"
                      onClick={stopSharing}
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      Stop sharing
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="deleteModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div
              className="modal-header"
              style={{ border: "none", marginTop: "-10px" }}
            >
              <h4 className="modal-title">Are you sure ?</h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>

            <div className="modal-body">
              <div className="">
                <div className="text-left">
                  <p style={{ marginTop: "18px" }}>
                    Do you really want to deactivate these records? This process
                    cannot be undone.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="modal-footer d-flex justify-content-end"
              style={{ border: "none" }}
            >
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={() => setEmail("")}
              >
                Cancel
              </button>
              <button
                data-bs-dismiss="modal"
                type="button"
                onClick={() => RemoveDatas()}
                className="btn btn-primary"
                style={{ backgroundColor: "#5746ec", border: "none" }}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade addmodal cm-modal-all"
        id="commentModal1"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div
              className="modal-header"
              style={{ border: "none", marginTop: "-8px" }}
            >
              <div className="modal-title h4">
                {" "}
                <h4 className="modal-title">Comment</h4>
              </div>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>{" "}
            <div className="modal-body">
              {/* <div className="mt-4">
                <Comment
                  content={
                    <Editor
                      onChange={handledChanges}
                      onSubmit={handleSubmit}
                      value={value}
                    />
                  }
                />
              </div> */}
              <div className="mt-4">
                <h4>{filename}</h4>
                <table className="mt-4 commentTable" style={{ width: "50%" }}>
                  <tr>
                    <td className="tableHe">Added By</td>
                    <td className="tableCo">{userData.user.username}</td>
                  </tr>
                  <tr>
                    <td className="tableHe">Date</td>
                    <td className="tableCo">{date}</td>
                  </tr>
                </table>
                {/* <Comment
                  content={
                    <Editor
                      onChange={handledChanges}
                      onSubmit={handleSubmit}
                      value={value}
                    />
                  }
                /> */}

                <div className="row">
                  <div
                    className="col-12"
                    style={{
                      backgroundColor: "#fff",
                      position: "relative",
                    }}
                  >
                    <div className="form-group">
                      <div
                        className="createNotesEditor"
                        style={{ border: "none" }}
                      >
                        <textarea
                          type="text"
                          className="form-control d-none"
                          placeholder="Project brief"
                          onChange={handledChanges}
                          id="message_insert"
                          value={value}
                        ></textarea>

                        <MessageEditor
                          setValue={setValue}
                          value={value}
                          config={config}
                        />
                      </div>
                    </div>{" "}
                  </div>
                </div>
                <div className="col-lg-12 d-flex justify-content-center align-items-center">
                  <button
                    type="submit"
                    htmlType="submit"
                    onClick={handleSubmit}
                    className="btn  btnsm"
                  >
                    {" "}
                    Add Comment{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FolderViewTable;
