import Box from "@mui/material/Box";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import PersonalizationTwo from "../components/PersonalizationTwo";
import API_PATH from "../Constants/api-path";
import "./Register.css";
import nextyn_logo from "../assests/images/Nextyn_logo.png"

import { toast } from "react-toastify";

const steps = ["Shipping address", "Payment details"];

function getStepContent(step) {
  switch (step) {
    case 0:
      return (
        <div
          style={{
            margin: "auto",
          }}
        >
          <PersonalizationTwo />
        </div>
      );
    // case 1:
    //   return <PersonalizationTwo />;
    // case 2:
    //   return <PersonalizationOne />;
    default:
      throw new Error("Unknown step");
  }
}
const RegisterStepOne = () => {
  const [activeStep, setActiveStep] = React.useState(0);
  const history = useNavigate();
  const [selected, setChecked] = useState([]);

  const [workspace, setWorkspace] = useState("");
  const [calendl, setCalendly] = useState(false);

  const name = localStorage.getItem("name");

  const handleNext = () => {
    history("/register-step/?step=2");
    setActiveStep(activeStep + 1);
  };

  const RedirectPage = () => {
    history("/");
  };

  const handleBack = () => {
    history("/register-step/?step=1");
    setActiveStep(activeStep - 1);
  };
  const handleSubmit = () => {
    history("/");
    // let pricing = localStorage.getItem("pricing-details");
    // localStorage.setItem("isAuth", true);
    // if (pricing) {
    //   pricing = JSON.parse(pricing);
    //   handleSubscribe(
    //     pricing.price,
    //     pricing.priceType,
    //     pricing.price_id,
    //     pricing.checked,
    //     pricing.trial_end
    //   );
    // } else {
    //   handleUpdateRedirect();
    // }
  };
  const handleUpdate = async () => {
    const userData = JSON.parse(localStorage.getItem("userData") || "{}");
    try {
      const res = await fetch(
        `${API_PATH.STEP}/${"user/update/" + userData.user._id}`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            selectedCategory: JSON.parse(localStorage.getItem("selected")),
            workspaceName: localStorage.getItem("workspaceName"),
          }),
        }
      );
      const result = await res.json();
      console.log("result", result);
      if (result) {
        if (result.success === true) {
          userData.user["workspaceName"] =
            localStorage.getItem("workspaceName");
          userData.user["selectedCategory"] = JSON.parse(
            localStorage.getItem("selected")
          );
          localStorage.setItem("userData", JSON.stringify(userData));
          localStorage.removeItem("workspaceName");
        }
      } else {
        return toast.error("something went wrong server error", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    } catch (error) {
      console.log(error);
      return toast.error("Please Login First to continue", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
  };

  const handleUpdateRedirect = async () => {
    const userData = JSON.parse(localStorage.getItem("userData") || "{}");
    try {
      const res = await fetch(
        `${API_PATH.STEP}/${"user/update/" + userData.user._id}`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            selectedCategory: JSON.parse(localStorage.getItem("selected")),
            workspaceName: localStorage.getItem("workspaceName"),
          }),
        }
      );
      const result = await res.json();
      console.log("result", result);
      if (result) {
        if (result.success === true) {
          localStorage.setItem("isAuth", true);
          userData.user["workspaceName"] =
            localStorage.getItem("workspaceName");
          userData.user["selectedCategory"] = JSON.parse(
            localStorage.getItem("selected")
          );
          localStorage.setItem("userData", JSON.stringify(userData));
          localStorage.removeItem("workspaceName");
          history("/projects");
        }
      } else {
        return toast.error("something went wrong server error", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    } catch (error) {
      console.log(error);
      return toast.error("Please Login First to continue", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
  };

  // const prefill = {
  //   email: userData.user.email,
  //   name: userData.user.firstNmae,
  // };

  // const handleSubscribe = async (
  //   price,
  //   priceType,
  //   price_id,
  //   checked,
  //   trial_end
  // ) => {
  //   const userData = JSON.parse(localStorage.getItem("userData") || "{}");
  //   if (userData?.user?._id) {
  //     let d = new Date();
  //     d.setDate(d.getDate() + 365);
  //     if (price === 0) {
  //       try {
  //         const res = await fetch(
  //           `${API_PATH.STEP}/${"user/update/" + userData.user._id}`,
  //           {
  //             method: "POST",
  //             headers: { "Content-Type": "application/json" },
  //             body: JSON.stringify({
  //               isSuscribed: 0,
  //               subscriptionExpieryDate: d,
  //               // selectedCategory: selected,
  //               // workspaceName: localStorage.getItem('workspaceName')
  //             }),
  //           }
  //         );
  //         const result = await res.json();
  //         console.log("result", result);
  //         if (result) {
  //           if (result.success === true) {
  //             userData.user["isSuscribed"] = 0;
  //             userData.user["subscriptionExpieryDate"] = d;
  //             userData.user["workspaceName"] =
  //               localStorage.getItem("workspaceName");
  //             userData.user["selectedCategory"] = selected;
  //             localStorage.setItem("userData", JSON.stringify(userData));
  //             localStorage.removeItem("workspaceName");
  //             history("/dashboard");
  //           }
  //         } else {
  //           return toast.error("something went wrong server error", {
  //             position: toast.POSITION.TOP_RIGHT,
  //             autoClose: 3000,
  //           });
  //         }
  //       } catch (error) {
  //         console.log(error);
  //         return toast.error("Please Login First to continue", {
  //           position: toast.POSITION.TOP_RIGHT,
  //           autoClose: 3000,
  //         });
  //       }
  //     } else {
  //       handleUpdate();
  //       try {
  //         const res = await fetch(
  //           // `${Config.cloudUrl}/${"stripe/create-payment"}`,
  //           {
  //             method: "POST",
  //             headers: { "Content-Type": "application/json" },
  //             body: JSON.stringify({
  //               email: userData.user.email,
  //               price_id: price_id,
  //               trial_end,
  //               success_url: `${window.location.origin}/success-page?checked=${checked}&priceType=${priceType}`,
  //               cancel_url: `${window.location.origin}/failure-page?checked=${checked}&priceType=${priceType}`,
  //             }),
  //           }
  //         );
  //         const result = await res.json();
  //         if (result) {
  //           localStorage.setItem("customerID", result.customer);
  //           window.location.href = result.url;
  //         } else {
  //           console.log("something went wrong server error");
  //         }
  //       } catch (error) {
  //         console.log(error);
  //       }
  //     }
  //   } else {
  //     window.location.href = `${window.location.origin}/register`;
  //   }
  // };

  const handleChange = (e) => {
    setChecked([...selected, e.target.value]);
  };

  return (
    <>
      <section className="mt-5">
        <div className="container-fluid">
          <div className="text-center logo-admin">
            <Link className="navbar-brand mt-4" to={"/"}
              style={{ width: "20%", height: "20%" }}>
              <img src={nextyn_logo} alt="logo" />
            </Link>
          </div>
          <div className="row align-items-center" style={{ height: "70vh" }}>
            <div className="col-12">
              <div className="row d-flex justify-content-center align-items-center">
                <div className="col-12 col-xxl-12 text-center comon-fild">
                  <h4 className="m-5" style={{ fontSize: "32px" }}>
                    Welcome, <span style={{ color: "#5746ec" }}>{name}</span>{" "}
                  </h4>
                  <p style={{ color: "rgb(132 147 166)", fontSize: "20px" }}>
                    Select multiple industries that are of interest to you.
                    <br />
                    Your personalised dashboard will display a custom list of
                    experts from the selected industries.
                  </p>
                </div>
                <React.Fragment>
                  {activeStep === steps.length ? (
                    <React.Fragment>
                      <div className="px-xl-1 mx-auto col-sm-8 col-md-6 col-lg-6">
                        <div className="card cards-border">
                          <div className="card-body p-4 text-center">
                            <div className="text-center">
                              <h3>Your’e all set!</h3>
                              {/* <NavLink to="/projects" variant="contained"> */}
                              <button
                                className="not-filled-btn"
                                style={{
                                  background: "#fff",
                                  height: "40px",
                                }}
                                onClick={handleUpdateRedirect}
                              // onClick={() => setCalendly(true)}
                              >
                                Go To Dashboard
                              </button>
                              {/* </NavLink>   */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <div className="px-xl-1 mx-auto col-sm-10">
                        <div className="">
                          <div className="card-body p-4 text-center">
                            <div className="text-center">
                              {/* <h4 className="mb-3" style={{ color: "#5e5873" }}>
                      Welcome to NextynTrends!
                    </h4> */}

                              {getStepContent(activeStep)}
                              <Box>
                                <div className="row justify-content-end">
                                  <div className="col-12 col-md-3">
                                    {/* {activeStep === steps.length - 1 ? ( */}
                                    <>
                                      <div style={{ paddingLeft: "1.5em" }}>
                                        <button
                                          className="not-filled-btn w-100"
                                          type="button"
                                          style={{
                                            background: "#fff",
                                            height: "40px",
                                          }}
                                          onClick={handleUpdateRedirect}
                                        >
                                          Submit
                                        </button>
                                      </div>
                                    </>
                                    {/* ) : (
                                      <>
                                        <button
                                          className="not-filled-btn w-25"
                                          type="button"
                                          style={{
                                            background: "#fff",
                                            height: "40px",
                                            margin: "auto",
                                          }}
                                          onClick={handleNext}
                                        >
                                          Create Workspace
                                        </button>
                                      </>
                                    )} */}
                                  </div>
                                </div>
                              </Box>
                            </div>
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  )}
                </React.Fragment>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default RegisterStepOne;
