import { css } from "@emotion/css";
import React, { useEffect, useState } from "react";
import { Dropdown, FormControl, InputGroup } from "react-bootstrap";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import { useSearchParams } from "react-router-dom";
import ScrollToBottom from "react-scroll-to-bottom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import API_PATH from "../Constants/api-path";
import Sidebar from "../Sidebar";

const ROOT_CSS = css({
  height: 430,
});
const TranscriptDetail = () => {
  const userData = JSON.parse(localStorage.getItem("userData"));
  const headers = {
    "x-access-token": userData.access_token,
  };
  const [recordingData, setRecordingData] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const getRecordingDetails = async () => {
    try {
      const res = await fetch(
        `${API_PATH.MEETING_ID_GET}/${searchParams.get("meetingId")}`,
        {
          method: "GET",
          headers: headers,
        }
      );

      const result = await res.json();
      if (result) {
        setRecordingData(result.data);
      } else {
        return toast.error("something went wrong server error", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    } catch (error) {
      return toast.error("something went wrong server error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
  };

  useEffect(() => {
    getRecordingDetails();
  }, []);

  return (
    <>
      <div className="page-wrapper chiller-theme toggled">
        <div className="main-sec d-lg-flex">
          <div className="sidebar-menu" id="navAccordion">
            <Sidebar />
          </div>
        </div>
        <main className="body-total content-wrapper float-start w-100">
          <div className="container-fluid">
            {/* <div className="d-block d-lg-block">
              <div className="top-bar-menu float-start" id="header">
                <TopMenu />
              </div>
            </div> */}

            <div className="w-100">
              <div className="body-expart-div messageScreen request-page projects-scope">
                <span className="sp-hr w-100" />

                <div
                  className="projects-details-page details-message-part"
                  style={{ marginBottom: "20px" }}
                >
                  <div
                    className="d-flex align-items-center"
                    style={{ justifyContent: "space-between" }}
                  >
                    <h3 className="ms-3">Transcript</h3>
                    {/* <div className="">
                      <button
                        style={{
                          // backgroundColor: "rgb(0, 168, 45)",
                          color: "#ffffff",
                        }}
                        type="button"
                        to="#"
                        className="one-ex-btn btn"
                        data-bs-toggle="modal"
                        data-bs-target="#shareModal2"
                      >
                        Share
                      </button>
                    </div>{" "} */}
                  </div>

                  {/* {Object.keys(recordingData).length > 2 ? ( */}
                  <div className="message-text-area12 mt-4">
                    <>
                      <ul className="mainComment2" id="messagesEndRef">
                        <ScrollToBottom className={ROOT_CSS}>
                          <li className="adminMessage clearfix p-3">
                            <h2
                              className="elastic-header"
                              style={{ cursor: "pointer" }}
                            >
                              name
                            </h2>
                            <p></p>
                            <p className="elastic-para ">
                              If you don't see a dialogue, click launch. By
                              joining a meeting, you agree to overcome privacy.
                              This is the front page of the simple English
                              Wikipedia. Wikipedias are places where people work
                              together to write encyclopedias in different
                              languages. We use English Simple English words and
                              grammar. Here. The simple English Wikipedia is for
                              everyone. That includes children and adults who
                              are learning English. There are 223660 articles on
                              the Simple English. All of the pages are free to
                              use. They have all been published under both the
                              Creativity Common Creative Commons Attributions
                              share alike license. You can help here. You may
                              change this pages and make new pages with the help
                              pages and other good pages to learn how to write
                              pages. If you need help, you may ask questions at
                              simple. When writing commander was an American
                              entertainer. He was born in New Inspire. He was
                              Mitigate in 1861. He was totally new in.
                            </p>

                            <p className="elastic-para">
                              Paragraphs are commonly numbered using the decimal
                              system, where (in books) the integral part of the
                              decimal represents the number of the chapter and
                              the fractional parts are arranged in each chapter
                              in order of magnitude. Thus in Whittaker and
                              Watson's 1921 A Course of Modern Analysis, chapter
                              9 is devoted to Fourier Series; within that
                              chapter §9.6 introduces Riemann's theory, the
                              following section §9.61 treats an associated
                              function, following §9.62 some properties of that
                              function, following §9.621 a related lemma, while
                              §9.63 introduces Riemann's main theorem, and so
                              on. Whittaker and Watson attribute this system of
                              numbering to Giuseppe Peano on their "Contents"
                              page, although this attribution does not seem to
                              be widely credited elsewhere.[6] Gradshteyn and
                              Ryzhik is another book using this scheme since its
                              third edition in 1951.
                            </p>

                            <p className="elastic-para">
                              Paragraphs are commonly numbered using the decimal
                              system, where (in books) the integral part of the
                              decimal represents the number of the chapter and
                              the fractional parts are arranged in each chapter
                              in order of magnitude. Thus in Whittaker and
                              Watson's 1921 A Course of Modern Analysis, chapter
                              9 is devoted to Fourier Series; within that
                              chapter §9.6 introduces Riemann's theory, the
                              following section §9.61 treats an associated
                              function, following §9.62 some properties of that
                              function, following §9.621 a related lemma, while
                              §9.63 introduces Riemann's main theorem, and so
                              on. Whittaker and Watson attribute this system of
                              numbering to Giuseppe Peano on their "Contents"
                              page, although this attribution does not seem to
                              be widely credited elsewhere.[6] Gradshteyn and
                              Ryzhik is another book using this scheme since its
                              third edition in 1951.
                            </p>
                          </li>
                        </ScrollToBottom>
                      </ul>
                    </>

                    <div />
                    <div className="messageEditorWrapper2">
                      <div className="form-group">
                        <div className="main3">
                          <AudioPlayer
                            autoPlay
                            src="http://example.com/audio.mp3"
                            onPlay={(e) => console.log("onPlay")}
                            // other props here
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* ) : (
                    <></>
                  )} */}
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>

      <div
        className="modal fade addmodal cm-modal-all"
        id="shareModal2"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel"></h5>
              <button
                id="closeContentModal"
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div>
                {/* <div className="modalWrapper">
                  <div className="linkWrapper">
                    <div className="linkChild">
                      <span className="linkTitle">Shareable link</span>
                    </div>
                    <div className="copyLink">
                      <CopyToClipboard copyText="https://www.evernote.com/shard/s620/sh/53364b71-095d-5d96-eae4-024eb45186cc/190416813732a37ee359c6cf6d40ba90" />
                    </div>
                  </div>
                  <div role="link" tabIndex="0">
                    <input
                      className="shareableLink"
                      type="text"
                      readOnly
                      value="https://www.evernote.com/shard/s620/sh/53364b71-095d-5d96-eae4-024eb45186cc/190416813732a37ee359c6cf6d40ba90"
                    />
                  </div>
                  <div style={{ marginTop: "5px", fontSize: "14px" }}>
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="me-2"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M8.07 11.373a3.483 3.483 0 11.002-6.967 3.483 3.483 0 01-.001 6.967zm0-8.707c-3.243 0-5.962 2.221-6.736 5.224.774 3.003 3.493 5.224 6.737 5.224s5.962-2.221 6.736-5.224c-.774-3.003-3.492-5.224-6.736-5.224zM9.813 7.89a1.741 1.741 0 11-3.482 0 1.741 1.741 0 013.482 0z"
                        fill="#00A82D"
                      ></path>
                    </svg>
                    Anyone with the link can <strong>view</strong>
                  </div>
                </div> */}

                <div className="mt-4 inviteSection text-left">
                  <div className="inviteTitle text-start">Invite someone</div>
                  <InputGroup className="mb-3 inputGroupWrppr">
                    <FormControl
                      placeholder="Enter email"
                      aria-label="Enter email"
                      aria-describedby="basic-addon2"
                      className="inputGroupInput"
                      // onChange={(event) => handleChange(event)}
                    />
                    <InputGroup.Text
                      id="basic-addon2"
                      className="inputGroups"
                      style={{ backgroud: "#fff" }}
                    >
                      <Dropdown>
                        <Dropdown.Toggle
                          className="dropdownBtn"
                          id="dropdown-basic"
                          style={{
                            backgroundColor: "transparent",
                            border: "none",
                            color: "#000",
                          }}
                        >
                          Can edit and invite
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item
                            href="#/action-1"
                            style={{
                              backgroundColor: "transparent",
                              border: "none",
                              color: "#000",
                            }}
                          >
                            Can edit and invite
                          </Dropdown.Item>
                          <Dropdown.Item href="#/action-2">
                            Can edit
                          </Dropdown.Item>
                          <Dropdown.Item href="#/action-3">
                            Can view
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </InputGroup.Text>
                  </InputGroup>
                </div>

                {/* {show ? ( */}
                <div
                  className="d-flex justify-content-end"
                  style={{ border: "none" }}
                >
                  <button
                    type="button"
                    className="btn btn-secondary"
                    style={{ marginRight: "1rem" }}
                    //   onClick={() => {
                    //     setEmail("");
                    //   }}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    style={{ backgroundColor: "#5746ec", border: "none" }}
                    //   onClick={makeUpdate}
                  >
                    Submit
                  </button>
                </div>
                {/* ) : (
                  ""
                )} */}

                <div>
                  <div className="sharedInfoWrapper">
                    <div className="sharedInfoChild">
                      <div className="sharedInfoProfile">
                        <div
                          className="shareWrppr text-start"
                          id="qa-USER_LIST_ROW_0"
                        >
                          <div className="emailSection">
                            <div className="profileIconWrppr text-left">
                              <div
                                className="profileIcon"
                                style={{
                                  backgroundImage:
                                    "url(https://ui-avatars.com/api/?background=0D8ABC&color=fff&name=Dharmendra+Yadav)",
                                }}
                              ></div>
                              <div className="ms-3">
                                <div
                                  id="qa-USER_LIST_USER_NAME"
                                  className="nameInfo"
                                >
                                  <span className="">
                                    Dharmendra Yadav (you)
                                  </span>
                                </div>
                                <div
                                  className="emailInfo"
                                  id="qa-USER_LIST_EMAIL"
                                >
                                  <span className="">
                                    dharmendra.yadav@techrefic.com
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="Yourr_9w71MjtL3S9I4G">
                            <div className="">
                              <Dropdown>
                                <Dropdown.Toggle
                                  className="dropdownBtn"
                                  id="dropdown-basic"
                                  style={{
                                    backgroundColor: "transparent",
                                    border: "none",
                                    color: "#000",
                                  }}
                                >
                                  Can edit and invite
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                  <Dropdown.Item href="#/action-1">
                                    Can edit and invite
                                  </Dropdown.Item>
                                  <Dropdown.Item href="#/action-2">
                                    Can edit
                                  </Dropdown.Item>
                                  <Dropdown.Item href="#/action-3">
                                    Can view
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </div>
                        </div>
                        <div
                          className="shareWrppr text-start"
                          id="qa-USER_LIST_ROW_0"
                        >
                          <div className="emailSection">
                            <div className="profileIconWrppr">
                              <div
                                className="profileIcon"
                                style={{
                                  backgroundImage:
                                    "url(https://ui-avatars.com/api/?background=0D8ABC&color=fff&name=Manas+Kumar)",
                                }}
                              ></div>
                              <div className="ms-3">
                                <div
                                  id="qa-USER_LIST_USER_NAME"
                                  className="nameInfo"
                                >
                                  <span className="">Manas Kumar</span>
                                </div>
                                <div
                                  className="emailInfo"
                                  id="qa-USER_LIST_EMAIL"
                                >
                                  <span className="">
                                    manas.kumar@techrefic.in
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="Yourr_9w71MjtL3S9I4G">
                            <div className="">
                              <Dropdown>
                                <Dropdown.Toggle
                                  className="dropdownBtn"
                                  id="dropdown-basic"
                                  style={{
                                    backgroundColor: "transparent",
                                    border: "none",
                                    color: "#000",
                                  }}
                                >
                                  Can edit and invite
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                  <Dropdown.Item href="#/action-1">
                                    Can edit and invite
                                  </Dropdown.Item>
                                  <Dropdown.Item href="#/action-2">
                                    Can edit
                                  </Dropdown.Item>
                                  <Dropdown.Item href="#/action-3">
                                    Can view
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="ZP9BDnxHhGA9rSLoFsa3">
                  <button
                    id="qa-SHARE_DIALOG_STOP"
                    className="cDCnsp1vcViX1pVJM7MV"
                    type="button"
                    tabIndex="0"
                  >
                    Stop sharing
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TranscriptDetail;
