import Aos from "aos";
import "aos/dist/aos.css";
import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ExpiredPage from "../components/ExpiredPage";
import API_PATH from "../Constants/api-path";
import ResetPasswordForm from "./ResetPasswordForm";

function ResetPasssword() {
  Aos.init({
    offset: 100,
    easing: "ease",
    delay: 0,
    once: true,
    duration: 800,
  });
  const [isExpired, setExpired] = useState(false);
  const [isLinkUsed, setIsLinkUsed] = useState(false);
  let history = useNavigate();

  useEffect(() => {
    getExpiredTime();
    if (isExpired) {
      history("/expire");
    }
  }, [isExpired]);


  const getExpiredTime = async () => {
    try {
      let d = new Date();
      const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
      });
      const res = await fetch(`${API_PATH.EXPIRE_TIME}`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          email: atob(params.email),
        }),
      });

      const result = await res.json();
      console.log("result", result);
      if (result.data) {
        let expiredDate = result.data;
        console.log(result, "result");
        if (expiredDate < d.getTime()) {
          setExpired(true);
        }
      }
      else if(result.data === 0) {
        setExpired(true);
      }
    } catch (error) {
      console.log(error);
      return toast.error("something went wrong server error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
  };

  document.title = "Client | Login";
  return (
    <>
      {isExpired ? (
        <ExpiredPage />
      ) : (
        <div className="login-page">
          <div className="container-fluid">
            <div className="row row-cols-1 row-cols-lg-2">
              <div className="col d-none d-md-block">
                <div className="login-left-part">
                  <NavLink to="/" className="logo">
                    <img src="images/logo-login.png" alt="logo" />
                  </NavLink>
                  <div className="logo-in-div col-lg-7 mx-auto">
                    <h1 className="text-left text-white" data-aos="fade-down">
                      Consulting experts is <br /> as easy as a click of a
                      button.
                    </h1>
                  </div>

                  <div className="col-lg-7 mx-auto">
                    <div className="img-box-1">
                      <img src="images/Login.gif"></img>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col position-relative">
                <div className="comon-fild col-lg-8 login-page-div">
                  <div className="inside-login">
                    <NavLink to="/" className="logo d-block d-lg-none mb-5">
                      <img src="images/logo-main.svg" alt="logo" />
                    </NavLink>
                    <h4> Reset Password? 🔒 </h4>
                    <p className="mb-2" style={{ color: "#5746ec" }}>
                      Your new password must be different from previously used
                      passwords
                    </p>
                    <ResetPasswordForm />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
export default ResetPasssword;
