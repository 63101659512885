import Button from "@mui/material/Button";
import { Comment, Form, Input, List } from "antd";
import React, { useState } from "react";
import { DocumentViewer } from "react-documents";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import ReactPlayer from "react-player";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Spinner } from "reactstrap";
import no_result from "../assests/images/Startled-bro.svg";
import API_PATH from "../Constants/api-path";
import MessageEditor from "../messageEditor";

const { TextArea } = Input;


const config = {
  readonly: false,
  height: "200px",
  width: "100%",
  enableDragAndDropFileToEditor: true,
  buttons: [
    "source",
    "|",
    "bold",
    "italic",
    "underline",
    "|",
    "ul",
    "ol",
    "|",
    "font",
    "fontsize",
    "brush",
    "paragraph",
    "|",
    "image",
    "table",
    "link",
    "|",
    "left",
    "center",
    "right",
    "justify",
    "|",
    "undo",
    "redo",
    "|",
    "hr",
    "eraser",
    "fullsize",
  ],
  uploader: { insertImageAsBase64URI: false },
  removeButtons: [
    "brush",
    "file",
    "hr",
    "font",
    "fontsize",
    "brush",
    "image",
    "undo",
    "redo",
    "link",
    "fullsize",
    "table",
    "undo",
    "redo",
    "back",
    "paint",
    "eraser",
    "paragraph",
    "source",
    "|",
    "justify",
  ],
  showXPathInStatusbar: false,
  showCharsCounter: false,
  showWordsCounter: true,
  toolbarAdaptive: false,
  toolbarSticky: false,
  toolbarButtonSize: "middle",
};

const CommentList = ({ comments }) => (
  <List
    dataSource={comments}
    header={`${comments.length} ${comments.length > 1 ? "replies" : "reply"}`}
    itemLayout="horizontal"
    renderItem={(props) => <Comment {...props} />}
  />
);

const Editor = ({ onChange, onSubmit, submitting, value }) => (
  <>
    <Form.Item>
      <TextArea rows={4} onChange={onChange} value={value} />
    </Form.Item>
    <Form.Item>
      <button
        type="submit"
        className="btn col-lg-5 btnsm"
        style={{ padding: "10px 10px" }}
      >
        Add Comment
      </button>
    </Form.Item>
  </>
);

const RecentViewDetails = ({ datas }) => {
  const userData = JSON.parse(localStorage.getItem("userData"));
  const [newCommentValue, setnewCommentValue] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [spinner, setSpinner] = useState(false);
  
  // console.log(datas);

  // const handleSubmit = () => {
  //   if (!value) return;
  //   setSubmitting(true);
  //   setTimeout(() => {
  //     setSubmitting(false);
  //     setValue("");
  //     setComments([
  //       ...comments,
  //       {
  //         author: "Han Solo",
  //         avatar: "https://joeschmoe.io/api/v1/random",
  //         content: <p>{value}</p>,
  //         datetime: moment().fromNow(),
  //       },
  //     ]);
  //   }, 1000);
  // };

  // const handleChange = (e) => {
  //   setValue(e.target.value);
  // };

  document.addEventListener("contextmenu", function (e) {
    e.preventDefault();
  });

  function Calculate_days_hours(date1, date2) {
    var diff = date2.getTime() - date1.getTime();
    var days = Math.floor(diff / (1000 * 60 * 60 * 24));
    var hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    var minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((diff % (1000 * 60)) / 1000);
    ///return { days, hours, minutes, seconds };
    if (days !== 0) {
      return days + " " + "days ago ";
    } else if (hours !== 0) {
      return hours + " " + "hours ago";
    } else if (minutes !== 0) {
      return minutes + " " + "min. ago";
    } else if (seconds < 60) {
      return "Less than a minute";
    }
  }


  function RemoveHtml(myString) {
    if (myString) {
      return myString.replace(/(<([^>]+)>)/gi, "");
    } else {
      return "Empty message";
    }
  }

  const getComments = (comments) => {
    if (comments instanceof Array) {
      return comments.map(function (object, i) {
        const { commnt, createdAt, userName } = object;
        return (
          <>
            <div className="commentBody">
              <div className="d-flex align-items-center">
                <img
                  draggable="false"
                  alt="ui-avatar"
                  src={"https://ui-avatars.com/api/?size=60&&font-size=.38&color=%23f33636&background=%23EFEFEF&length=2&name=" + userName}
                  size="60"
                  className=" css-ibnyz4 userImage"
                />
                <h6
                  className="mb-0 mt-0"
                  style={{ fontWeight: "600", marginLeft: "15px" }}
                >
                  {userName}
                </h6>
                <p
                  className="mb-0"
                  style={{ color: "grey", marginLeft: "15px" }}
                >
                  {Calculate_days_hours(new Date(createdAt), new Date(Date.now()))}
                </p>
              </div>
              <div className="mt-2 mb-4">
                <p className="mb-0">
                  {RemoveHtml(commnt)}
                </p>
              </div>
            </div>
          </>
        );
      });
    }

  }


  const addComment = async (e) => {
    e.preventDefault()
    if (!newCommentValue) {
      return toast.warning("All fields are Mandatory", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
    let payload = {};
    payload.comments = [
      ...datas.comments,
      {
        userId: userData.user._id,
        commnt: newCommentValue,
        createdAt: Date.now(),
        userName: userData.user.username,
        likeCount: 0,
        disLikeCount: 0,
      },
    ];
    getComments(payload.comments)
    //console.log(payload);
    if (searchParams.get("id")) {
      setSpinner(true);
      try {
        const res = await fetch(`${API_PATH.SLEEVE_UPDATE}/${searchParams.get("id")}`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            "x-access-token": userData.access_token,
          },
          body: JSON.stringify(payload),
        });
        const result = await res.json();
        if (result) {
          // console.log(result);
          setSpinner(false);
          setnewCommentValue("");
        } else {
          return toast.error("something went wrong server error", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
        }
      } catch (error) {
        return toast.error("something went wrong server error", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    }
  };


  const setFiles = (data, publicUrl) => {
    // console.log(data);
    if (data !== null && data !== undefined) {
      let str = data.split("/");
      // console.log(str[0]);
      if (str[0] === "audio") {
        return (
          <div>
            <AudioPlayer
              src={publicUrl}
              onPlay={(e) => console.log("onPlay")}
            // other props here
            // style={{ width: "150%" }}
            />
          </div>
        );
      } else if (str[0] === "video") {
        return (
          <ReactPlayer
            className="video-player"
            style={{
              height: "calc(100vh - 200px)",
              margin: "auto",
              width: "100%",
            }}
            controls={true}
            url={publicUrl}
            config={{ file: { attributes: { controlsList: "nodownload" } } }}
          />
        );
      } else if (
        str[0] === "doc" ||
        str[0] === "docx" ||
        str[0] === "pdf" ||
        str[0] === "xls" ||
        str[0] === "xlsx" ||
        str[0] === "ppt" ||
        str[0] === "pptx" ||
        str[0] === "txt" ||
        str[0] === "csv" ||
        str[0] === "system"
      ) {
        return (
          <>
            <DocumentViewer
              queryParams="hl=Nl"
              url={publicUrl}
              style={{ height: "80vh", width: "100%" }}
              googleCheckContentLoaded
            ></DocumentViewer>
          </>
        );
      } else if (str[0] === "image") {
        return (
          <img
            src={publicUrl}
            alt="No Image"
            style={{
              height: "calc(100vh - 200px)",
              margin: "auto",
              width: "100%",
            }}
          />
        );
      }
    } else {
      return "";
    }
  };
  if (datas.sleeveType) {
    return (
      <>
        <div className="row">
          <div className="col-md-12">
            {setFiles(datas.sleeveType, datas.publicUrl)}
          </div>
        </div>
        {
          datas?.comments?.length > 0 ? (
            <>
              <div className="row">
                <div className="col-12">
                  <h5 className="commentTitle">
                    <span
                      style={{
                        borderBottom: "2px solid darkgrey",
                        paddingBottom: "8px",
                      }}
                    >
                      Comments{" "}
                      <span
                        style={{
                          fontSize: "12px",
                          background: "#ededf0",
                          padding: "5px 6px",
                          borderRadius: "5px",
                        }}
                      >
                        {datas?.comments?.length}
                      </span>
                    </span>
                  </h5>
                  <div className="bg-white mt-5 p-4 stylishEditor">
                    {getComments(datas.comments)}
                  </div>

                  <div className="col-12 mt-4">
                    <div
                      style={{
                        backgroundColor: "#fff",
                        position: "relative",
                      }}
                    >
                      <div className="form-group">
                        <div
                          className="createNotesEditor commentSection"
                          style={{ border: "none" }}
                        >
                          <textarea
                            type="text"
                            className="form-control d-none"
                            placeholder="Project brief"
                            // onChange={handleNotesInputs}
                            id="message_insert"
                            value={newCommentValue}
                          ></textarea>

                          <MessageEditor
                            setValue={setnewCommentValue}
                            value={newCommentValue}
                            config={config}
                          />
                        </div>
                      </div>{" "}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 mt-2">
                  <Button
                    className="btn col-lg-5 mx-auto btnsm"
                    style={{ textTransform: "capitalize" }}
                    onClick={addComment}
                    id="editContentdisabled1"
                  >
                    {spinner ? (
                      <>
                        <Spinner
                          size="sm"
                          style={{
                            width: "1rem",
                            height: "1rem",
                            marginRight: "5px",
                          }}
                        />
                        Add Comment
                      </>
                    ) : (
                      " Add Comment"
                    )}
                  </Button>
                </div>
              </div>
            </>

          ) : ('')
        }
      </>
    );
  } else {
    return (
      <>
        <div className="text-center noResultFound">
          <div className="container">
            <div className="row d-flex justify-content-center align-items-center">
              <div className="col-md-6">
                <img
                  src={no_result}
                  alt="srv"
                  style={{ height: "400px", marginTop: "50px" }}
                ></img>
              </div>
              <div className="col-md-6">
                <h3
                  style={{
                    fontSize: "18px",
                    textAlign: "left",
                    fontWeight: "300",
                    lineHeight: "26px",
                  }}
                >
                  <span style={{ fontWeight: "500" }}>Oops,</span> No result
                  found
                </h3>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="text-center noResultFound">
          <img src={no_result} />
          <h3>No result found </h3>
        </div> */}
      </>
    );
  }
};

export default RecentViewDetails;
