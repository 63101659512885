import { Tooltip, Tag } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Dropdown, FormControl, InputGroup, Spinner } from "react-bootstrap";
import {
  createSearchParams,
  Link,
  NavLink,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { toast } from "react-toastify";
import company_icon3 from "../assests/images/company_icons3.png";
import StarredButton from "../components/StarredButton";
import API_PATH from "../Constants/api-path";
import MobileMenu from "../MobileMenu";
import Sidebar from "../Sidebar";
import { CurrencyIcon } from "../Utils/CurrencyIcon";
import { CalculateExpertRate } from "../Constants/CalculateExpertRate";
import { BsChevronLeft } from "../Utils/Icons";
import "./ExpertProfile.css";
import { Col, Row } from "react-bootstrap";

const text = <span>Industry</span>;
const text2 = <span>Tags</span>;
const customColors = ["#5746ec"];
var ExpertId = "";

function ExpertProfile() {
  document.title = "Expert Cloud -  Expert Profile";

  const userData = JSON.parse(localStorage.getItem("userData"));
  const [ModalToggle, setModalToggle] = useState(false);
  const history = useNavigate();
  const handleClose1 = () => setModalToggle(false);

  document.title = "Expert Cloud -  Expert Profile";
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  const [Loading, setLoading] = useState(false);
  const [expertDetails, setExpertDetails] = useState({});
  const [educationDetails, setEducationDetails] = useState([]);
  const [experienceDetails, setexperienceDetails] = useState([]);
  const [payload, setPayload] = useState({
    email: "",
    permission: "view",
  });
  const [userEmail, setEmail] = useState("");
  // console.log("userData", userData);
  const [show, setShow] = useState(false);
  const [workData, setworkData] = useState({});

  const handleChange = (event) => {
    setPayload({ ...payload, [event.target.name]: event.target.value });
    if (event.target.value.length > 0) setShow(true);
    else setShow(false);
  };

  const handleRequestInputs = (e) => {
    const key = e.target.name;
    const value = e.target.value;

    setEmail(value);
  };

  const getData = (data) => {
    //console.log(data);
    setworkData({ ...data });
    //console.log(workData);
  };

  const modalClose = () => {
    setEmail("");
  };

  const makeUpdate = async () => {
    if (userEmail !== "") {
      try {
        const res = await fetch(
          `${API_PATH.SHARE_EXPERT}/${workData?._id}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              "x-access-token": userData.access_token,
            },
            body: JSON.stringify({
              permission: payload.permission,
              email: payload.email,
            }),
          }
        );

        const result = await res.json();
        if (result) {
          if (result.status === 200) {
            getExpertDetails(workData?._id);
            setEmail("");
            return toast.success(result.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
            });
          } else {
            return toast.error(result.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
            });
          }
        } else {
          return toast.error("something went wrong server error", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
        }
      } catch (error) {
        return toast.error("something went wrong server error", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    }
  };

  const stopSharing = async (e) => {
    e.preventDefault();
    try {
      const res = await fetch(
        `${API_PATH.SHARE_EXPERT}/${workData?._id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            "x-access-token": userData.access_token,
          },
          body: JSON.stringify({ permittedUsers: [] }),
        }
      );

      const result = await res.json();
      if (result) {
        getExpertDetails(workData?._id);
        return toast.success("User removed successfully!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      } else {
        return toast.error("something went wrong server error", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    } catch (error) {
      return toast.error("something went wrong server error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
  };

  const getExpertDetails = async (expertId) => {
    // console.log(expertId, "expertId");
    try {
      const res = await fetch(
        `${API_PATH.GETSINGLE_EXPERT}/${expertId}/${userData.user._id}`,
        {
          method: "GET",
          headers: headers,
        }
      );

      const result = await res.json();
      if (result) {
        // console.log(result.data, "resultss");
        setExpertDetails(result.data);
      } else {
        return toast.error("something went wrong server error", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    } catch (error) {
      return toast.error("something went wrong server error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
  };

  const getDefaultText = (permission) => {
    if (permission === "editi") return "Can edit and invite";
    if (permission === "edit") return "Can edit";
    if (permission === "view") return "Can view";
  };

  const getPUserList = () => {
    if (expertDetails) {
      if (expertDetails.permittedUsers instanceof Array) {
        return expertDetails.permittedUsers.map(function (object, i) {
          const { _id, firstNmae, email, permission, username, expert_id } =
            object;
          return (
            <>
              <div className="shareWrppr text-start" id={_id}>
                <div className="emailSection">
                  <div className="profileIconWrppr">
                    <div className="avatarImg">
                      <img
                        width="45px"
                        src={
                          "https://ui-avatars.com/api/?&rounded=true&font-size=0.38&background=EFEFEF&color=f33636&name=" +
                          email
                        }
                      />
                    </div>

                    {/* <div
                    className="profileIcon"
                    style={{
                      backgroundImage: `url(https://ui-avatars.com/api/?background=0D8ABC&color=fff&name=${email})`,
                    }}
                  ></div> */}
                    <div className="ms-3">
                      <div id="qa-USER_LIST_USER_NAME" className="nameInfo">
                        <span className="">
                          {firstNmae ? firstNmae : username}
                        </span>
                      </div>
                      <div className="emailInfo" id="qa-USER_LIST_EMAIL">
                        <span className="">{email}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="Yourr_9w71MjtL3S9I4G">
                  <div className="">
                    <Dropdown>
                      <Dropdown.Toggle
                        className="dropdownBtn"
                        id="dropdown-basic"
                        style={{
                          background: "none",
                          border: "none",
                          color: "#000000",
                        }}
                      >
                        {getDefaultText(permission)}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item
                          href="#/action-3"
                          onClick={() =>
                            updatePermission("view", email, expert_id)
                          }
                        >
                          Can view
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              </div>
            </>
          );
        });
      }
    }
  };

  const updatePermission = async (permission, email, expert_id) => {
    try {
      const res = await fetch(`${API_PATH.SHARE_EXPERT}/${expert_id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": userData.access_token,
        },
        body: JSON.stringify({ permission: permission, email: email }),
      });

      const result = await res.json();
      if (result) {
        getExpertDetails(expert_id);
      } else {
        return toast.error("something went wrong server error", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    } catch (error) {
      return toast.error("something went wrong server error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
  };

  let query = useQuery();
  let expert_id = query.get("expertId");
  console.log(expert_id, "expert_id");
  const headers = {
    "x-access-token": userData.access_token,
  };

  const handelSubmit = async (data) => {
    // console.log(data);
    let payload = {
      currentDesignation: data[1].designation || "",
      currentCompany: data[1].company_name || "",
      country: data.country || "",
      expertName: data.full_Name,
      expertRate: CalculateExpertRate(data.expertRate),
      expert_id: data.expert_id,
      clientName: userData.user.username,
      clientCompany: userData.user.company_id.name,
      clientCountry: userData.user.country,
      clientContactNumber: userData.user.contactNumber,
    };

    // console.log(payload);

    try {
      const res = await fetch(`${API_PATH.ONE_EXPERT}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
      const result = await res.json();
      if (result) {
        return toast.success(result.msg, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      } else {
        return toast.error("something went wrong server error", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    } catch (error) {
      return toast.error("something went wrong server error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
  };

  // console.log(expertDetails, "expertDetailsss");

  useEffect(() => {
    axios
      .get(`${API_PATH.GETSINGLE_EXPERT}/${expert_id}/${userData.user._id}`, {
        headers,
      })
      .then((res) => {
        console.log("2222222222222222222222", res.data);
        setExpertDetails(res.data.data);
        setEducationDetails(res.data.education);
        setexperienceDetails(res.data.experience);
        // console.log("11111111111111111111");
        setLoading(true);
      })
      .catch((err) => {
        // console.log(err);
      });
  }, []);

  const goToFcPage = (data) => {
    history({
      pathname: "/fc",
      search: `?${createSearchParams({
        expertId: data?._id ? data?._id : data?._id,
      })}`,
    });
    return toast.success("Please select a time slot", {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 3000,
    });
  };

  if (Loading) {
    // console.log("dsdsdsssssssssssssssss");
    var expertRate = expertDetails?.hourly_rate_call_cunsltn;
    var experience = [];
    for (var i = 0; i < experienceDetails?.length; i++) {
      experience?.push(experienceDetails[i]);
    }

    // console.log("experience", experience);

    var CN = [];
    function uniqueData(data) {
      var companyName = [];
      for (var i = 0; i < data.length; i++) {
        if (companyName.indexOf(data[i].company_name) == -1) {
          companyName.push(data[i].company_name);
        }
      }
      CN.push(...companyName);
      return companyName;
    }
    uniqueData(experience);
    var store = [];

    for (let i = 0; i < CN.length; i++) {
      let local = [];
      let print = [];
      for (let j = 0; j < experience.length; j++) {
        if (CN[i] == experience[j].company_name) {
          let obj = {};
          obj.title = experience[j].company_name;
          obj.dateFrom = experience[j].date_from;
          obj.dateTill = experience[j].date_till;
          obj.whatWork = experience[j].what_work;
          obj.location = experience[j].location;
          obj.tillPresent = experience[j].till_present;
          obj.logoUrl = experience[j].logo_url;
          obj.designation = experience[j].career_title;
          local.push(obj);
        }
      }
      print.push(CN[i], local);
      store.push(print);
    }

    // console.log("store", store);
  }

  const ConstantsArray = (array) => {
    var type = typeof array;
    let newArray = array;
    if (type == "string") {
      newArray = array?.split(",");
    }
    return newArray;
  };

  function getMonthDifference(startDate, endDate) {
    return (
      endDate.getMonth() -
      startDate.getMonth() +
      12 * (endDate.getFullYear() - startDate.getFullYear())
    );
  }

  const Convert_Month_Year = (months) => {
    var month = months + 1;

    var year = Math.floor(month / 12);
    //console.log(year);
    if (year === 0) {
      var month_year = (month % 12) + " mos";
      return month_year;
    } else if (month % 12 === 0) {
      var month_year = year + " yrs ";
      return month_year;
    } else {
      var month_year = year + " yrs " + (month % 12) + " mos";
      return month_year;
    }
  };

  function expertSummery(data) {
    // console.log(data, "aboutdata");
    if (data !== null && data !== "" && data !== undefined) {
      // console.log("data", data);
      return (
        <div
          className="comon-divs-re"
          style={{ width: "100%", paddingRight: "20px" }}
        >
          <div className="pvs-header__title-container">
            <h2 className="pvs-header__title text-heading-large">
              <span
                aria-hidden="true"
                style={{
                  fontSize: "20px",
                  fontWeight: "400",
                }}
              >
                About
              </span>
            </h2>
          </div>

          <div className="inline-show-more-text">
            <span
              aria-hidden="true"
              data-name="background_summery"
              style={{ fontSize: "14px" }}
            >
              {data}
            </span>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }

  const addDefaultSrc = (ev) => {
    ev.target.src = company_icon3;
  };
  //console.log(Months_Years("Apr 2015", "march 2016"));

  if (!Loading) {
    return (
      <div style={{ textAlign: "center" }}>
        <Spinner animation="border" />
      </div>
    );
  } else {
    return (
      <>
        <>
          <MobileMenu />
          <div className="page-wrapper chiller-theme toggled">
            <div className="main-sec d-lg-flex">
              <div className="sidebar-menu" id="navAccordion">
                <Sidebar />
              </div>
            </div>
            <main className="body-total content-wrapper float-start w-100">
              <div className="container-fluid">
                {/* <div className="d-block d-lg-block">
                  <div className="top-bar-menu float-start">
                    <TopMenu />
                  </div>
                </div> */}

                <div className="bk-div float-start w-100">
                  <div className="body-expart-div request-page projects-scope">
                    <h4 className="d-flex align-items-center">
                      <Link to={"#"} onClick={() => history(-1)}>
                        <BsChevronLeft className="me-3 search-btn-back" />
                      </Link>
                      Expert Profile
                    </h4>
                    {/* <div className="d-md-flex top-ps-seection justify-content-between align-items-center">
                      <NavLink
                        to="#"
                        className="mb-0 btn search-btn-back text-left"
                        onClick={() => history(-1)}
                        style={{ textAlign: "left" }}
                      >
                        {" "}
                        <BsChevronLeft /> Expert Profile
                      </NavLink>
                    </div> */}

                    <div className="mt-4">
                      <section className="content">
                        <div className="findExperts">
                          <div className="linkedinBody">
                            <div className="comon-divs-re d-md-flex align-items-center justify-content-between findExperts">
                              <div className="col-lg-9">
                                {store?.length > 0 ? (
                                  <>
                                    <div className="d-flex align-items-center mb-4">
                                      <span style={{ minWidth: "95px" }}>
                                        {store[0][1][0].logoUrl !== null &&
                                          store[0][1][0].logoUrl !== "" ? (
                                          <img
                                            src={store[0][1][0].logoUrl}
                                            alt="logo"
                                            className="me-2"
                                            style={{
                                              width: "95px",
                                              borderRadius: "5px",
                                              maxWidth: "95px",
                                            }}
                                            onError={addDefaultSrc}
                                          />
                                        ) : (
                                          <img
                                            src={company_icon3}
                                            alt="logo1"
                                            className="me-2"
                                            style={{
                                              width: "95px",
                                              borderRadius: "5px",
                                            }}
                                          />
                                        )}
                                      </span>
                                      <div className="ms-2">
                                        <h4
                                          className="find_companyName"
                                          style={{ marginBottom: "10px" }}
                                        >
                                          {store[0][0]}
                                        </h4>
                                        <h5 className="linkH5">
                                          {store[0][1][0]?.designation !==
                                            null ? (
                                            <>{store[0][1][0]?.designation}</>
                                          ) : (
                                            <></>
                                          )}
                                        </h5>
                                        <p
                                          className="mb-0"
                                          style={{
                                            fontSize: "13px",
                                            color: "gray",
                                          }}
                                        >
                                          <i className="las la-globe-americas me-2" style={{ color: "#5746ec" }}></i>
                                          {expertDetails?.country}
                                        </p>
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  <></>
                                )}

                                <Row className="mt-2">
                                  <Col>
                                    {expertDetails?.industry !== null &&
                                      expertDetails?.industry !== undefined ? (
                                      <div className="d-flex next-list mt-3 align-items-top">
                                        <span className="me-2">
                                          {customColors?.map((color) => (
                                            <Tooltip
                                              placement="top"
                                              color={color}
                                              title={text}
                                            >
                                              <svg
                                                style={{
                                                  fontSize: "20px",
                                                  color: "rgb(87, 70, 236)",
                                                }}
                                                stroke="currentColor"
                                                fill="currentColor"
                                                stroke-width="0"
                                                viewBox="0 0 24 24"
                                                height="1em"
                                                width="1em"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <g>
                                                  <path
                                                    fill="none"
                                                    d="M0 0h24v24H0z"
                                                  ></path>
                                                  <path d="M10 10.111V1l11 6v14H3V7l7 3.111zm2-5.742v8.82l-7-3.111V19h14V8.187L12 4.37z"></path>
                                                </g>
                                              </svg>
                                            </Tooltip>
                                          ))}
                                        </span>

                                        <div>
                                          {ConstantsArray(
                                            expertDetails?.industry
                                          ).map((curElem, id) => {
                                            return (

                                              <Tag
                                                color="blue"
                                                bordered={false}
                                                key={id}
                                              >
                                                {curElem}
                                              </Tag>

                                            );
                                          })}
                                        </div>
                                      </div>
                                    ) : (
                                      <></>
                                    )}
                                  </Col>
                                </Row>

                                <Row className="mt-2">
                                  <Col>
                                    {expertDetails?.tags !== null &&
                                      expertDetails?.tags !== undefined ? (
                                      <div className="d-flex next-list align-items-top">
                                        <span className="me-2">
                                          {customColors.map((color) => (
                                            <Tooltip
                                              placement="top"
                                              color={color}
                                              title={text2}
                                            >
                                              <svg
                                                style={{
                                                  fontSize: "20px",
                                                  color: "rgb(87, 70, 236)",
                                                }}
                                                stroke="currentColor"
                                                fill="none"
                                                stroke-width="1.5"
                                                viewBox="0 0 24 24"
                                                aria-hidden="true"
                                                height="1em"
                                                width="1em"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <path
                                                  stroke-linecap="round"
                                                  stroke-linejoin="round"
                                                  d="M13.5 21v-7.5a.75.75 0 01.75-.75h3a.75.75 0 01.75.75V21m-4.5 0H2.36m11.14 0H18m0 0h3.64m-1.39 0V9.349m-16.5 11.65V9.35m0 0a3.001 3.001 0 003.75-.615A2.993 2.993 0 009.75 9.75c.896 0 1.7-.393 2.25-1.016a2.993 2.993 0 002.25 1.016c.896 0 1.7-.393 2.25-1.016a3.001 3.001 0 003.75.614m-16.5 0a3.004 3.004 0 01-.621-4.72L4.318 3.44A1.5 1.5 0 015.378 3h13.243a1.5 1.5 0 011.06.44l1.19 1.189a3 3 0 01-.621 4.72m-13.5 8.65h3.75a.75.75 0 00.75-.75V13.5a.75.75 0 00-.75-.75H6.75a.75.75 0 00-.75.75v3.75c0 .415.336.75.75.75z"
                                                ></path>
                                              </svg>
                                            </Tooltip>
                                          ))}
                                        </span>

                                        <div>
                                          {ConstantsArray(expertDetails?.tags).map(
                                            (curElem, id) => {
                                              //console.log(curElem.length);
                                              //if (curElem.length > 1) {
                                              return (
                                                <Tag
                                                  color="volcano"
                                                  bordered={false}
                                                  key={id}
                                                >
                                                  {curElem}
                                                </Tag>
                                              );
                                            }
                                          )}
                                        </div>
                                      </div>
                                    ) : (
                                      <></>
                                    )}
                                  </Col>
                                </Row>
                              </div>

                              <div className="col-lg-2">
                                <div className="mt-5 mt-md-0 text-center pe-3 right-divs-2">
                                  <h5 className="expertRate">
                                    <CurrencyIcon
                                      currencyType={
                                        userData?.user?.company_id?.currency
                                      }
                                    />
                                    {userData?.user?.company_id?.expertRate ==
                                      0 ? (
                                      <CalculateExpertRate rate={expertRate} />
                                    ) : userData?.user?.company_id
                                      ?.expertRate ? (
                                      userData?.user?.company_id?.expertRate
                                    ) : (
                                      <CalculateExpertRate rate={expertRate} />
                                    )}
                                    <span> / hour </span>{" "}
                                  </h5>
                                  <div
                                    onClick={() => goToFcPage(expertDetails)}
                                  >
                                    <button className="btn new-reguest mt-4">
                                      Request
                                    </button>
                                  </div>
                                  <div
                                    className="d-flex justify-content-around mt-4 w-50 mx-auto"
                                    onClick={() => getData(expertDetails)}
                                  >
                                    <NavLink
                                      to="#"
                                      className="me-4"
                                      data-bs-toggle="modal"
                                      data-bs-target="#shareModal2"
                                      onClick={() => {
                                        getExpertDetails(
                                          expertDetails?._id
                                            ? expertDetails?._id
                                            : expertDetails?._id
                                        );
                                        return;
                                      }}
                                    >
                                      {" "}
                                      <img
                                        src="/images/share.png"
                                        width="16px"
                                        alt="png"
                                      />{" "}
                                    </NavLink>
                                    <StarredButton datas={expertDetails} />
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* {console.log(expertDetails.background_summery, "background_summery")} */}
                            {expertSummery(expertDetails?.background_summery)}

                            {/* <div> 
                              <h4 className="pvs-header__title text-heading-large">Expert rate</h4>
                            {userData?.user?.company_id?.expertRate ? (
                              userData?.user?.company_id?.expertRate
                            ) : (
                              <CalculateExpertRate
                                rate={
                                  expertRate !== null ? expertRate : 450
                                }
                              />
                            )}
                            <span> / hour </span>{" "}
                            </div> */}

                            {store.length > 0 ? (
                              <div
                                className="comon-divs-re"
                                style={{ width: "100%", paddingRight: "20px" }}
                              >
                                <div className="pvs-header__title-container">
                                  <h2 className="pvs-header__title text-heading-large">
                                    <span
                                      aria-hidden="true"
                                      style={{
                                        fontSize: "20px",
                                        fontWeight: "400",
                                      }}
                                    >
                                      Experience
                                    </span>
                                  </h2>
                                </div>
                                <div className="inline-show-more-text">
                                  {store.map((curElem, id) => {
                                    //console.log(curElem);
                                    // console.log(
                                    //   curElem[1][curElem[1].length - 1]
                                    //     .dateFrom
                                    // );

                                    return (
                                      <>
                                        <div className="companies" key={id}>
                                          <div className="companyLogo mb-4">
                                            {curElem[1][0].logoUrl !== null &&
                                              curElem[1][0].logoUrl !== "" ? (
                                              <img
                                                src={curElem[1][0].logoUrl}
                                                alt="logo"
                                                onError={addDefaultSrc}
                                              />
                                            ) : (
                                              <img
                                                src={company_icon3}
                                                alt="logo1"
                                              />
                                            )}

                                            <div style={{ marginLeft: "20px" }}>
                                              <h5
                                                className="companyName"
                                                style={{ fontSize: "18px" }}
                                              >
                                                {curElem[0]}
                                              </h5>
                                              {curElem[1][0].tillPresent ===
                                                "yes" ? (
                                                <p className="totalExperience">
                                                  {Convert_Month_Year(
                                                    getMonthDifference(
                                                      new Date(
                                                        curElem[1][
                                                          curElem[1].length - 1
                                                        ].dateFrom
                                                      ),
                                                      new Date(Date.now())
                                                    )
                                                  )}
                                                </p>
                                              ) : (
                                                <p className="totalExperience">
                                                  {Convert_Month_Year(
                                                    getMonthDifference(
                                                      new Date(
                                                        curElem[1][
                                                          curElem[1].length - 1
                                                        ].dateFrom
                                                      ),
                                                      new Date(
                                                        curElem[1][0].dateTill
                                                      )
                                                    )
                                                  )}
                                                </p>
                                              )}
                                            </div>
                                          </div>
                                          <div className="experienceWrapper">
                                            {curElem[1].map((curElem, id) => {
                                              return (
                                                <div
                                                  className="experienceInfo"
                                                  key={id}
                                                >
                                                  <h5
                                                    className="companyName"
                                                    style={{
                                                      fontSize: "18px",
                                                    }}
                                                  >
                                                    {curElem.designation}
                                                  </h5>
                                                  <p className="text-body-small inline t-black--light break-words">
                                                    {curElem.dateFrom +
                                                      " - " +
                                                      (curElem.dateTill ===
                                                        null ||
                                                        curElem.dateTill === ""
                                                        ? "Present"
                                                        : curElem.dateTill)}
                                                  </p>
                                                  <div
                                                    style={{
                                                      maxWidth: "92%",
                                                    }}
                                                  >
                                                    {curElem.what_work !==
                                                      null ? (
                                                      <p className="experienceContent">
                                                        {curElem?.whatWork}
                                                      </p>
                                                    ) : (
                                                      <></>
                                                    )}
                                                  </div>
                                                </div>
                                              );
                                            })}
                                          </div>
                                        </div>
                                      </>
                                    );
                                  })}
                                </div>
                              </div>
                            ) : (
                              <></>
                            )}
                            {educationDetails?.length > 0 ? (
                              <div
                                className="comon-divs-re"
                                style={{ width: "100%", paddingRight: "20px" }}
                              >
                                <div className="pvs-header__title-container">
                                  <h2 className="pvs-header__title text-heading-large">
                                    <span
                                      aria-hidden="true"
                                      style={{
                                        fontSize: "20px",
                                        fontWeight: "400",
                                      }}
                                    >
                                      Education
                                    </span>
                                  </h2>
                                </div>

                                {educationDetails.map((curElem, id) => {
                                  return (
                                    <>
                                      <div className="companyLogo" key={id}>
                                        {curElem.logo_url !== null ? (
                                          <img
                                            src={curElem.logo_url}
                                            alt="logo"
                                            onError={addDefaultSrc}
                                          />
                                        ) : (
                                          <img src={company_icon3} alt="logo" />
                                        )}
                                        <div style={{ marginLeft: "20px" }}>
                                          <h5 className="companyName">
                                            {curElem.institute_name}
                                          </h5>
                                          <p className="totalExperience">
                                            {curElem.degree}
                                          </p>
                                          <p className="text-body-small inline t-black--light break-words">
                                            {curElem.description !== null
                                              ? curElem.description
                                              : ""}
                                          </p>
                                        </div>
                                      </div>
                                    </>
                                  );
                                })}
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
            </main>
          </div>
        </>
        <div
          className="modal fade pro-jects1"
          id="shareModal2"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div
                className="modal-header"
                style={{ border: "none", marginTop: "-5px" }}
              >
                <h5
                  className="modal-titles"
                  id="exampleModalLabel"
                  style={{ visibility: "visible" }}
                >
                  Share
                </h5>
                <button
                  id="closeContentModal"
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={modalClose}
                ></button>
              </div>
              <div className="modal-body">
                <div>
                  <div className="mt-4 inviteSection text-left">
                    <div className="inviteTitle text-start">Invite someone</div>
                    <InputGroup className="mb-3 inputGroupWrppr">
                      <FormControl
                        placeholder="Enter Email"
                        aria-label="Enter Email"
                        aria-describedby="basic-addon2"
                        className="inputGroupInput"
                        name="email"
                        value={userEmail}
                        onChange={(event) => {
                          handleRequestInputs(event);
                          handleChange(event);
                        }}
                      />
                      <InputGroup.Text
                        id="basic-addon2"
                        className="inputGroups"
                        style={{ backgroud: "#fff" }}
                      >
                        <Dropdown>
                          <Dropdown.Toggle
                            className="dropdownBtn"
                            id="dropdown-basic"
                            style={{
                              backgroundColor: "transparent",
                              border: "none",
                              color: "#000",
                            }}
                          >
                            {getDefaultText(payload.permission)}
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item
                              href="#/action-3"
                              onClick={() =>
                                updatePermission(
                                  "view",
                                  userEmail,
                                  workData?._id
                                )
                              }
                            >
                              Can view
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </InputGroup.Text>
                    </InputGroup>
                  </div>

                  {show ? (
                    <div
                      className="d-flex justify-content-end"
                      style={{ border: "none" }}
                    >
                      <button
                        type="button"
                        className="btn btn-secondary"
                        style={{ marginRight: "1rem" }}
                        onClick={() => {
                          setEmail("");
                        }}
                      >
                        Cancel
                      </button>
                      {expertDetails?.permittedUsers?.filter(
                        (o) => o?.email === userEmail?.trim()
                      )?.length > 0 ? (
                        <button
                          type="button"
                          className="btn btn-primary"
                          style={{ backgroundColor: "#5746ec", border: "none" }}
                          disabled
                        >
                          Invite
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="btn btn-primary"
                          style={{ backgroundColor: "#5746ec", border: "none" }}
                          onClick={makeUpdate}
                        >
                          Invite
                        </button>
                      )}
                    </div>
                  ) : (
                    ""
                  )}

                  <div>
                    <div className="sharedInfoWrapper">
                      <div className="sharedInfoChild">
                        <div className="sharedInfoProfile">
                          {getPUserList()}
                        </div>
                      </div>
                    </div>
                  </div>

                  {expertDetails?.permittedUsers?.length > 0 ? (
                    <>
                      <div className="ZP9BDnxHhGA9rSLoFsa3">
                        <button
                          id="qa-SHARE_DIALOG_STOP"
                          className="cDCnsp1vcViX1pVJM7MV"
                          type="button"
                          tabIndex="0"
                          onClick={stopSharing}
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        >
                          Stop sharing
                        </button>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ExpertProfile;
