export const categoryArrayTwo = [
  {
    label: "Professional Services",
    key: "Professional Services",
  },
  {
    label: "Internet",
    key: "Internet",
  },
  {
    label: "Technology",
    key: "Technology",
  },
  {
    label: "Education",
    key: "Education",
  },
  {
    label: "Society & Government",
    key: "Society",
  },
  {
    label: "Logistics & Transportation",
    key: "Logistics",
  },
  {
    label: "Supply Chain",
    key: "Supply Chain",
  },
  {
    label: "Telecommunications",
    key: "Telecommunications",
  },
];
