import React, { useState, useEffect } from "react";

function ScopeOverview({ datas }) {
  // console.log(datas);
  const [projectOverview, setProjectOverview] = useState({});
  const [projectOverview1, setProjectOverview1] = useState({});
  useEffect(() => {
    setProjectOverview({ ...datas });
    setProjectOverview1({ ...datas });
  }, [datas]);

  // console.log(projectOverview);
  // console.log(projectOverview1);

  function CalculatePercentage(total, percentage) {
    return parseInt((percentage / total) * 100);
  }

  const completedCalls = parseFloat(projectOverview1.completed_Calls);

  const percentage = CalculatePercentage(
    projectOverview.expected_calls,
    completedCalls
  );

  console.log(percentage, "percentage");
  console.log(projectOverview.expected_calls, "expected_calls");
  console.log(completedCalls, "completed_calls");


  return (
    <>
      <div className="d-flex align-items-center justify-content-between">
        <div className="progress col-10 mb-0">
          <div className="progress-bar" style={{ width: `${percentage}%` }} role="progressbar"
            aria-valuenow="0"
            aria-valuemin="0"
            aria-valuemax="100"></div>
        </div>
        <h5 className="my-0">{percentage + "%"}</h5>
      </div>
      {/* <hr /> */}
      <div className="d-flex align-items-center justify-content-between">
        <ul className="list-unstyled right-small-icons d-flex align-items-center justify-content-between w-100 mb-0">
          <li>
          <span className="red-icon">
              <img src="images/red-phone.png" alt="red-phone" />
            </span>
            <span>
              {projectOverview?.expected_calls}
              <small className="d-block">
                {" "}
                Expected <br />
              
              </small>
            </span>
          </li>

          <li>
            <span className="red-icon">
              <img src="images/red-file.png" alt="red-phone" />
            </span>
            <span>
              {projectOverview1?.expected_Submitted}
              <small className="d-block">
                {" "}
                Profiles
                <br />
              </small>
            </span>
          </li>

          <li>
            <span className="red-icon">
              <img src="images/red-phone.png" alt="red-phone" />
            </span>
            <span>
              {projectOverview1?.completed_Calls}
              <small className="d-block">
                {" "}
                Completed
                <br />
                
              </small>
            </span>
          </li>
        </ul>
      </div>
    </>
  );
}
export default ScopeOverview;
