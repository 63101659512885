import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import CloseIcon from "@mui/icons-material/Close";
import error from "../assests/images/error.svg";
function FlashCard({ isErrorModal }) {
  const [show, setShow] = useState(true);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    setShow(isErrorModal);
  }, [isErrorModal]);

  return (
    <div>
      <Modal show={show} onHide={handleClose} centered size="sm">
        <Modal.Body>
          <div className="text-end">
            <CloseIcon onClick={handleClose} />
          </div>
          <div className="py-4 px-3 text-center">
            <img
              src={error}
              style={{ width: "150px" }}
              className="m-auto"
              alt="success"
            />
            <h4>Oops</h4>
            <p className="mt-5">Something went wrong !</p>
            <a className="successModal error" onClick={handleClose}>
              Countinue
            </a>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default FlashCard;
