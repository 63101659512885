import Button from "@mui/material/Button";
import { Editor } from "@tinymce/tinymce-react";
import { Input } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Spinner } from "reactstrap";
import "../App.css";
import API_PATH from "../Constants/api-path";
import "./CreateNotesForm.css";

const { TextArea } = Input;

const config = {
  readonly: false,
  width: "100%",
  enableDragAndDropFileToEditor: true,
  buttons: [
    "source",
    "|",
    "bold",
    "italic",
    "underline",
    "|",
    "ul",
    "ol",
    "|",
    "font",
    "fontsize",
    "brush",
    "paragraph",
    "|",
    "image",
    "table",
    "link",
    "|",
    "left",
    "center",
    "right",
    "justify",
    "|",
    "undo",
    "redo",
    "|",
    "hr",
    "eraser",
    "fullsize",
  ],
  uploader: { insertImageAsBase64URI: false },
  removeButtons: [
    "brush",
    "file",
    "hr",
    "font",
    "fontsize",
    "brush",
    "image",
    "undo",
    "redo",
    "link",
    "fullsize",
    "table",
    "undo",
    "redo",
    "back",
    "paint",
    "eraser",
    "paragraph",
    "source",
    "|",
    "justify",
  ],
  showXPathInStatusbar: false,
  showCharsCounter: false,
  showWordsCounter: true,
  toolbarAdaptive: false,
  toolbarSticky: false,
  toolbarButtonSize: "middle",
};

const CollaborateCreateNotesForm = ({ data }) => {
  const userData = JSON.parse(localStorage.getItem("userData"));
  const history = useNavigate();
  const [noteData, setNoteData] = useState({
    name: "",
    description: "",
    userId: "",
    companyId: "",
    roleId: "",
  });
  const [spinner, setSpinner] = useState(false);
  function RemoveHtml(myString) {
    if (myString !== undefined) {
      // console.log(myString);
      return myString.replace(/(<([^>]+)>)/gi, "");
    }
  }

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  const [value, setValue] = useState("");
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleNotesInputs = (e) => {
    const key = e.target.name;
    const value = e.target.value;
    setNoteData({ ...noteData, [key]: value });
  };

  console.log(handleNotesInputs, "handleNotesInputs");

  const handleEditorChange = (content) => {
    console.log(content, "content");
    setNoteData({ ...noteData, description: content });
  };

  console.log(handleEditorChange, "handleEditorChange");

  const createNote = async (e) => {
    e.preventDefault();
    setSpinner(true);
    try {
      let payload = {
        ...noteData,
      };

      payload.description = noteData.description;
      payload.companyId = userData.user.company_id;
      payload.userId = userData.user._id;
      payload.roleId = userData.user.role_id;
      // console.log(payload);
      payload.roleId = userData.user.role_id._id;
      // console.log(payload);
      const res = await fetch(`${API_PATH.NOTE_CREATE}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": userData.access_token,
        },
        body: JSON.stringify(payload),
      });

      const result = await res.json();
      if (result) {
        setSpinner(false);
        history({
          pathname: "/call_recordings",
        });
        return toast.success("Note created successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      } else {
        return toast.error("something went wrong server error", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    } catch (error) {
      setSpinner(false);
      return toast.error("something went wrong server error", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
  };

  console.log("windowDimensions.innerHeight", windowDimensions.innerHeight);

  //content is the current value of the text editor
  // editor is an object that holds the html element that in this case is the text area where the name prop will be stored.

  return (
    <>
      <div className="container bg-white stylishEditor position-relative">
        <div className="row">
          <div className="col-md-12 border-right">
            <div className="p-2">
              <div className="row">
                <form method="get" action="">
                  <div className="row">
                    <div className="form-group">
                      <div className="form-group">
                        {noteData.name ? (
                          <TextArea
                            style={{
                              color: "#5c5f62",
                              fontWeight: "500",
                              margin: "0px 0",
                              fontSize: "30px",
                            }}
                            name="name"
                            placeholder="Title"
                            onChange={handleNotesInputs}
                            value={noteData.name}
                            className="noteTextArea"
                            bordered={false}
                            data-is-title="true"
                            autoSize={{
                              minRows: 2,
                              maxRows: 6,
                            }}
                          />
                        ) : (
                          <TextArea
                            style={{
                              color: "#5c5f62",
                              fontWeight: "500",
                              margin: "0px 0",
                              fontSize: "30px",
                            }}
                            name="name"
                            placeholder="Title"
                            onChange={handleNotesInputs}
                            className="noteTextArea"
                            bordered={false}
                            data-is-title="true"
                            autoSize={{
                              minRows: 2,
                              maxRows: 6,
                            }}
                          />
                        )}
                      </div>
                      <div className="customToolBar">
                        <Editor
                          apiKey="4zsbw146cia5a0qmlr6rucoz820ppv27r55qepau8vvaz016"
                          // onInit={(evt, editor) => (editorRef.current = editor)}
                          initialValue={
                            value
                      
                          }
                          branding={false}
                          id="notesEditor"
                          onEditorChange={handleEditorChange}
                          init={{
                            height: windowDimensions.height - 230,
                            menubar: false,
                            plugins: [
                              "advlist",
                              "autolink",
                              "lists",
                              "link",
                              "image",
                              "charmap",
                              "preview",
                              "anchor",
                              "searchreplace",
                              "visualblocks",
                              "code",
                              "fullscreen",
                              "insertdatetime",
                              "media",
                              "table",
                              "code",
                              "help",
                              "wordcount",
                            ],
                            toolbar:
                              "undo redo | blocks | " +
                              "bold italic forecolor | alignleft aligncenter " +
                              "alignright alignjustify | bullist numlist outdent indent | " +
                              "strikethrough link subscript superscript pre fontname table ",
                            content_style:
                              "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                            placeholder: "Start Writing...", // Add placeholder here
                          }}
                        />
                      </div>
                      {/* <button onClick={log}>Log editor content</button>{" "} */}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="createBttnNotes">
          <Button
            className="btn btnsm"
            style={{ textTransform: "capitalize" }}
            onClick={createNote}
          >
            {" "}
            {spinner ? (
              <>
                <Spinner
                  size="sm"
                  style={{
                    width: "1rem",
                    height: "1rem",
                    marginRight: "5px",
                  }}
                />
                Create Note
              </>
            ) : (
              "Create Note"
            )}
          </Button>
        </div>
      </div>
    </>
  );
};

export default CollaborateCreateNotesForm;
